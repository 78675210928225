import React from 'react';
import { ValueType } from 'react-select';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import debounce from 'debounce-promise';
import { RadioKey } from '@wiot/shared-domain/models/radio-key/radio-key';
import { fetchKeysFromDB } from '../../api/apiHelpers';
import { AsyncBaseSelect } from '../FilterBar/select/AsyncBaseSelect';
import { DEBOUNCE_TIME_TO_START_SEARCH } from '../FilterBar/select/constants/debounce-time-to-start-search';
import { InputContextType } from '../Input/input-context-type';

interface IAsyncRadioKeySelectProps extends LocalizeContextProps {
  handleSelectionChange: (value: ValueType<RadioKey>) => void;
  selected?: RadioKey;
  label?: string;
  error?: string;
  touched?: boolean;
}

const AsyncRadioKeySelect = (props: IAsyncRadioKeySelectProps) => {
  const { translate, handleSelectionChange, selected, label, error, touched } = props;

  const searchRadioKeys = async (inputValue: string): Promise<RadioKey[]> => {
    try {
      const { radioKeys } = await fetchKeysFromDB({
        filters: { name: inputValue },
      });
      return radioKeys;
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  return (
    <>
      <label id="radio-key-async-select" className="form__label" htmlFor="radio-key">
        {translate(label || 'radio-key')}
      </label>
      <div className="radio-key__async-select">
        <AsyncBaseSelect<RadioKey>
          translationId="radio-key"
          inputContextType={ InputContextType.MODAL }
          getOptionLabel={ (option: RadioKey) => option.name || '' }
          getOptionValue={ (option: RadioKey) => option.id }
          loadOptions={ debounce(searchRadioKeys, DEBOUNCE_TIME_TO_START_SEARCH) }
          selectedOptions={ selected }
          onChange={ handleSelectionChange }
          translateOptions={ false }
          isMulti={ false }
        />
      </div>
      {!!error && touched && <div className="input-error">{error}</div>}
    </>
  );
};

export default withLocalize(AsyncRadioKeySelect);
