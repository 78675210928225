import { Dispatch } from 'redux';
import { EmailCredentials, UserDetails } from '@wiot/shared-domain/models/auth/auth-api';
import { authenticatedInstance as httpClientInstance, handleApiError, loginUser } from '../../api/apiHelpers';
import { ASSIGN_DEVICE_KEY_TO_USER, DEVICE_KEY_ASSIGNED_TO_USER } from './deviceKeyActionTypes';
import { ProtectionDetails } from '@wiot/shared-domain/models/device-key/protection-details';
import { handleProtectionDetailsResponse } from './fetchDeviceKeyProtectionActionCreators';
import { setCurrentUserAndPermissions } from '../actions/loginActionCreators';
import { DeviceKeyAssignedActions, DeviceKeyAssignedToUserAction } from './assignDeviceKeyToCurrentUserActions';

export async function assignDeviceKeyToCurrentUser(
  dispatch: Dispatch<DeviceKeyAssignedActions>,
  downloadHash: string,
  userDetails: UserDetails,
) {
  dispatch({ type: ASSIGN_DEVICE_KEY_TO_USER, downloadHash, user: userDetails });

  try {
    const url = `/deviceKey/${ downloadHash }/currentUser`;
    const { data: protectionDetails } = await httpClientInstance.put<ProtectionDetails>(url, { downloadHash });
    handleProtectionDetailsResponse(downloadHash, dispatch, protectionDetails);
    setCurrentUserAndPermissions(userDetails, dispatch);
    dispatch({
      type: DEVICE_KEY_ASSIGNED_TO_USER,
      downloadHash,
      protectionDetails,
      user: userDetails,
    } as DeviceKeyAssignedToUserAction);
  } catch (error) {
    handleApiError(error);
  }
}

export const loginAndAssignDeviceKey = (
  credentials: EmailCredentials,
  downloadHash: string,
) => async (dispatch: Dispatch<DeviceKeyAssignedActions>): Promise<void> => {
    dispatch({ type: ASSIGN_DEVICE_KEY_TO_USER, downloadHash });

    const userDetails = await loginUser(credentials);
    if (userDetails) {
      await assignDeviceKeyToCurrentUser(dispatch, downloadHash, userDetails);
    }
  };
