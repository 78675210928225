import * as React from 'react';
import { useEffect, useState } from 'react';
import Tree from 'react-d3-tree';
import { Translate } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand } from '@fortawesome/free-solid-svg-icons';
import { CustomNodeElementProps, Point, TreeNodeDatum } from 'react-d3-tree/lib/types/common';
import { DEVICE_MANAGER_PAGE_KEY } from '@wiot/shared-domain/domain/device-manager/device-manager-page-key';
import { AppState } from '../../../state/reducers/rootReducer';
import LoadingIcon from '../../../components/shared/LoadingIcon';
import api from '../../../api/api';
import DeviceTopologyLoadingSpinner from './DeviceTopologyLoadingSpinner';
import { convertToD3NodeWrapper, D3NodeWrapper } from './d3-node-wrapper';
import { getTreeNodeComponent } from './tree-node-component-selector';
import { toggleTreeNodeChildren } from '../../../state/device-tree/expandTreeNodeChildrenActionCreator';
import { resetTreeExpandState } from '../../../state/device-tree/expand-state-tracker/resetTreeExpandStateActionCreator';
import { fetchDeviceGroupTree } from '../../../state/device-tree/fetch-device-tree/fetchDeviceGroupTreeActionCreator';
import { closeContextMenu } from '../../../state/context-menu/toggleContextMenuActionCreators';
import { Tooltip } from '../../../components/shared/Tooltip';

function DeviceTopology() {
  const dispatch = useDispatch();

  const filter = useSelector((state: AppState) => state.filters.filter[DEVICE_MANAGER_PAGE_KEY]);
  const isLoading = useSelector((appState: AppState) => appState.isLoading);
  const isDeviceTreeLoading = useSelector((appState: AppState) => appState.deviceTree.isLoading);
  const rootTreeNode = useSelector((appState: AppState) => appState.deviceTree.nodes.rootNode);
  const d3NodeWrapper = convertToD3NodeWrapper(rootTreeNode);
  let treeContainer: HTMLDivElement | null;
  const [translatePoint, setTranslatePoint] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);

  const resetTreeLocation = () => {
    const containerDimensions = treeContainer?.getBoundingClientRect();
    if (containerDimensions) {
      const translatedPoint = { x: (containerDimensions.width + Math.random() / 10) / 2, y: 100 };
      setTranslatePoint(translatedPoint);
    }
  };

  useEffect(() => {
    dispatch(resetTreeExpandState());
    resetTreeLocation();
    dispatch(fetchDeviceGroupTree(filter));
  }, [filter]);

  const backgroundImage = `linear-gradient(to bottom, rgba(255, 255, 255, 0.888) 0%,
   rgba(255, 255, 255, 0.888) 100%), url(${ api.baseAPIUrl }${ api.customizeWallpaper })`;

  const renderCustomNodeElement = (nodeProps: CustomNodeElementProps) => {
    const d3nodeWrapper = nodeProps.nodeDatum as unknown as D3NodeWrapper;
    return getTreeNodeComponent(d3nodeWrapper.innerNode, nodeProps);
  };

  const onReactD3TreeUpdate = (target: {
    node: TreeNodeDatum | null;
    zoom: number;
    translate: Point;
  }) => {
    const treeNodeToggled = !!target.node;
    if (treeNodeToggled) {
      const d3nodeWrapper = target.node as unknown as D3NodeWrapper;
      dispatch(toggleTreeNodeChildren(d3nodeWrapper.innerNode.id));
    }

    const zoomChanged = target.zoom !== zoom;
    if(zoomChanged){
      rememberZoom(target.zoom);
      dispatch(closeContextMenu());
    }

    const treeMoved = translatePoint.x !== target.translate.x || translatePoint.y !== target.translate.y;
    if (treeMoved && !zoomChanged) {
      rememberTreeLocationOnMove(target.translate);
      dispatch(closeContextMenu());
    }
  };

  const rememberTreeLocationOnMove = (point: Point) => {
    setTranslatePoint(point);
  };

  const rememberZoom = (zoomValue: number) => {
    setZoom(zoomValue);
  };

  return (
    <div
      id="treeWrapper"
      ref={ (tc) => {
        treeContainer = tc;
      } }
      style={ { backgroundImage } }
    >
      { isLoading && <DeviceTopologyLoadingSpinner/> }
      { isDeviceTreeLoading
        ? (<LoadingIcon/>)
        : (
          <Tree
            data={ d3NodeWrapper }
            scaleExtent={ { min: 0.1, max: 10 } }
            translate={ translatePoint }
            orientation="vertical"
            collapsible
            transitionDuration={ 0 }
            depthFactor={ 150 }
            separation={ { siblings: 0.89, nonSiblings: 1.04 } }
            renderCustomNodeElement={ renderCustomNodeElement }
            nodeSize={ { x: 110, y: 118 } }
            pathClassFunc={ () => 'tree-links' }
            onUpdate={ onReactD3TreeUpdate }
            zoom={ zoom }
          />
        ) }
      <div className="tree__controls">
        <div className="tree__controls__zoom">
          <div className="tree__controls__zoom__label">
            <button
              className="text-color-main border-color-main background-color-white"
              onClick={ () => resetTreeLocation() }
              data-tip="tree__controls__zoom"
              data-for="tree__controls__zoom-reset"
            >
              <Tooltip id="tree__controls__zoom-reset">
                <Translate id="click-to-reset-zoom"/>
              </Tooltip>
              <FontAwesomeIcon icon={ faExpand } size="sm" className="tree__controls__icon"/>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeviceTopology;
