import { Field } from 'formik';
import * as React from 'react';
import { Translate } from 'react-localize-redux';
import classNames from 'classnames';
import { MASKED_INPUT_PLACEHOLDER, MaskedMonthYearDateInput } from '../shared/MaskedMonthYearDateInput';
import { InputContextType } from '../Input/input-context-type';

export interface ICustomFieldInputProps {
  translationId?: string;
  fieldName: string;
  placeholder?: string;
  value?: string | boolean | number;
  readOnly?: boolean;
  type?: 'text' | 'number';
  className?: string;
  inputContextType?: InputContextType;
  error?: string;
  touched?: boolean;
  checked?: boolean;
  masked?: boolean;
  isTextarea?: boolean;
  autoComplete?: 'on' | 'off';
  validate?: (value: any) => string | undefined;
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
  inputFontFamily?: string;
  maxLength?: number;
  hideLabel?: boolean;
  rows?: number;
  required?: boolean;
  step?: number;
  useStandardFormLabelStyle?: boolean;
}

export const CustomFieldInput = ({
  translationId,
  fieldName,
  placeholder,
  value = '',
  error,
  touched,
  readOnly = false,
  type = 'text',
  masked,
  isTextarea,
  validate,
  className,
  inputContextType = InputContextType.MODAL,
  inputFontFamily,
  maxLength,
  autoComplete = 'on',
  hideLabel = false,
  required = false,
  step = 1,
  useStandardFormLabelStyle = true,
  ...inputProps
}: ICustomFieldInputProps) => {

  const getInputComponent = () => {
    if (masked) {
      return MaskedMonthYearDateInput;
    } else if (isTextarea) {
      return 'textarea';
    }
    return 'input';
  }

  return (
    <>
      <div className={ useStandardFormLabelStyle ? 'form__label' : '' }>
        { !hideLabel && translationId && (
          <label className={ required ? 'required' : '' }>
            <Translate id={ translationId } />
          </label>
        ) }
        <Field
          name={ fieldName }
          type={ type }
          className={ classNames(
            `${ inputContextType }-input`,
            { [`${ inputContextType }-input--readonly`] : readOnly, },
            inputFontFamily,
          ) }
          disabled={ readOnly }
          readOnly={ readOnly }
          validate={ validate }
          component={ getInputComponent() }
          value={ value }
          maxLength={ maxLength }
          autoComplete={ autoComplete }
          step={ step }
          // eslint-disable-next-line react/jsx-props-no-spreading
          { ...inputProps }
        />
        { !!error && <div className="input-error">{ error }</div> }
      </div>
    </>
  );
}
