import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import classNames from 'classnames';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';

import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';
import RenderOnCondition from '../RenderOnCondition';
import { fetchDeviceGroupFromDB, fetchDeviceGroupsFromDB } from '../../api/apiHelpers';
import { DeviceGroupExtended } from '../../state/types';
import { OutsideClickHandler } from '../OutsideClickHandler';
import CustomCheckbox from '../shared/CustomCheckbox';
import { setFilter } from '../../state/filter/set-filter-action-creator';
import { PageKeys } from '../../state/reducers/filterSortReducer';
import { AppState } from '../../state/reducers/rootReducer';
import { generatePropertyViewPath } from '../../pages/PropertyView/PropertyViewPathUtils';
import { ExpandIcon } from '../FilterBar/select/components/ExpandIcon';
import { includesCaseInsensitive } from '../../utils/string';

export interface FilterAdvancedSelectProps extends LocalizeContextProps {
  pageKey: PageKeys;
  isPropertySelect?: boolean;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
enum CHECKED_STATE {
  CHECKED = 'CHECKED',
  PARTIAL = 'PARTIAL',
  UNCHECKED = 'UNCHECKED',
}

const FilterAdvancedSelect = (
  {
    translate,
    pageKey,
    isPropertySelect,
  }: FilterAdvancedSelectProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [ isExpanded, setIsExpanded ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ deviceGroups, setDeviceGroups ] = useState<DeviceGroup[]>([]);
  const [ viewingOption, setViewingOption ] = useState<DeviceGroupExtended[]>([]);
  const [ searchTerm, setSearchTerm ] = useState('');
  const [ showExtraRoot, setShowExtraRoot ] = useState(false);
  const filter = useSelector((state: AppState) => state.filters.filter);

  // @ts-ignore The 'any' type in the global filter must be refactored
  const selectedOptions: DeviceGroupExtended[][] = 'deviceGroup' in filter[pageKey] ? filter[pageKey].deviceGroup : [];
  const toggleDropdownOpen = () => {
    setIsExpanded(!isExpanded);
  };

  const handleDeviceGroupChange = (selectedGroups: DeviceGroupExtended[][]) => {
    dispatch(
      setFilter({
        page: pageKey,
        values: { deviceGroup: selectedGroups },
      }),
    );
  };

  const handlePropertySelect = (selectedProperty?: DeviceGroupExtended) => {
    const path = generatePropertyViewPath({
      deviceGroupId: selectedProperty?.id,
    });
    history.push(path);

    dispatch(
      setFilter({
        page: pageKey,
        values: {
          deviceGroup: selectedProperty ? [[selectedProperty]] : [],
          propertyMBusDeviceTypeIdForConsumption: 0,
          propertyMBusDeviceTypeIdForPieChart: 0,
        },
      }),
    );
  }

  const setInitialGroups = useCallback(async () => {
    try {
      const { deviceGroups: deviceGroupsRes } = await fetchDeviceGroupsFromDB(
        false,
        undefined,
        false,
      );

      if (deviceGroupsRes.length === 1 && deviceGroupsRes[0].hasChildren) {
        setViewingOption([ { ...deviceGroupsRes[0], checked: false } ]);
        setDeviceGroups(deviceGroupsRes[0].children ?? []);
        setSearchTerm('');
      } else {
        setViewingOption([]);
        setShowExtraRoot(true);
        setDeviceGroups(deviceGroupsRes);
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    setInitialGroups();
  }, [ setInitialGroups ]);

  const isArraysEqual = (arr1: DeviceGroupExtended[], arr2: DeviceGroupExtended[]) => {
    let isEqual = false;
    if (arr1.length !== arr2.length) {
      return isEqual;
    }

    arr1.forEach((group, index) => {
      if (group.id !== arr2[index].id) {
        isEqual = false;
        return;
      }
      isEqual = true;
    });
    return isEqual;
  };

  const handleOptionSelect = (
    event: React.MouseEvent<HTMLInputElement>,
    group: DeviceGroupExtended,
  ) => {
    event.stopPropagation();
    const { currentTarget } = event;
    const selectedTree = [ ...viewingOption, group ];

    if (isPropertySelect) {
      handlePropertySelect(currentTarget.checked ? group : undefined);
      return;
    }

    const existsAt = selectedOptions.findIndex((option) => isArraysEqual(option, selectedTree));
    if (existsAt !== -1) {
      if (!currentTarget.checked) {
        const updatedSelectedOptions = [...selectedOptions];
        updatedSelectedOptions.splice(existsAt, 1);
        handleDeviceGroupChange(updatedSelectedOptions);
      }
      return;
    }

    if (currentTarget.checked) {
      handleDeviceGroupChange([...selectedOptions, [...viewingOption, group]]);
    }
  };

  const handleStepInto = async (group: DeviceGroupExtended, isOptionChecked: boolean) => {
    if(isLoading){
      return;
    }
    if (group.hasChildren) {
      try {
        setIsLoading(true);
        const deviceGroup = await fetchDeviceGroupFromDB(group.id!, false);
        if (deviceGroup.hasChildren) {
          setViewingOption((prevState) => [ ...prevState, { ...group, checked: isOptionChecked } ]);
          setDeviceGroups(deviceGroup.children!);
          setSearchTerm('');
        }
      } catch (e) {
        console.error(e);
      }finally {
        setIsLoading(false);
      }
    }
  };

  const handleStepOut = async (index: number,event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    try {
      if (index === 0) {
        await setInitialGroups();
      } else {
        const actualIndex = showExtraRoot ? index - 1 : index;
        const deviceGroup = await fetchDeviceGroupFromDB(
          viewingOption[actualIndex].id!,
          false,
        );
        if (deviceGroup.hasChildren) {
          setViewingOption(viewingOption.slice(0, actualIndex + 1));
          setDeviceGroups(deviceGroup.children!);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const filterOptions = (event: React.FormEvent<any>) => {
    setSearchTerm(event.currentTarget.value);
  };

  const getIsOptionChecked = (id: string) => {
    let isChecked = CHECKED_STATE.UNCHECKED;
    // eslint-disable-next-line no-restricted-syntax
    for (const option of selectedOptions) {
      if (option[option.length - 1].id === id) {
        isChecked = CHECKED_STATE.CHECKED;
        break;
      }
      if (option.findIndex((group) => group.id === id) !== -1) {
        isChecked = CHECKED_STATE.PARTIAL;
      }
    }
    return isChecked;
  };

  const getBreadCrumb = () => {
    const options = showExtraRoot
      ? [ { name: translate('root-group') }, ...viewingOption ]
      : [ ...viewingOption ];
    return options.map((group, index) => {
      if (index < options.length - 1) {
        return (
          <a key={ index } href="#" onClick={ (e) => handleStepOut(index,e) }>
            { ' ' }
            { index !== 0 && '›' } { group.name }
          </a>
        );
      }
      return (
        <span key={ index }>
          { ' ' }
          { index !== 0 && '›' } { group.name }
        </span>
      );
    });
  };

  const getSearchPlaceHolder = () => {
    const arrayLength = viewingOption.length;
    return `🔍 ${ translate('search-in', {
      groupName: viewingOption[arrayLength - 1]?.name,
    })?.toString() }`;
  };

  const getFilteredDeviceGroups = () => {
    if (searchTerm) {
      return deviceGroups.filter((group: DeviceGroupExtended) => group && includesCaseInsensitive(group.name, searchTerm));
    }
    return deviceGroups;
  };

  const getIsSelectDisabled = () => viewingOption.findIndex((option) => option.checked) >= 0;

  const getRenderedOptions = () =>
    getFilteredDeviceGroups().map((group: DeviceGroupExtended, index) => {
      const isChecked = getIsOptionChecked(group.id!);
      const isSelectDisabled = getIsSelectDisabled();

      return (
        <div key={ group.id! + index } className="multiselect_option_wrapper">
          <div className="multiselect_option_content">
            <div className="multiselect_option_checkbox">
              <RenderOnCondition condition={!isPropertySelect || group.propertySettings?.isProperty}>
                <CustomCheckbox
                  onClick={ (event: React.MouseEvent<HTMLInputElement>) =>
                    handleOptionSelect(event, group)
                  }
                  checked={ isSelectDisabled || isChecked === CHECKED_STATE.CHECKED }
                  disabled={ isSelectDisabled }
                  onChange={ () => {} }
                />
              </RenderOnCondition>
            </div>
            <div
              className={ classNames('multiselect_option_label', {
                multiselect_partial: isChecked === CHECKED_STATE.PARTIAL,
              }) }
              onClick={ () => handleStepInto(group, isChecked === CHECKED_STATE.CHECKED) }
            >
              { group.name }
              { group.hasChildren && <FontAwesomeIcon icon={ faAngleRight }/> }
            </div>
          </div>
        </div>
      );
    });

  const handleClickOutside = () => {
    setIsExpanded(false);
  };

  return (
    <OutsideClickHandler onClickOutside={ handleClickOutside }>
      <div className="filterbar__item filter-bar-select">
        <div
          className={ `filter-bar-control filter-bar-select__control ${ isExpanded ? 'filter-bar-select__control--active' : '' }` }
          onClick={ toggleDropdownOpen }
          tabIndex={ 0 }
        >
          <div className="select__value-container">
            <div className="select__label">
              <Translate id={ isPropertySelect ? 'select-property' : 'choose-group' }/>
              { ' ' }
              { selectedOptions.length > 0 ? `(${ selectedOptions.length })` : '' }
            </div>
          </div>
          <ExpandIcon isExpanded={ isExpanded } className="group-select__dropdown-icon"/>
        </div>
        { isExpanded && (
          <div className="multiselect_dropdown_wrapper group-select">
            <div className="multiselect_breadcrumb">
              <span>{ getBreadCrumb() }</span>
            </div>
            <RenderOnCondition condition={ viewingOption.length }>
              <Form.Control
                type="text"
                value={ searchTerm }
                placeholder={ getSearchPlaceHolder() }
                onChange={ filterOptions }
              />
            </RenderOnCondition>
            <div>{ getRenderedOptions() }</div>
          </div>
        ) }
      </div>
    </OutsideClickHandler>
  );
};

export default withLocalize(FilterAdvancedSelect);
