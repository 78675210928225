import { DeviceViews, DisplayTypes } from '@wiot/shared-domain/models/device/device';
import {
  RESET_DEVICE_MANAGER_STATE,
  SET_DEVICE_MANAGER_CONTENT_DISPLAY,
  SET_DEVICE_MANAGER_VISIBLE_VIEW,
} from '../constants/actionTypes';
import { ResetDeviceManagerStateAction } from '../types';

export const changeVisibleView = (view: DeviceViews) => ({
  type: SET_DEVICE_MANAGER_VISIBLE_VIEW,
  payload: view,
});

export const changeContentDisplay = (display: DisplayTypes) => ({
  type: SET_DEVICE_MANAGER_CONTENT_DISPLAY,
  payload: display,
});

export const resetDeviceManagerState = (): ResetDeviceManagerStateAction => ({
  type: RESET_DEVICE_MANAGER_STATE,
});
