import { Dispatch } from 'redux';
import HttpStatus from 'http-status-codes';
import { DisplayTypes } from '@wiot/shared-domain/models/device/device';

import { FETCH_DEVICES } from '../constants/actionTypes';
import { IPagination } from '../types';
import { FetchDevicesAction } from './fetchDevicesAction';
import {
  showDataExceedsDisplayLimit,
  showNoResultsInfo,
} from './fetching-limit/fetchNoResultInfoAction';
import { IDeviceManagerFilter, PageKeys } from '../reducers/filterSortReducer';
import { fetchDevicesFromDB, FETCH_CANCELLED_BY_NEW_FETCH } from '../../api/apiHelpers';
import { getFetchOptions } from '../../utils/common';
import { saveDevices } from '../actions/saveDevicesAction';
import { savePagination } from '../actions/savePaginationAction';
import { isLoading } from '../actions/isLoadingAction';
import { isTableLoading } from '../table/isTableLoadingAction';
import { fetchDeviceReadingsActionCreator } from '../device-readings/fetch-device-readings/fetchDeviceReadingsActionCreators';


function updateFilterBasedOnDisplayType(filter: IDeviceManagerFilter, groupAssignmentSettings: DisplayTypes) {
  let updatedFilter: IDeviceManagerFilter = filter || {};
  if (groupAssignmentSettings !== DisplayTypes.ASSIGNED) {
    const additionalFilter =
      groupAssignmentSettings === DisplayTypes.UNASSIGNED
        ? { deviceGroup: null }
        : { blacklisted: true };
    if (updatedFilter) {
      updatedFilter = {
        ...updatedFilter,
        ...additionalFilter,
      };
    } else {
      updatedFilter = additionalFilter;
    }
  }
  return updatedFilter;
}

export const fetchDevices = (
  groupAssignmentSettings: DisplayTypes,
  pageSize: number,
  currentPage: number,
  filter: IDeviceManagerFilter,
  callerPage: PageKeys,
  fetchDeviceReadings: boolean,
) => async (
  dispatch: Dispatch<any>,
): Promise<void> => {
  dispatch({
    type: FETCH_DEVICES,
  } as FetchDevicesAction);

  let updatedFilter: IDeviceManagerFilter = updateFilterBasedOnDisplayType(filter, groupAssignmentSettings);

  const fetchOptions = getFetchOptions<IDeviceManagerFilter>(
    pageSize,
    undefined,
    updatedFilter,
    currentPage,
  );

  dispatch(isLoading(true));
  dispatch(isTableLoading(true));
  dispatch(showNoResultsInfo());

  let deactivateLoadingWhenFinished = true;
  try {
    const res = await fetchDevicesFromDB({ ...fetchOptions });
    if (res && res.devices) {
      const { devices: devicesRes, totalDocs: totalDocsRes, totalPages } = res;
      dispatch(saveDevices(devicesRes));
      const paginationData: IPagination = { totalDocs: totalDocsRes || 0, totalPages };
      dispatch(savePagination(paginationData, callerPage));

      if (fetchDeviceReadings) {
        dispatch(fetchDeviceReadingsActionCreator(devicesRes.map(device => device.id)));
      }
    }
  } catch (err) {
    if (err.response?.status === HttpStatus.UNPROCESSABLE_ENTITY) {
      dispatch(showDataExceedsDisplayLimit());
    }

    if (err.message === FETCH_CANCELLED_BY_NEW_FETCH) {
      deactivateLoadingWhenFinished = false;
    }
  } finally {
    if (deactivateLoadingWhenFinished) {
      dispatch(isLoading(false));
      dispatch(isTableLoading(false));
    }
  }
};
