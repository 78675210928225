import FilterBarInput from './FilterBarInput';
import FilterDateRange from './FilterDateRange';
import FilterDatePicker from './FilterDatePicker';
import FilterAdvancedSelect from './FilterAdvancedSelect';
import { FilterDeviceTypeMultiSelect } from './FilterDeviceTypeMultiSelect';
import { FilterGatewaySelect } from './FilterGatewaySelect';
import FilterManufacturerSelect from './FilterManufacturerSelect';
import FilterAsyncDeviceSelect from './FilterAsyncDeviceSelect';
import * as React from 'react';
import { PageKeys } from '../../state/reducers/filterSortReducer';
import { FilterField } from './filter-field';
import DeviceStatusSelect from '../shared/DeviceStatusSelect';
import BooleanSelect from './BooleanSelect';
import GroupTypeSelect from './GroupTypeSelect';
import FilterBillingPeriodPicker from './FilterBillingPeriodPicker';
import { FilterBillingFrequencyToggle } from './FilterBillingFrequencyToggle';
import { ConsumptionTypeToggleFilter } from './ConsumptionTypeToggleFilter';

export const getFilterComponent = (filterField: FilterField, filterPageKey: PageKeys): JSX.Element => {
  switch (filterField.component) {
    case 'boolean-select':
      return (<BooleanSelect filterField={ filterField } pageKey={ filterPageKey }/>);
    case 'input':
      return (<FilterBarInput name={ filterField.stateField } pageKey={ filterPageKey }/>);

    case 'date-range':
      return (<FilterDateRange pageKey={ filterPageKey }/>);
    case 'date-picker':
      return (<FilterDatePicker field={ filterField } pageKey={ filterPageKey }/>);
    case 'billing-period-picker':
      return <FilterBillingPeriodPicker field={ filterField } pageKey={ filterPageKey }/>;
    case 'billing-frequency-toggle':
      return <FilterBillingFrequencyToggle field={ filterField } pageKey={ filterPageKey }/>;
    case 'consumption-type-toggle-filter':
      return <ConsumptionTypeToggleFilter field={ filterField } pageKey={ filterPageKey }/>;

    case 'advanced-multi-select':
      return (<FilterAdvancedSelect pageKey={ filterPageKey }/>);
    case 'property-select':
      return (<FilterAdvancedSelect pageKey={ filterPageKey } isPropertySelect={true} />);

    case 'multi-device-type-select':
      return (<FilterDeviceTypeMultiSelect pageKey={ filterPageKey }/>);

    case 'multi-device-status-select':
      return (<DeviceStatusSelect pageKey={ filterPageKey }/>);
    case 'gateway-select':
      return (<FilterGatewaySelect pageKey={ filterPageKey } filterField={ filterField }/>);
    case 'group-type-select':
      return (<GroupTypeSelect pageKey={ filterPageKey } filterField={ filterField }/>);
    case 'manufacturer-select':
      return (<FilterManufacturerSelect pageKey={ filterPageKey }/>);
    case 'async-device-select':
      return (<FilterAsyncDeviceSelect pageKey={ filterPageKey } filterField={ filterField }/>);
    default:
      throw new Error('There is no component for the given component type:' + filterField.component);
  }
};
