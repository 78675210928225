import { ErrorMessage, Field, Form, Formik, FormikActions } from 'formik';
import React from 'react';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { Link } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { DataValidator } from '@wiot/shared-domain/models/validators/data-validators';
import api from '../../api/api';
import { resetPassword } from '../../api/apiHelpers';
import { LOGIN_PATH } from '../../navigation/paths';

interface IResetForm {
  email: string;
}

type IResetPasswordProps = LocalizeContextProps;

const ResetPassword = (props: IResetPasswordProps) => {
  const handleSubmit = async (values: IResetForm, formikActions: FormikActions<IResetForm>) => {
    const isValidEmail = DataValidator.validateEmail(values.email);

    // The password can be reset by using an email or id(for anonymous users).
    // Send the proper key based on data entered
    const identifier = isValidEmail ? 'email' : 'id';
    const payload = {
      [identifier]: values.email,
    };

    try {
      const response = await resetPassword(payload);
      if (response) {
        toastr.success(
          props.translate('success').toString(),
          props.translate('we-send-an-email').toString(),
        );
      }
    } catch (e) {
      toastr.error(props.translate('error').toString(), props.translate('ERROR').toString());
    } finally {
      formikActions.setSubmitting(false);
    }
  };

  return (
    <main
      data-testid="page-reset-password"
      className="main"
      style={{
        backgroundImage: `url(${api.baseAPIUrl}${api.customizeWallpaper})`,
      }}
    >
      <Formik
        initialValues={{
          email: '',
        }}
        validate={({ email }) => {
          const errors: { email?: string } = {};
          if (!email) {
            errors.email = 'Valid Email/Id is required.';
          }
          return errors;
        }}
        onSubmit={handleSubmit}
        render={({ isSubmitting, isValid }) => (
          <>
            <img
              src={`${api.baseAPIUrl}${api.customizeBranding}`}
              alt="Logo"
              className="login-brand"
            />
            <Form className="reset-password-form">
              <h1 className="reset-password-form__heading" data-testid="reset-password-heading">
                <Translate id="reset-pw" />
              </h1>

              <p
                className="reset-password-form__subheading"
                data-testid="reset-password-subheading"
              >
                <Translate id="we-send-an-email" />
              </p>

              <label className="reset-password-form__label">
                <Translate>
                  {({ translate }) => (
                    <Field
                      className="reset-password-form__input"
                      name="email"
                      type="text"
                      data-testid="email-input"
                      placeholder={translate('email-id')}
                    />
                  )}
                </Translate>
                <ErrorMessage name="email" component="div" className="input-error" />
              </label>

              <div className="reset-password-form__container">
                <Link
                  to={LOGIN_PATH}
                  className="reset-password-form__link"
                  data-testid="reset-password-button"
                >
                  <Translate id="back-to-login" />
                </Link>

                <button
                  className={`reset-password-form__button ${
                    isValid ? 'reset-password-form__button--valid' : ''
                  }`}
                  type="submit"
                  data-testid="reset-password-button"
                  disabled={isSubmitting}
                >
                  <Translate id="reset" />
                </button>
              </div>
            </Form>
          </>
        )}
      />
    </main>
  );
};

export default withLocalize(ResetPassword);
