/* eslint-disable react/function-component-definition */
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HttpStatus from 'http-status-codes';
import { IColumnObject } from '@wiot/shared-domain/models/device/device';
import { PageKeys } from '../../state/reducers/filterSortReducer';

import ErrorBoundary from '../../components/ErrorBoundary';
import FilterBar from '../../components/Filter/FilterBar';
import Table from '../../components/Table/Table';
import TableDataDevMessages from './Table/TableDataDevMessages';
import { FetchOptions, IPagination } from '../../state/types';
import { getFetchOptions } from '../../utils/common';
import { FETCH_CANCELLED_BY_NEW_FETCH, fetchDeviceMessagesFromDB } from '../../api/apiHelpers';
import { AppState } from '../../state/reducers/rootReducer';
import { saveDeviceMessages } from '../../state/actions/saveDeviceMessagesAction';
import { savePagination } from '../../state/actions/savePaginationAction';
import { isLoading } from '../../state/actions/isLoadingAction';
import Desktop from '../../components/Responsive/Desktop';
import Mobile from '../../components/Responsive/Mobile';
import MobileTable from './Mobile/MobileTable';
import { IDeviceMessageFilter } from '../../state/reducers/filterSortReducer';
import MainGrid from '../../components/shared/MainGrid';
import { showDataExceedsDisplayLimit, showNoResultsInfo } from '../../state/device/fetching-limit/fetchNoResultInfoAction';
import { updateDeviceMessageFilter } from '../../state/filter/updateDeviceMessagesFilterActionCreator';

const DEVICE_MESSAGE_PAGE_KEY: PageKeys = 'device-messages';

const DeviceMessages = () => {
  const dispatch = useDispatch();

  const currentPage = useSelector((state: AppState) => state.pagination[DEVICE_MESSAGE_PAGE_KEY].currentPage);
  const filter = useSelector((state: AppState) => state.filters.filter[DEVICE_MESSAGE_PAGE_KEY]);
  const currentEntriesPerPage = useSelector((state: AppState) => state.currentEntriesPerPage);

  useEffect(() => {
    dispatch(updateDeviceMessageFilter());
  }, [dispatch]);

  const fetchDeviceMessages = useCallback(
    async (column?: IColumnObject) => {
      if (currentEntriesPerPage === 0) {
        return;
      }

      const fetchOptions: FetchOptions<IDeviceMessageFilter> = getFetchOptions(
        currentEntriesPerPage,
        column,
        filter,
        currentPage,
      );

      dispatch(isLoading(true));
      dispatch(showNoResultsInfo());

      let deactivateLoadingWhenFinished = true;

      try {
        let res = await fetchDeviceMessagesFromDB({ ...fetchOptions });

        if (res === HttpStatus.UNPROCESSABLE_ENTITY) {
          dispatch(saveDeviceMessages([]));
          dispatch(savePagination({ totalDocs: 0, totalPages: 0 }, DEVICE_MESSAGE_PAGE_KEY));
          dispatch(showDataExceedsDisplayLimit());
        }

        if (res && res.deviceReadings) {
          const { deviceReadings, totalDocs, totalPages } = res;
          dispatch(saveDeviceMessages(deviceReadings));
          const paginationData: IPagination = { totalDocs, totalPages };
          dispatch(savePagination(paginationData, DEVICE_MESSAGE_PAGE_KEY));
        }
      } catch (error) {
        if (error.message === FETCH_CANCELLED_BY_NEW_FETCH) {
          deactivateLoadingWhenFinished = false;
        }
      } finally {
        if (deactivateLoadingWhenFinished) {
          dispatch(isLoading(false));
        }
      }
    },
    [currentEntriesPerPage, filter, currentPage, isLoading, saveDeviceMessages, savePagination, showDataExceedsDisplayLimit, showNoResultsInfo],
  );

  useEffect(() => {
    fetchDeviceMessages();
  }, [fetchDeviceMessages]);

  const getTableComponent = () => <TableDataDevMessages refreshData={ fetchDeviceMessages }/>;

  return (

    <MainGrid dataTestId={ `page-${ DEVICE_MESSAGE_PAGE_KEY }` }>
      <ErrorBoundary>
        <FilterBar page={ DEVICE_MESSAGE_PAGE_KEY }/>
      </ErrorBoundary>
      <Desktop>
        <ErrorBoundary>
          <Table
            page={ DEVICE_MESSAGE_PAGE_KEY }
            changeView={ false }
            addModal={ false }
            tableComponent={ getTableComponent() }
            elementType={ DEVICE_MESSAGE_PAGE_KEY }
            addText=""
            refreshTableData={ () => undefined }
            showPagination
          />
        </ErrorBoundary>
      </Desktop>
      <Mobile>
        <MobileTable refreshData={ fetchDeviceMessages }/>
      </Mobile>
    </MainGrid>
  );
};

export default DeviceMessages;
