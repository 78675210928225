import { IUser } from '../user/user';
import { DeviceList } from '@wiot/shared-domain/models/device/device-list';
import { PropertySettings } from '@wiot/shared-domain/models/settings/property-settings';

export interface Ancestor {
  id?: string;
  name: string;
  abbreviation?: string;
}

export const toAncestor = (deviceGroup: DeviceGroup): Ancestor => ({
  id: deviceGroup.id,
  name: deviceGroup.name!,
  abbreviation: deviceGroup.abbreviation,
});

export const METADATA_KEY_CREATED_FOR_USER_ID = 'createdForUserId';

export interface DeviceGroup {
  id?: string;
  externalId?: string;
  name?: string;
  type?: string;
  metadata?: { key: string, value: string }[];
  // we could potentially just use string type
  parent?: any;
  parentId?: string;
  hasChildren?:boolean;
  children?: any[];
  ancestors?: Ancestor[];
  deviceList?: DeviceList;
  users?: IUser[];
  hasPermission?: boolean;
  notes?: string;
  isImported?: boolean;
  dataIntegrationJobID?: any;
  abbreviation?: string;
  syncState?: string;
  systemName?: string;
  propertySettings?: PropertySettings;
}

export interface DeviceGroups {
  deviceGroups: DeviceGroup[];
  totalPages?: number;
  totalDocs?: number;
}

export enum DeviceGroupType {
  COUNTRY = 'COUNTRY',
  DISTRICT = 'DISTRICT',
  TOWN = 'TOWN',
  STREET = 'STREET',
  COMPANY = 'COMPANY',
  OBJECT = 'OBJECT',
  PROPERTY = 'PROPERTY',
  BUILDING = 'BUILDING',
  FLOOR = 'FLOOR',
  FLAT = 'FLAT',
  ROOM = 'ROOM',
}

export function getDeviceGroupTypes(): string[] {
  return Object.values(DeviceGroupType);
}
