import { DisplayTypes } from '@wiot/shared-domain/models/device/device';
import React from 'react';
import Toggle, { ToggleOption } from './Toggle';

export interface ToggleContentDisplayProps {
  handleContentDisplayToggle?: (value: DisplayTypes) => void;
  contentDisplayOptions?: ToggleOption[];
  selectedContentDisplay?: DisplayTypes;
}

const ToggleContentDisplay = ({
  handleContentDisplayToggle,
  contentDisplayOptions,
  selectedContentDisplay,
}: ToggleContentDisplayProps) => (
  <Toggle
    label="lists"
    handleToggle={handleContentDisplayToggle}
    options={contentDisplayOptions}
    selectedOption={selectedContentDisplay}
  />
);

export default ToggleContentDisplay;
