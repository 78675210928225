import { Dispatch } from 'redux';
import { TreeNode } from '@wiot/shared-domain/domain/device-tree/tree-node';
import { DEVICE_MANAGER_PAGE_KEY } from '@wiot/shared-domain/domain/device-manager/device-manager-page-key';
import {
  authenticatedInstance as httpClientInstance,
  handleApiError
} from '../../api/apiHelpers';
import { isLoading } from '../actions/isLoadingAction';
import api from '../../api/api';
import { subTreeFetched, fetchSubTree } from './subTreeActions';
import { fetchDeviceGroupsTreeFromDB } from '../device-tree/fetch-device-tree/fetchDeviceGroupTreeActionCreator';
import { AppState } from '../reducers/rootReducer';

export const refreshCurrentSubTree = () => async (
  dispatch: Dispatch<any>,
  getState: () => AppState,
): Promise<void> => {
  const state = getState();
  const currentSubTreeRoot = state.deviceHierarchySubTree.root;

  dispatch(fetchSubTreeActionCreator(currentSubTreeRoot?.id));
};

export const fetchSubTreeActionCreator = (
  idOfSubTreeRoot?: string,
) => async (
  dispatch: Dispatch<any>,
  getState: () => AppState,
): Promise<void> => {
  const state = getState();
  const filter = state.filters.filter[DEVICE_MANAGER_PAGE_KEY] || {};

  dispatch(fetchSubTree(idOfSubTreeRoot));
  dispatch(isLoading(true));
  let url: string;
  let rootNode: TreeNode;
  try {
    if (idOfSubTreeRoot) {
      url = `${ api.deviceGroups }/tree/${ encodeURIComponent(idOfSubTreeRoot) }`;
      const { data } = await httpClientInstance.get<TreeNode>(url);
      rootNode = data;
    } else {
      rootNode = await fetchDeviceGroupsTreeFromDB(filter, [], 2);
    }
    dispatch(subTreeFetched(rootNode));
  } catch (error) {
    handleApiError(error);
    throw error;
  } finally {
    dispatch(isLoading(false));
  }
};
