/* eslint-disable no-underscore-dangle */
import React, { FormEvent, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { faBan, faList, faMapMarkerAlt, faSitemap } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { toastr } from 'react-redux-toastr';
import { withLocalize } from 'react-localize-redux';

import { Device, DeviceViews, DisplayTypes } from '@wiot/shared-domain/models/device/device';
import { DEVICE_MANAGER_PAGE_KEY } from '@wiot/shared-domain/domain/device-manager/device-manager-page-key';
import { ACLDeviceManager } from '@wiot/shared-domain/models/role/access-level';
import FilterBar from '../../components/Filter/FilterBar';
import Table from '../../components/Table/Table';
import { DeviceExtended } from '../../state/types';
import { removeDeviceFromDB } from '../../api/apiHelpers';
import TableDataDevManager from './Table/TableDataDevManager';
import { AppState } from '../../state/reducers/rootReducer';
import { clearPagination, saveCurrentPage as setCurrentPageNumber } from '../../state/actions/savePaginationAction';
import { isTableLoading as isTableLoadingAction } from '../../state/table/isTableLoadingAction';
import { toggleSelectDevice } from '../../state/actions/toggleSelectDeviceAction';
import { toggleSelectAllDevices } from '../../state/actions/toggleSelectAllDevicesAction';
import Mobile from '../../components/Responsive/Mobile';
import Desktop from '../../components/Responsive/Desktop';
import DeviceActionModal from './DeviceActionModal/DeviceActionModal';
import DeviceTopology from './Topology/DeviceTopology';
import MobileTable from './Mobile/MobileTable';
import { ToggleOption } from '../../components/Table/Toggle';
import ErrorBoundary from '../../components/ErrorBoundary';
import DeviceExportModal from './DeviceExport/DeviceExportModal';
import RenderOnCondition from '../../components/RenderOnCondition';
import { changeContentDisplay, changeVisibleView } from '../../state/actions/deviceManagerStateAction';
import MapView from './Map/MapView';
import { fetchDevices } from '../../state/device/fetchDevicesActionCreator';
import useTranslation from '../../hooks/useTranslation';
import MainGrid from '../../components/shared/MainGrid';
import { updateDeviceManagerFilter } from '../../state/filter/updateDeviceManagerFilterActionCreator';
import { FeatureAvailability } from '../../utils/feature-availability';
import PropertyViewSettingsModal from '../PropertyView/Consumption/Table/PropertyViewSettingsModal';
import { selectIsPropertySettingsModalVisible } from '../../state/property-view/propertySelectors';

const contentDisplayOptions: ToggleOption[] = [
  {
    value: DisplayTypes.ASSIGNED,
    icon: faCheckCircle,
    availability: FeatureAvailability.Wiot | FeatureAvailability.KeyManager
  },
  {
    value: DisplayTypes.UNASSIGNED,
    icon: faTimesCircle,
    permissionKey: ACLDeviceManager.VIEW_UNASSIGNED,
    availability: FeatureAvailability.Wiot,
  },
  {
    value: DisplayTypes.BLOCKED,
    icon: faBan,
    permissionKey: ACLDeviceManager.VIEW_BLACKLISTED,
    availability: FeatureAvailability.Wiot,
  },
];

const viewOptions: ToggleOption[] = [
  {
    value: DeviceViews.TABLE,
    icon: faList,
    availability: FeatureAvailability.Wiot | FeatureAvailability.KeyManager,
  },
  {
    value: DeviceViews.TOPOLOGY,
    icon: faSitemap,
    permissionKey: ACLDeviceManager.VIEW_TOPOLOGY,
    availability: FeatureAvailability.Wiot | FeatureAvailability.KeyManager,

  },
  {
    value: DeviceViews.MAP_VIEW,
    icon: faMapMarkerAlt,
    permissionKey: ACLDeviceManager.VIEW_MAP,
    availability: FeatureAvailability.None,
  },
];

const DeviceManager = () => {
  const dispatch = useDispatch();
  const translate = useTranslation();

  const devices = useSelector((state: AppState) => state.devices);
  const currentPage = useSelector((state: AppState) => state.pagination[DEVICE_MANAGER_PAGE_KEY].currentPage);
  const totalDocs = useSelector((state: AppState) => state.pagination[DEVICE_MANAGER_PAGE_KEY].totalDocs);
  const filter = useSelector((state: AppState) => state.filters.filter[DEVICE_MANAGER_PAGE_KEY]);
  const isMobileDisplay = useSelector((state: AppState) => state.isMobileDisplay);
  const currentPageTitle = useSelector((state: AppState) => state.currentPageTitle);
  const visibleView = useSelector((state: AppState) => state.deviceManagerState.visibleView);
  const isLoading = useSelector((state: AppState) => state.isLoading);
  const isKeyManagerModeEnabled = useSelector((state: AppState) => state.siteSettings.isKeyManagerModeEnabled);
  const isPropertySettingsModalVisible = useSelector((state: AppState) => selectIsPropertySettingsModalVisible(state));
  const selectedContentDisplay = useSelector(
    (state: AppState) => state.deviceManagerState.contentDisplay,
  );
  const currentEntriesPerPage = useSelector((state: AppState) => state.currentEntriesPerPage);

  const [showAddModal, setShowAddModal] = useState(false);

  const [showDeviceExportModal, setShowDeviceExportModal] = useState(false);

  useEffect(() => {
    dispatch(updateDeviceManagerFilter());
  }, [dispatch]);

  const isExportButtonVisible = !isKeyManagerModeEnabled && selectedContentDisplay === DisplayTypes.ASSIGNED;

  const refreshDeviceManagerData = () => {
    if (visibleView !== DeviceViews.TABLE) {
      return;
    }

    if (currentEntriesPerPage === 0) {
      return;
    }

    const fetchDeviceReadings: boolean = !isKeyManagerModeEnabled;
    dispatch(
      fetchDevices(
        selectedContentDisplay,
        currentEntriesPerPage,
        currentPage,
        filter,
        DEVICE_MANAGER_PAGE_KEY,
        fetchDeviceReadings
      )
    );
  };

  useEffect(() => {
    refreshDeviceManagerData();
  }, [
    visibleView,
    currentEntriesPerPage,
    filter,
    selectedContentDisplay,
    currentPage,
  ]);

  const removeUnit = async (id: string, refresh = true) => {
    try {
      dispatch(isTableLoadingAction(true));
      const response = await removeDeviceFromDB(id);

      if (refresh) {
        await refreshDeviceManagerData();
      }

      if (response) {
        toastr.success(
          translate('success').toString(),
          translate('delete-device-success').toString(),
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getSelectedDevices = (): Device[] =>
    (devices ? devices.filter((device: DeviceExtended) => device.checked) : []);

  const handleBulkRemove = async () => {
    const selectedDevices: Device[] = getSelectedDevices();
    const removePromises = selectedDevices.map(async (device) => removeUnit(device.id, false));
    await Promise.all(removePromises);
    await refreshDeviceManagerData();
  };

  const markAllDevicesAsSelected = (event: FormEvent<HTMLInputElement>) => {
    const isChecked = event.currentTarget.checked;
    dispatch(toggleSelectAllDevices(isChecked));
  };

  const onContentDisplayChanged = (value: DisplayTypes) => {
    dispatch(clearPagination(DEVICE_MANAGER_PAGE_KEY));
    dispatch(changeContentDisplay(value));
  };

  const getVisibleView = () => {
    switch (visibleView) {
      case DeviceViews.TABLE:
        return isMobileDisplay ? (
          <MobileTable
            devices={ devices }
            removeUnit={ removeUnit }
            currentPage={ currentPage }
            setCurrentPage={ (pageNumber) => dispatch(setCurrentPageNumber(pageNumber, DEVICE_MANAGER_PAGE_KEY)) }
            currentPageTitle={ currentPageTitle }
            totalDocs={ totalDocs }
            currentEntriesPerPage={ currentEntriesPerPage }
            refreshData={ refreshDeviceManagerData }
            isLoading={ isLoading }
          />
        ) : (
          <TableDataDevManager
            refreshData={ refreshDeviceManagerData }
            removeUnit={ removeUnit }
            markOneDeviceAsSelected={ (idOfDevice) => dispatch(toggleSelectDevice(idOfDevice)) }
            markAllDevicesAsSelected={ markAllDevicesAsSelected }
            isSelectAllChecked={
              devices.length > 0 && devices.length === getSelectedDevices().length
            }
          />
        );
      case DeviceViews.TOPOLOGY:
        return (<DeviceTopology/>);
      case DeviceViews.MAP_VIEW:
        return <MapView/>;
      default:
        return <div>Nothing here</div>;
    }
  };
  return (
    <MainGrid dataTestId="page-device-manager">
      <ErrorBoundary>
        <FilterBar
          page={ DEVICE_MANAGER_PAGE_KEY }
          handleAddBtnClick={ () => setShowAddModal(true) }
          handleDeviceExportBtnClick={ () => setShowDeviceExportModal(true) }
        />
      </ErrorBoundary>
      <Desktop>
        <ErrorBoundary>
          <Table
            page={ DEVICE_MANAGER_PAGE_KEY }
            changeView={ selectedContentDisplay === DisplayTypes.ASSIGNED }
            addModal={ !isKeyManagerModeEnabled }
            addText="device"
            handleBulkRemove={ handleBulkRemove }
            selectedRows={ getSelectedDevices() }
            tableComponent={ getVisibleView() }
            elementType="devices"
            visibleView={ visibleView }
            showPagination={ visibleView === DeviceViews.TABLE }
            showExportButton={ isExportButtonVisible }
            contentDisplayOptions={
              visibleView === DeviceViews.TABLE ? contentDisplayOptions : undefined
            }
            handleContentDisplayToggle={ onContentDisplayChanged }
            selectedContentDisplay={ selectedContentDisplay }
            viewOptions={ viewOptions }
            handleViewToggle={ (deviceView) => dispatch(changeVisibleView(deviceView)) }
            selectedView={ visibleView }
            refreshTableData={ refreshDeviceManagerData }
            editorModalCreator={ (toggleShowEditorModal) => (
              <DeviceActionModal
                closeAddAndUpdateModal={ toggleShowEditorModal }
                title="add-device"
                showDeleteButton={ false }
                addUnit
                refreshDevices={ refreshDeviceManagerData }
              />
            ) }
          />
        </ErrorBoundary>
      </Desktop>
      <Mobile>
        <>
          { getVisibleView() }
          { showAddModal && (
            <DeviceActionModal
              closeAddAndUpdateModal={ () => setShowAddModal(false) }
              title="add-device"
              showDeleteButton={ false }
              addUnit
              refreshDevices={ refreshDeviceManagerData }
            />
          ) }
          <RenderOnCondition condition={ showDeviceExportModal }>
            <DeviceExportModal handleClose={ () => setShowDeviceExportModal(false) }/>
          </RenderOnCondition>
        </>
      </Mobile>

      <RenderOnCondition condition={ isPropertySettingsModalVisible }>
        <PropertyViewSettingsModal/>
      </RenderOnCondition>
    </MainGrid>
  );
};

export default connect()(withLocalize(DeviceManager));
