import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import { FieldArray, FieldArrayRenderProps } from 'formik';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import './AccessRightFormSection.less';
import { IsoStringDateRange } from '@wiot/shared-domain/models/date/iso-string-date-range';
import CustomFieldSelect from '../../../components/Table/CustomFieldSelect';
import GroupSelect from '../../../components/shared/GroupSelect';
import Desktop from '../../../components/Responsive/Desktop';
import Mobile from '../../../components/Responsive/Mobile';
import { DeviceGroupExtended, SetFieldValue } from '../../../state/types';
import { AppState } from '../../../state/reducers/rootReducer';
import { DeviceReadingAccessPeriodsFormSection } from './DeviceReadingAccessPeriodsFormSection';
import { fetchPermittedRoles } from '../../../state/actions/user-action-modal/fetchPermittedRolesActionCreators';
import { UserRole } from '@wiot/shared-domain/models/user/user-role';
import { ICustomFieldSelectOption } from '../../../components/Table/custom-field-select';

interface IAccessRightFormSectionProps {
  modalId: string;
  modalTitle: string;
  setFieldValue: SetFieldValue;
  userRolesFormValue: Partial<UserRole>[] | undefined | null;
}

export const AccessRightFormSection = ({
  modalId,
  modalTitle,
  setFieldValue,
  userRolesFormValue,
}: IAccessRightFormSectionProps): JSX.Element => {
  const [userRoleDeleteIndex, setUserRoleDeleteIndex] = useState<number | null>(null);

  const dispatch = useDispatch();
  const selectableRoles = useSelector((state: AppState) => state.userActionModal.selectableRoles);
  const isKeyManagerModeEnabled = useSelector((state: AppState) => !!state.siteSettings.isKeyManagerModeEnabled);

  useEffect(() => {
    if (selectableRoles.length === 0) {
      dispatch(fetchPermittedRoles());
    }
  }, []);

  function handleDeviceGroupChange(
    deviceGroup: DeviceGroupExtended | string,
    groupSelectSetFieldValue: SetFieldValue,
    userRoleIndex?: number,
  ) {
    if (userRoleIndex || userRoleIndex === 0) {
      groupSelectSetFieldValue(`userRoles[${userRoleIndex}].deviceGroup`, deviceGroup, true);
    }
  }

  function onAccessPeriodsChanged(newAccessPeriods: IsoStringDateRange[], userRolesIndex: number) {
    setFieldValue(
      `userRoles[${userRolesIndex}].permittedDeviceReadingAccessPeriods`,
      newAccessPeriods,
      true,
    );
  }

  function getDeviceReadingAccessPeriodsFormSection(userRole: Partial<UserRole>, index: number): React.ReactElement {
    return (
      <DeviceReadingAccessPeriodsFormSection
        accessPeriods={userRole.permittedDeviceReadingAccessPeriods || []}
        onChange={(newAccessPeriods) => onAccessPeriodsChanged(newAccessPeriods, index)}
      />
    );
  }

  function onRoleSelectChange(
    event: React.FormEvent<HTMLSelectElement>,
    userRoleIndex: number,
  ) {
    const selectedRoleId = event.currentTarget.value;
    const selectedRole = selectableRoles.find((r) => r.id === selectedRoleId);

    if (selectedRole) {
      setFieldValue(`userRoles[${userRoleIndex}].role`, selectedRole, true);
    }
  }

  function getAccessRightSection (userRole: Partial<UserRole>, index: number, arrayHelpers: FieldArrayRenderProps): React.ReactElement {
    return (
      <div
        key={ `userRoles-array-${index}` }
        className={ `px-2 mb-1 border  ${
          userRoleDeleteIndex !== null && userRoleDeleteIndex === index
            ? 'border-danger'
            : 'border-white'
        }`}
      >
        <Row>
          <Col lg={5}>
            <CustomFieldSelect<ICustomFieldSelectOption>
              translationId="role"
              fieldName={`userRoles[${index}].role.name`}
              options={selectableRoles.map((ur) => ({
                id: ur.id || '',
                name: ur.name || '',
              }))}
              translateOptions={false}
              translateNewMapped
              value={userRole.role?.id || ''}
              onChange={(event) => onRoleSelectChange(event, index)}
              required
            />
          </Col>
          <Col lg={5}>
            <GroupSelect
              handleDeviceGroupChange={handleDeviceGroupChange}
              setFieldValue={setFieldValue}
              selectedOption={userRole.deviceGroup?.id || ''}
              userRolesIndex={index}
              targetId={`group-select-${modalTitle}-${modalId}-${index}`}
              required
            />
          </Col>

          <Desktop>
            <Col
              lg={2}
              className="accessrightformsection-deleterow d-flex align-items-center justify-content-end pr-1"
            >
              <span
                className="form__icon"
                onClick={() => arrayHelpers.remove(index)}
                onMouseOverCapture={() => setUserRoleDeleteIndex(index)}
                onMouseOutCapture={() => setUserRoleDeleteIndex(null)}
              >
                <FontAwesomeIcon icon={faTrash}/>
              </span>
            </Col>
          </Desktop>
          <Mobile>
            <Col lg={2}>
              <button
                type="button"
                className="form__button--delete btn-block mt-1 mb-2"
                onClick={() => arrayHelpers.remove(index)}
              >
                <FontAwesomeIcon icon={ faTrash }/>
              </button>
              <div className="user-role-divider"/>
            </Col>
          </Mobile>

        </Row>
        { !isKeyManagerModeEnabled && getDeviceReadingAccessPeriodsFormSection(userRole, index) }
      </div>
    );
  }

  return (
    <div className="accessrightformsection form__section">
      <h4 className="form__subheading">
        <Translate id="access-right" />
      </h4>
      <FieldArray
        name="userRoles"
        render={(arrayHelpers) => {
          return (
            <>
              {(userRolesFormValue?.length === 0 ? [{}] : userRolesFormValue)?.map(
                (userRole: Partial<UserRole>, index: number) => getAccessRightSection(userRole, index, arrayHelpers)
              )}

              <button className="form__button" type="button" onClick={() => arrayHelpers.push({})}>
                <Translate id="additional-access-right" />
              </button>
            </>
          );
        }}
      />
    </div>
  );
};
