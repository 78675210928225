import * as React from 'react';
import { toastr } from 'react-redux-toastr';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { BlobWithPath, ImportOption } from '@wiot/shared-domain/models/data-integration/data-integration';
import RenderOnCondition from '../../../../components/RenderOnCondition';
import CustomFieldSelect from '../../../../components/Table/CustomFieldSelect';
import { validateXML } from '../../../../api/apiHelpers';
import CustomFileInput from '../../../../components/CustomFileInput';
import GroupSelect from '../../../../components/shared/GroupSelect';
import { DeviceGroupExtended, SetFieldValue } from '../../../../state/types';
import { IntegrationModalState } from '../IntegrationActionModal';

interface IXmlImportProps extends LocalizeContextProps {
  setAcceptedFile: (file: BlobWithPath) => void;
  setFieldValue: SetFieldValue;
  formikValues: IntegrationModalState;
  title: string;
}

function XmlImport(props: IXmlImportProps) {
  const {
    translate, setAcceptedFile, setFieldValue, formikValues, title,
  } = props;

  const onXMLDropAccepted = async (droppedFiles: any) => {
    toastr.info(
      translate('validate-xml').toString(),
      translate('xml-validation-started').toString(),
    );

    setAcceptedFile(droppedFiles[0]);

    const formData = new FormData();
    formData.append('xmlFile', droppedFiles[0]);

    const response = await validateXML(formData);

    if (response) {
      setFieldValue('xmlValidationResult', response);

      if (response.err?.msg) return;
      toastr.success(
        translate('validate-xml').toString(),
        translate('xml-validation-success').toString(),
      );
    }
  };

  const onXMLDropRejected = () => {
    toastr.warning(translate('upload-xml').toString(), translate('file-type-invalid').toString());
  };

  const handleDeviceGroupChange = (value: DeviceGroupExtended | string) => {
    setFieldValue('selectedDeviceGroupId', value, false);
  };

  const xmlDropzoneOptions = {
    accept: [
      '.xml',
      'text/xml',
      'text/xml-external-parsed-entity',
      'text/plain',
      'application/xml',
      'application/xhtml+xml',
    ],
    onDropAccepted: onXMLDropAccepted,
    onDropRejected: onXMLDropRejected,
    multiple: false,
  };

  return (
    <>
      <div className="form__section">
        <CustomFileInput options={xmlDropzoneOptions} label="files" />
      </div>
      <RenderOnCondition condition={formikValues.xmlValidationResult?.newObjects?.devices?.length}>
        <div className="form__section">
          <div className="form__row">
            <CustomFieldSelect
              translationId="choose-import-devices-options"
              fieldName="importOption"
              options={Object.values(ImportOption).map(option => ({ id: option, name: option }))}
              value={formikValues.importOption}
              translateOptions
              required
            />
          </div>
        </div>
      </RenderOnCondition>
      <RenderOnCondition condition={formikValues.xmlValidationResult?.newObjects?.keys?.length}>
        <div className="form__section">
          <div className="form__row">
            <div className="form__label my-3">
              <Translate id="please-choose-device-group-for-key-assignment" />
              :
            </div>
          </div>
        </div>
        <div className="form__section">
          <div className="form__row">
            <GroupSelect
              handleDeviceGroupChange={handleDeviceGroupChange}
              setFieldValue={setFieldValue}
              selectedOption={formikValues.selectedDeviceGroupId || ''}
              setGroupId
              required
              targetId={`group-select-${title}`}
            />
          </div>
        </div>
      </RenderOnCondition>
    </>
  );
}

export default withLocalize(XmlImport);
