import React, { useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { DeviceGroupType } from '@wiot/shared-domain/models/device-group/device-group';
import DeviceActionModal from '../../DeviceManager/DeviceActionModal/DeviceActionModal';
import ConfirmModal, { ConfirmationVariant } from '../../../components/Modal/ConfirmModal';
import { DEVICE_GROUPS_TABLE_HEADERS as TABLE_HEADERS, deviceGroupIcons } from '../../../constants';
import QUESTION_MARK from '../../../assets/devices/question-mark-small.svg';
import { getDeviceGroupPathFromAncestors } from '../../../utils/device-group-path-helper';
import RenderOnCondition from '../../../components/RenderOnCondition';
import DeviceGroupDetailsModal from '../DeviceGroupDetailsModal';
import { DeviceGroupExtended } from '../../../state/types';
import TableMenu from '../Table/TableMenu';
import DeviceGroupActionModal from '../DeviceGroupActionModal';
import UserActionModal from '../../UserManagement/UserActionModal/UserActionModal';
import { getSelectedMetaDataValue } from '../../DeviceManager/DeviceActionModal/metaDataUtilityFunctions';
import { DeviceMetadataFields } from '@wiot/shared-domain/models/device/device';
import { AppState } from '../../../state/reducers/rootReducer';
import { getMenuId } from '../../../components/PortalMenu/constants/context-menu-ids';
import { closeContextMenu, openContextMenu } from '../../../state/context-menu/toggleContextMenuActionCreators';
import { Tooltip } from '../../../components/shared/Tooltip';

interface IDataRow {
  deviceGroup: DeviceGroupExtended;
  removeUnit: (id: string) => Promise<void>;
  refreshData: () => void;
}

const DataRow = (props: IDataRow) => {
  const menuButtonRef = useRef(null);
  const [showDeviceGroupDetails, setShowDeviceGroupDetails] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeviceGroupActionModal, setShowDeviceGroupActionModal] = useState(false);
  const [
    showDeviceGroupActionAddGroupInGroupModal,
    setShowDeviceGroupActionAddGroupInGroupModal,
  ] = useState(false);
  const [
    showDeviceGroupActionAddDeviceInGroupModal,
    setShowDeviceGroupActionAddDeviceInGroupModal,
  ] = useState(false);
  const [showUserActionModalAddUserInGroup, setShowUserActionModalAddUserInGroup] = useState(false);
  const isKeyManagerModeEnabled = useSelector((state: AppState) => !!state.siteSettings.isKeyManagerModeEnabled);
  const visibleContextMenu = useSelector((state: AppState) => state.contextMenu.visibleContextMenuId);

  const dispatch = useDispatch();

  const { deviceGroup } = props;
  const downloadHash = getSelectedMetaDataValue(DeviceMetadataFields.metadata, 'downloadHash', deviceGroup);

  const { id, name, type, abbreviation, hasChildren } = deviceGroup;

  const { truncatedPath } = getDeviceGroupPathFromAncestors(deviceGroup.ancestors || []);

  const menuId = getMenuId(deviceGroup.id);
  const showMenu = (visibleContextMenu === menuId);
  const closeMenu = (event?: React.MouseEvent) => {
    event && event.preventDefault();
    dispatch(closeContextMenu());
  }
  const toggleMenu = () => {
    if (!showMenu) {
      dispatch(openContextMenu(menuId));
    } else {
      dispatch(closeContextMenu());
    }
  };

  const toggleDeviceGroupDetails = (
    event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent,
  ) => {
    event && event.preventDefault();
    setShowDeviceGroupDetails((prevState) => !prevState);
    closeMenu();
  };

  const toggleShowMenu = (event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent) => {
    event && event.preventDefault();
    toggleMenu();
  };

  const toggleDeleteModal = () => {
    setShowDeleteModal((prevState) => !prevState);
    closeMenu();
  };

  const toggleDeviceGroupActionModal = (
    event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent,
  ) => {
    if (event) {
      event.stopPropagation();
    }
    setShowDeviceGroupActionModal((prevState) => !prevState);
    closeMenu();
  };

  const toggleDeviceGroupActionAddGroupInGroupModal = (
    event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent,
  ) => {
    if (event) {
      event.stopPropagation();
    }
    setShowDeviceGroupActionAddGroupInGroupModal((prevState) => !prevState);
    closeMenu();
  };

  const toggleDeviceActionModalAddDeviceInGroup = (
    event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent,
  ) => {
    if (event) {
      event.stopPropagation();
    }
    setShowDeviceGroupActionAddDeviceInGroupModal((prevState) => !prevState);
    closeMenu();
  };

  const toggleUserActionModalAddUserInGroup = (
    event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent,
  ) => {
    if (event) {
      event.stopPropagation();
    }
    setShowUserActionModalAddUserInGroup((prevState) => !prevState);
    closeMenu();
  };

  return (
    <div className="data-card-container">
      <Row className="data-card-container__header">
        <div className="data-card-container__header__title text-overflow-ellipsis">{name}</div>
      </Row>
      <Row className="data-card-container__device-type">
        <img
          data-tip="device-group-type-icon"
          data-for={`deviceGroupType-${id}-${type}`}
          src={(type && deviceGroupIcons[type as DeviceGroupType]) || QUESTION_MARK}
          alt="device group type"
        />
        <Tooltip
          id={`deviceGroupType-${id}-${type}`}
          place="right"
        >
          {type ? <Translate id={type} /> : '-'}
        </Tooltip>
      </Row>
      <Row className="data-card-container__body">
        <Col xs={4} className="data-card-container__key">
          <Translate id={TABLE_HEADERS[0]} />
          {':'}
        </Col>
        <Col className="data-card-container__value">{name || '-'}</Col>
      </Row>
      <RenderOnCondition condition={!isKeyManagerModeEnabled}>
        <Row className="data-card-container__body">
          <Col xs={4} className="data-card-container__key">
            <Translate id={TABLE_HEADERS[1]} />
            {':'}
          </Col>
          <Col className="data-card-container__value">{abbreviation || '-'}</Col>
        </Row>
      </RenderOnCondition>
      <Row className="data-card-container__body">
        <Col xs={4} className="data-card-container__key">
          <Translate id={TABLE_HEADERS[2]} />
          {':'}
        </Col>
        <Col className="data-card-container__value">{type ? <Translate id={type} /> : '-'}</Col>
      </Row>
      <Row className="data-card-container__body">
        <Col xs={4} className="data-card-container__key">
          <Translate id={TABLE_HEADERS[4]} />
          {':'}
        </Col>
        <Col className="data-card-container__value">{truncatedPath}</Col>
      </Row>
      <Row className="data-card-container__footer justify-content-center">
        <Col xs={9} className="text-center">
          <span className="device-details-btn" onClick={toggleDeviceGroupDetails}>
            <Translate id="details" />
          </span>
          <RenderOnCondition condition={showDeviceGroupDetails}>
            <DeviceGroupDetailsModal
              closeModal={toggleDeviceGroupDetails}
              deviceGroupData={deviceGroup}
            />
          </RenderOnCondition>
        </Col>
        <Col xs={3} ref={menuButtonRef}>
          <div className="device-details">
            <div className="device-details-icon" role="presentation" onClick={toggleShowMenu}>
              <FontAwesomeIcon icon={faEllipsisV} />
            </div>
            <RenderOnCondition condition={showMenu}>
              <TableMenu
                isMobileDisplay
                deviceGroupData={deviceGroup}
                removeUnit={props.removeUnit}
                menuId={ menuId }
                toggleDetails={toggleDeviceGroupDetails}
                toggleDeviceGroupActionModal={toggleDeviceGroupActionModal}
                toggleDeleteModal={toggleDeleteModal}
                toggleDeviceGroupActionModalAddGroupInGroup={
                  toggleDeviceGroupActionAddGroupInGroupModal
                }
                toggleDeviceActionModalAddDeviceInGroup={toggleDeviceActionModalAddDeviceInGroup}
                toggleUserActionModalAddUserInGroup={toggleUserActionModalAddUserInGroup}
                downloadHash={downloadHash}
              />
            </RenderOnCondition>
            <RenderOnCondition condition={showDeviceGroupActionAddDeviceInGroupModal}>
              <DeviceActionModal
                closeAddAndUpdateModal={toggleDeviceActionModalAddDeviceInGroup}
                title="add-dev-under-group"
                showDeleteButton={false}
                addUnit
                selectedDeviceGroup={{ id }}
                disableGroupSelect
              />
            </RenderOnCondition>
            <RenderOnCondition condition={showDeviceGroupActionModal}>
              <DeviceGroupActionModal
                closeAddAndUpdateModal={toggleDeviceGroupActionModal}
                title="update-group"
                showDeleteButton
                addUnit={false}
                removeUnit={(itemId: string) => props.removeUnit(itemId)}
                id={id}
                refreshGroups={props.refreshData}
              />
            </RenderOnCondition>
            <ConfirmModal
              show={showDeleteModal}
              modalCloseRequested={toggleDeleteModal}
              actionConfirmed={() => props.removeUnit(id!)}
              translationIdOfElementType="device-group"
              translationIdOfAdditionalInfo={ hasChildren ? 'confirm-to-delete-group' : '' }
              confirmationVariant={ ConfirmationVariant.DELETE }
            />
            <RenderOnCondition condition={showDeviceGroupActionAddGroupInGroupModal}>
              <DeviceGroupActionModal
                closeAddAndUpdateModal={toggleDeviceGroupActionAddGroupInGroupModal}
                title="add-group"
                showDeleteButton={false}
                addUnit
                selectedDeviceGroup={id}
                disableGroupSelect
                refreshGroups={props.refreshData}
              />
            </RenderOnCondition>
            <RenderOnCondition condition={showUserActionModalAddUserInGroup}>
              <UserActionModal
                saved={() => {
                  props.refreshData();
                  toggleUserActionModalAddUserInGroup();
                }}
                cancelled={toggleUserActionModalAddUserInGroup}
                title="add-user-under-group"
                showDeleteButton={false}
                isInCreationMode
                selectedDeviceGroupId={id}
              />
            </RenderOnCondition>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DataRow;
