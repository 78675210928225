import { DeviceViews, DisplayTypes } from '@wiot/shared-domain/models/device/device';
import { RESET_DEVICE_MANAGER_STATE, SET_DEVICE_MANAGER_CONTENT_DISPLAY, SET_DEVICE_MANAGER_VISIBLE_VIEW, } from '../constants/actionTypes';

export interface IDeviceManagerState {
  visibleView: DeviceViews;
  contentDisplay: DisplayTypes;
}

const initialState: IDeviceManagerState = {
  visibleView: DeviceViews.TABLE,
  contentDisplay: DisplayTypes.ASSIGNED,
};

export const deviceManagerStateReducer = (
  state = initialState,
  action: { type: string; payload: DeviceViews | DisplayTypes },
): IDeviceManagerState => {
  switch (action.type) {
    case SET_DEVICE_MANAGER_VISIBLE_VIEW:
      return <IDeviceManagerState>{
        ...state,
        visibleView: action.payload,
      };
    case SET_DEVICE_MANAGER_CONTENT_DISPLAY:
      return <IDeviceManagerState>{
        ...state,
        contentDisplay: action.payload,
      };
    case RESET_DEVICE_MANAGER_STATE:
      return <IDeviceManagerState>{
        ...initialState,
      };
    default:
      return state;
  }
};
