import React from 'react';
import { DeviceViews, DisplayTypes } from '@wiot/shared-domain/models/device/device';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { hasPermission } from '../../utils/common';
import { AppState } from '../../state/reducers/rootReducer';
import { FeatureAvailability, isAvailableInCurrentMode } from '../../utils/feature-availability';

export interface ToggleOption {
  value: string;
  icon: IconDefinition;
  permissionKey?: string;
  availability: FeatureAvailability
}

export interface ToggleProps extends LocalizeContextProps {
  label?: string;
  handleToggle?: (value: any) => void;
  options?: ToggleOption[];
  selectedOption?: DisplayTypes | DeviceViews;
}

const Toggle = ({
  handleToggle,
  options: contentDisplayOptions,
  selectedOption: selectedContentDisplay,
  translate,
  label,
}: ToggleProps) => {
  const userPermission = useSelector((state: AppState) => state.currentUser.permission);
  const isKeyManagerModeEnabled = useSelector((state: AppState) => state.siteSettings.isKeyManagerModeEnabled);

  const getPermittedOptions = (options?: ToggleOption[]) =>
    options?.filter((opt) => {
      if (!isAvailableInCurrentMode(opt.availability, isKeyManagerModeEnabled)) {
        return false;
      }
      if (opt.permissionKey) {
        return hasPermission(userPermission, opt.permissionKey);
      }
      return true;
    });

  const permittedOptions = getPermittedOptions(contentDisplayOptions);

  return handleToggle && permittedOptions && selectedContentDisplay ? (
    <div className="toggle-btn-group-wrapper__container">
      <span className={ `table__header__subheading ${ label }` }>{ label && translate(label) }</span>
      <ToggleButtonGroup
        className="toggle-btn-group-wrapper"
        name="toggleContentDisplay"
        value={ selectedContentDisplay }
        onChange={ handleToggle }
      >
        { permittedOptions.map((option) => (
          <ToggleButton
            value={ option.value }
            key={ option.value }
            size="sm"
            variant="outline-info"
            data-tip={ translate(`toggle-option-${ option.value.toLowerCase() }`) }
          >
            <FontAwesomeIcon icon={ option.icon }/>
          </ToggleButton>
        )) }
      </ToggleButtonGroup>
    </div>
  ) : null;
};

export default withLocalize(Toggle);
