import { DeviceGroupExtended } from '../state/types';
import { SelectableOption } from '../components/FilterBar/select/selectable-option';

// TODO : Types must be simplified and the types cleaned up. A bit too weird.
export function getDeviceGroupIds(
  deviceGroupFilter: string[] | DeviceGroupExtended[][] | null | undefined)
  : string[] | undefined {
  const deviceGroupIds = (deviceGroupFilter as DeviceGroupExtended[][] | undefined)?.map(
    (option) => option.length > 0 ? option[option.length - 1].id! : '');

  return deviceGroupIds;
}

export function getDeviceTypeIds(
  deviceTypeFilter: SelectableOption[] | undefined)
  : string[] | undefined {
  const deviceTypeIds: string[] = [];
  deviceTypeFilter?.map((option: SelectableOption) => {
    if (option.value) {
      deviceTypeIds.push(option.value);
    }
  });

  return deviceTypeIds;
}

export function getGroupTypes(
  groupTypeFilter: SelectableOption[] | undefined,
) : string[] | undefined {
  const groupTypes: string[] = [];

  groupTypeFilter?.map((option: SelectableOption) => {
    if (option.value) {
      groupTypes.push(option.value);
    }
  });

  if (groupTypes.length === 0) {
    return undefined;
  }

  return groupTypes;
}
