import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Translate } from 'react-localize-redux';
import { Link } from 'react-router-dom';
import React from 'react';
import { RESET_PASSWORD_PATH } from '../../navigation/paths';
import { Tooltip } from '../shared/Tooltip';

const LoginHelpIndicator = () => (
  <div className="login-form__icons__help">
    <FontAwesomeIcon
      data-tip="login-form__icons__help"
      data-for="icons_help"
      icon={faQuestionCircle}
    />
    <Tooltip
      id="icons_help"
      type="light"
      className="login-form__icons__tooltip"
    >
      <div className="login-form__icons__tooltip__heading">
        <Translate id="login" />
      </div>
      <div className="login-form__icons__tooltip__text">
        <Translate id="login-indicator-line-1" />
      </div>
      <div className="login-form__icons__tooltip__text">
        <Translate id="login-indicator-line-2" />
      </div>
      <div className="login-form__icons__tooltip__text">
        <Translate id="login-indicator-line-3" />
      </div>
      <div className="login-form__forgot-pw">
        <Link
          to={ RESET_PASSWORD_PATH }
          className="login-form__forgot-pw__button"
          type="text"
          data-testid="reset-password"
        >
          <Translate id="forgot-pw" />
        </Link>
      </div>
    </Tooltip>
  </div>
);

export default LoginHelpIndicator;
