import React from 'react';
import { Place } from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DeviceStatus } from '@wiot/shared-domain/models/device/device';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { getStatusIcon } from '../../utils/common';
import { Tooltip } from '../../components/shared/Tooltip';

interface DeviceStatusPilProps extends LocalizeContextProps {
  status: DeviceStatus[];
  id: any;
  tooltipPlace: Place;
}

const DeviceStatusPil = (props: DeviceStatusPilProps) => {
  const { status, id, tooltipPlace } = props;

  const getStatusTooltipContent = (deviceStatus: DeviceStatus[]) => {
    const translatedStatus = deviceStatus.map((s) => props.translate(s));
    return translatedStatus.join(' | ');
  };

  return (
    <div
      data-tip="pil"
      data-for={status.join(' ') + id}
      className={`pil ${status.includes(DeviceStatus.OK) ? 'pil-green' : 'pil-red'}
              `}
    >
      <span className="pil__text">
        {status.map((st, i) => (
          <FontAwesomeIcon key={i} icon={getStatusIcon(st || '')} size="sm" className="pil-fa" />
        ))}
      </span>
      <Tooltip
        id={status.join(' ') + id}
        place={tooltipPlace}
        className="device-manager__table__table__trow__pil__tooltip"
      >
        {getStatusTooltipContent(status)}
      </Tooltip>
    </div>
  );
};

export default withLocalize(DeviceStatusPil);
