import React, { useEffect, useRef } from 'react';
import { Field } from 'formik';
import classNames from 'classnames';
import { toastr } from 'react-redux-toastr';
import { LocalizedElement, LocalizedElementMap, Translate } from 'react-localize-redux';
import { Col, Row } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Clipboard from 'react-clipboard.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList, faEye, faEyeSlash, faRedo, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import RenderOnCondition from '../RenderOnCondition';
import AesKeyHintIconWithTooltip from '../shared/AesKeyHintIconWithTooltip';
import useTranslation from '../../hooks/useTranslation';
import { SetFieldValue } from '../../state/types';
import LoginPasswordIndicator from '../Login/LoginPasswordIndicator';
import { InputContextType } from '../Input/input-context-type';

interface CustomFieldAdvancedInputProps {
  fieldName: string;
  id?: string;
  type: 'text' | 'password' | 'number';
  inputContextType?: InputContextType;
  value?: string | number;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  setFieldValue?: SetFieldValue;
  validate?: (value: any) => string | undefined;

  translationId?: string;
  label?: LocalizedElement | LocalizedElementMap;
  placeholder?: string;
  inputFontFamily?: string;
  className?: string;

  minLength?: number;
  maxLength?: number;

  required?: boolean;
  readOnly?: boolean;
  error?: string;
  touched?: boolean;

  autoFocus?: boolean;
  autoComplete?: string;

  isTextarea?: boolean;

  icon?: IconDefinition;
  onIconClick?: (event: React.MouseEvent) => void;

  onKeyPress?: (e: React.KeyboardEvent) => void;

  showEyeButton?: boolean;
  initialIsValueVisible?: boolean;
  onEyeButtonClick?: () => void;
  showCopyButton?: boolean;
  showPasswordHintButton?: boolean;
  showAesKeyHintButton?: boolean;
  showGenerateRandomValueButton?: boolean;
  generateRandomValue?: () => void;

  step?: number;
  rows?: number;

  children?: React.ReactNode | React.ReactNode[];
}

export const CustomFieldAdvancedInput = (props: CustomFieldAdvancedInputProps) => {
  const {
    type= 'text',
    translationId,
    inputFontFamily,
    inputContextType = InputContextType.MODAL,
    isTextarea = false,
    autoComplete = 'off',
    icon,
    onIconClick,
    showEyeButton,
    initialIsValueVisible,
    onEyeButtonClick,
    showCopyButton,
    showPasswordHintButton,
    showAesKeyHintButton,
    showGenerateRandomValueButton,
    generateRandomValue,
    children,

    ...fieldProps

  } = props;

  const {
    fieldName,
    required = false,
    readOnly = false,
    error,
    touched,
    autoFocus,
  } = fieldProps;

  const [isFocused, setIsFocused] = React.useState(false);
  const [isValueVisible, setIsValueVisible] = React.useState(initialIsValueVisible);

  const translate = useTranslation();

  const inputElement = useRef<HTMLInputElement>(null);

  const showCustomButton = !readOnly && icon;

  useEffect(() => {
    if (autoFocus) {
      focusInputField();
    }
  }, []);

  const focusInputField = () => {
    inputElement.current?.focus();
  }

  const toggleVisibility = () => {
    setIsValueVisible(!isValueVisible);
    if (onEyeButtonClick) {
      onEyeButtonClick();
    }
  }

  const onCopySuccess = () => {
    toastr.success(translate('copy').toString(), translate('copy-clipboard-success').toString());
  };

  const onCopyError = () => {
    toastr.error(translate('copy').toString(), translate('copy-clipboard-error').toString());
  };

  const onGenerateRandomValueClick = () => {
    if (!generateRandomValue) {
      return;
    }
    setIsValueVisible(false);
    generateRandomValue();
  }

  const getInputType = () => type === 'password' && isValueVisible ? type : 'text'

  const getInputComponent = () => {
    if (isTextarea) {
      return 'textarea';
    }
    return 'input';
  }

  return (
    <div className="form__label">
      <RenderOnCondition condition={ translationId }>
        <label className={ required ? 'required' : '' }>
          { `${ translationId ? translate(translationId) : undefined }` }
        </label>
      </RenderOnCondition>

      <Field
        readOnly={fieldProps.readOnly}
      >
        { ({ field, form, meta }) => (
          <>
            <Row
              className={ classNames(
                `${ inputContextType }-input`,
                {
                  [`${ inputContextType }-input--readonly`] : readOnly,
                  [`${ inputContextType }-input--active`] : !readOnly && isFocused,
                },
                inputFontFamily,
              ) }
              onClick={ () => { focusInputField() } }
            >
              <Col className="nested-input-text-wrapper">
                <Field as={ "Input" }
                  { ...fieldProps }
                  name={ fieldName }
                  innerRef={ inputElement }
                  className="nested-input-text"
                  type={ getInputType() }
                  autoComplete={ autoComplete }
                  component={ getInputComponent() }
                  data-testid={`settings-${fieldName}`}
                  onFocus={ () => { setIsFocused(true) } }
                  onBlur={ () => { setIsFocused(false) } }
                />
              </Col>

              <RenderOnCondition condition={ showCustomButton }>
                <Col className={ 'nested-input-icon' } onClick={ onIconClick ? onIconClick : focusInputField }>
                  <FontAwesomeIcon
                    icon={ icon! }
                    size="lg"
                  />
                </Col>
              </RenderOnCondition>
              <RenderOnCondition condition={ showEyeButton || type === "password" }>
                <Col className="nested-input-icon" onClick={ toggleVisibility }>
                  <FontAwesomeIcon
                    icon={ isValueVisible ? faEye : faEyeSlash }
                    size="lg"
                    className="nested-input-icon-eye"
                  />
                </Col>
              </RenderOnCondition>
              <RenderOnCondition condition={ showCopyButton }>
                <Col className="nested-input-icon">
                  <Clipboard
                    onSuccess={ onCopySuccess }
                    onError={ onCopyError }
                    data-clipboard-text={ `${ fieldProps.value }` }
                    className={ 'nested-input-button' }
                    title={ translate('copy').toString() }
                  >
                    <FontAwesomeIcon
                      icon={ faClipboardList }
                      size="lg"
                    />
                  </Clipboard>
                </Col>
              </RenderOnCondition>
              <RenderOnCondition condition={ showPasswordHintButton }>
                <Col className="nested-input-icon mt-1">
                  <LoginPasswordIndicator
                    tooltipPlace="right"
                    className="nested-input-icon ml-2"
                  />
                </Col>
              </RenderOnCondition>
              <RenderOnCondition condition={ showAesKeyHintButton }>
                <Col className="nested-input-icon">
                  <AesKeyHintIconWithTooltip/>
                </Col>
              </RenderOnCondition>

              <RenderOnCondition condition={ showGenerateRandomValueButton && generateRandomValue }>
                <Col className="nested-input-icon nested-input-icon-generate" onClick={ onGenerateRandomValueClick }>
                  <Badge className="pil pil-long background-color-white text-color-main w-100">
                    <FontAwesomeIcon icon={ faRedo } size="sm" className="mr-1" />
                    <Translate id="generate" />
                  </Badge>
                </Col>
              </RenderOnCondition>

              { children }

            </Row>
            { !!error && touched && <div className="input-error input-error-mt">{ error }</div> }
          </>
        ) }
      </Field>
    </div>
  );
}
