import React, { useCallback, useEffect, useState } from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';
import { AggregatedPermission } from '@wiot/shared-domain/models/role/role';
import { IColumnObject } from '@wiot/shared-domain/models/device/device';
import FilterBar from '../../components/Filter/FilterBar';
import Table from '../../components/Table/Table';
import { DeviceGroupExtended, IPagination } from '../../state/types';
import { getFetchOptions, hasPermission } from '../../utils/common';
import { fetchDeviceGroupsFromDB, removeDeviceGroupFromDB } from '../../api/apiHelpers';
import TableDataDevGroup from './Table/TableDataDevGroups';
import { AppState } from '../../state/reducers/rootReducer';
import { saveDeviceGroups as saveDeviceGroupsAction } from '../../state/actions/saveDeviceGroupsAction';
import { savePagination as savePaginationAction } from '../../state/actions/savePaginationAction';
import { isLoading } from '../../state/actions/isLoadingAction';
import { isTableLoading } from '../../state/table/isTableLoadingAction';
import { toggleSelectUnit as toggleSelectUnitAction } from '../../state/actions/toggleSelectDeviceGroupAction';
import { toggleSelectAllUnits as toggleSelectAllUnitsAction } from '../../state/actions/toggleSelectAllDeviceGroupsAction';
import ErrorBoundary from '../../components/ErrorBoundary';
import Desktop from '../../components/Responsive/Desktop';
import Mobile from '../../components/Responsive/Mobile';
import MobileTable from './Mobile/MobileTable';
import RenderOnCondition from '../../components/RenderOnCondition';
import DeviceGroupActionModal from './DeviceGroupActionModal';
import { IDeviceGroupFilter } from '../../state/reducers/filterSortReducer';
import MainGrid from '../../components/shared/MainGrid';
import { updateDeviceGroupFilter } from '../../state/filter/updateDeviceGroupFilterActionCreator';
import PropertyViewSettingsModal from '../PropertyView/Consumption/Table/PropertyViewSettingsModal';
import { handleDeviceGroupDeletionResponse } from '../../state/device-group/deviceGroupRemovalResponseHandler';

export interface DeviceGroupsProps extends LocalizeContextProps {
  deviceGroups: DeviceGroup[];
  saveDeviceGroups: (deviceGroups: DeviceGroup[]) => void;
  savePagination: (paginationData: IPagination) => void;
  toggleSelectUnit: (id: string) => void;
  toggleSelectAllUnits: (isChecked: boolean) => void;
  currentEntriesPerPage: number;
  currentPage: number;
  totalDocs: number;
  setIsLoading: (loading: boolean) => void;
  setIsTableLoading: (loading: boolean) => void;
  isLoading: boolean;
  filter: IDeviceGroupFilter;
  permission?: AggregatedPermission;
  updateDeviceGroupFilter: () => void;
  isPropertySettingsModalVisible: boolean;
}

const DeviceGroups = (props: DeviceGroupsProps) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const {
    toggleSelectUnit,
    toggleSelectAllUnits,
    saveDeviceGroups,
    filter,
    currentEntriesPerPage,
    currentPage,
    totalDocs,
    permission,
    deviceGroups,
    translate,
    setIsTableLoading,
    savePagination,
    setIsLoading,
    updateDeviceGroupFilter,
    isPropertySettingsModalVisible,
  } = props;

  const fetchDeviceGroups = useCallback(
    async (column?: IColumnObject) => {
      const fetchOptions = getFetchOptions<IDeviceGroupFilter>(
        currentEntriesPerPage,
        column,
        filter,
        currentPage,
      );

      setIsTableLoading(true);
      try {
        const res = await fetchDeviceGroupsFromDB(
          true,
          fetchOptions,
          true,
          true,
          false,
          false,
        );
        if (res && res.deviceGroups) {
          saveDeviceGroups(res.deviceGroups);
          const paginationData: IPagination = { totalDocs: res.totalDocs ?? 0, totalPages: res.totalPages };
          savePagination(paginationData);
          setIsLoading(false);
          setIsTableLoading(false);
        }
      } catch (e) {
        setIsLoading(false);
        setIsTableLoading(false);
      }
    },
    [
      currentEntriesPerPage,
      currentPage,
      filter,
      saveDeviceGroups,
      savePagination,
      setIsLoading,
      setIsTableLoading,
    ],
  );

  useEffect(() => {
    updateDeviceGroupFilter();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    fetchDeviceGroups();
  }, [fetchDeviceGroups, setIsLoading]);

  const removeUnit = async (id: string) => {
    setIsTableLoading(true);
    const response = await removeDeviceGroupFromDB(id);
    handleDeviceGroupDeletionResponse(translate, response);
    setIsTableLoading(false);
  };

  const getSelectedDeviceGroups = (): DeviceGroupExtended[] =>
    deviceGroups
      ? deviceGroups.filter((deviceGroup: DeviceGroupExtended) => deviceGroup.checked)
      : [];

  const handleBulkRemove = async () => {
    const selectedDeviceGroups: DeviceGroupExtended[] = getSelectedDeviceGroups();
    const removePromises = selectedDeviceGroups.map(async (deviceGroup) =>
      removeUnit(deviceGroup.id!),
    );
    await Promise.all(removePromises);
    await fetchDeviceGroups();
  };

  const selectAll = (event: React.FormEvent<HTMLInputElement>) => {
    const isChecked = event.currentTarget.checked;
    toggleSelectAllUnits(isChecked);
  };

  const toggleShowAddModal = () => {
    setShowAddModal((prevState) => !prevState);
  };

  const getTableComponent = () => (
    <TableDataDevGroup
      refreshData={ fetchDeviceGroups }
      removeUnit={ removeUnit }
      markOneDeviceGroupAsSelected={ toggleSelectUnit }
      markAllDeviceGroupsAsSelected={ selectAll }
      isSelectAllChecked={ deviceGroups.length === getSelectedDeviceGroups().length }
    />
  );

  return (
    <MainGrid dataTestId="page-device-groups">
      <ErrorBoundary>
        <FilterBar page="device-groups" handleAddBtnClick={ toggleShowAddModal }/>
      </ErrorBoundary>
      <Desktop>
        <ErrorBoundary>
          <Table
            page="device-groups"
            changeView={ false }
            addModal={ hasPermission(permission, 'deviceGroups.add') }
            addText="group"
            tableComponent={ getTableComponent() }
            selectedRows={ getSelectedDeviceGroups() }
            handleBulkRemove={ handleBulkRemove }
            elementType="device-groups"
            showPagination
            refreshTableData={ fetchDeviceGroups }
            editorModalCreator={ (toggleShowEditorModal) => (
              <DeviceGroupActionModal
                closeAddAndUpdateModal={ toggleShowEditorModal }
                title="add-group"
                showDeleteButton={ false }
                addUnit
                refreshGroups={ fetchDeviceGroups }
              />
            ) }
          />
        </ErrorBoundary>
      </Desktop>
      <Mobile>
        <>
          <MobileTable
            removeUnit={ removeUnit }
            refreshData={ fetchDeviceGroups }
            totalDocs={ totalDocs }
          />
          <RenderOnCondition condition={ showAddModal }>
            <DeviceGroupActionModal
              closeAddAndUpdateModal={ toggleShowAddModal }
              title="add-group"
              showDeleteButton={ false }
              addUnit
              refreshGroups={ fetchDeviceGroups }
            />
          </RenderOnCondition>
        </>
      </Mobile>

      <RenderOnCondition condition={ isPropertySettingsModalVisible }>
        <PropertyViewSettingsModal/>
      </RenderOnCondition>
    </MainGrid>
  );
};

const mapStateToProps = (state: AppState) => ({
  deviceGroups: state.deviceGroups,
  currentEntriesPerPage: state.currentEntriesPerPage,
  currentPage: state.pagination['device-groups'].currentPage,
  totalDocs: state.pagination['device-groups'].totalDocs,
  filter: state.filters.filter['device-groups'],
  permission: state.currentUser.permission,
  isPropertySettingsModalVisible: state.properties.settingsModal.isVisible,
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  toggleSelectUnit: (device: string) => dispatch(toggleSelectUnitAction(device)),
  toggleSelectAllUnits: (isChecked: boolean) => dispatch(toggleSelectAllUnitsAction(isChecked)),
  saveDeviceGroups: (deviceGroups: DeviceGroup[]) => dispatch(saveDeviceGroupsAction(deviceGroups)),
  savePagination: (paginationData: IPagination) =>
    dispatch(savePaginationAction(paginationData, 'device-groups')),
  setIsLoading: (loading: boolean) => dispatch(isLoading(loading)),
  setIsTableLoading: (loading: boolean) => dispatch(isTableLoading(loading)),
  updateDeviceGroupFilter: ()=> dispatch(updateDeviceGroupFilter())
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(DeviceGroups));
