import { DeviceGroupType } from '@wiot/shared-domain/models/device-group-types/device-group-types';
import { DeviceGroupType as DeviceGroupTypes } from '@wiot/shared-domain/models/device-group/device-group';

const includedTypesInKeyManagerMode = [
  DeviceGroupTypes.BUILDING,
  DeviceGroupTypes.STREET,
  DeviceGroupTypes.TOWN,
  DeviceGroupTypes.COUNTRY,
  DeviceGroupTypes.DISTRICT,
  DeviceGroupTypes.ROOM,
]

export function filterDeviceGroupTypes(deviceGroupTypes: DeviceGroupType[], isKeyManagerModeEnabled: boolean): DeviceGroupType[] {
  if (!isKeyManagerModeEnabled) {
    return deviceGroupTypes;
  }

  const keyManagerDeviceGroupTypes =
    deviceGroupTypes.filter(dgt => includedTypesInKeyManagerMode.includes(DeviceGroupTypes[dgt.name as DeviceGroupTypes]));

  return keyManagerDeviceGroupTypes;
}
