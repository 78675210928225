import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { getIn } from 'formik';
import { Translate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { IRole } from '@wiot/shared-domain/models/role/role';
import CustomToggle from '../../../components/Table/CustomToggle';
import { SetFieldValue } from '../../../state/types';
import NestedRoleGroup from './NestedRoleGroup';
import MessageRoleGroup from './MessageRoleGroup';
import { getRoleSectionStructure } from '../../../utils/common';
import { AppState } from '../../../state/reducers/rootReducer';
import HasPermission from '../../../components/HasPermission';
import RenderOnCondition from '../../../components/RenderOnCondition';
import PermittedRoleSelect from './PermittedRoleSelect';

interface IRoleGroup {
  groupKey: string;
  values: IRole;
  setFieldValue: SetFieldValue;
  readOnly?: boolean;
  groupToggleChange?: (
    name: string,
    value: boolean,
    setFieldValue: SetFieldValue,
    values: IRole,
  ) => void;
}

const RoleGroup = ({
  groupKey,
  values,
  readOnly,
  setFieldValue,
  groupToggleChange,
}: IRoleGroup) => {
  const userPermission = useSelector<AppState>((state) => state.currentUser.permission);
  const groupPathArr = groupKey.split('.');
  const groupObj = getIn(values, groupPathArr);
  const isKeyManagerModeEnabled = useSelector((state: AppState) => !!state.siteSettings.isKeyManagerModeEnabled);
  const structureObj = getRoleSectionStructure(groupPathArr, groupKey, isKeyManagerModeEnabled);
  const boolKeys: string[] = [];
  const objectKeys: string[] = [];

  if (!structureObj) {
    return null;
  }

  Object.keys(structureObj).forEach((key) => {
    const value = getIn(structureObj, key);
    if (typeof value === 'object') {
      objectKeys.push(key);
    } else {
      boolKeys.push(key);
    }
  });

  const handlePermittedRolesChange = (value: any) => {
    setFieldValue('permittedRoles', value);
  };

  const removePermittedRole = (index: number) => {
    if (values.permittedRoles && values.permittedRoles?.length > 0) {
      const updatedPermittedRoles = [...values.permittedRoles];
      updatedPermittedRoles.splice(index, 1);
      setFieldValue('permittedRoles', updatedPermittedRoles);
    }
  };

  return (
    <>
      <HasPermission permissionObj={ userPermission } permissionKey={ groupKey } nested>
        <div className="form__row">
          <h4 className="form__subheading">
            <Translate id={ groupPathArr[groupPathArr.length - 1] }/>
          </h4>
        </div>
      </HasPermission>
      <Row>
        { boolKeys.map((key, index) => {
          const value = getIn(groupObj, key);
          const name = `${ groupKey }.${ key }`;
          return (
            <HasPermission
              key={ index }
              permissionObj={ userPermission }
              permissionKey={ name }
              skipCheck={ readOnly || groupKey.startsWith('deviceType') }
            >
              <Col lg={ 4 } key={ index } className="pt-2">
                <CustomToggle
                  value={ value }
                  onChange={ setFieldValue }
                  name={ name }
                  label={ key }
                  readOnly={ readOnly }
                />
              </Col>
            </HasPermission>
          );
        }) }
      </Row>
      { objectKeys.map((key, index) => {
        const name = `${ groupKey }.${ key }`;
        if (key !== 'period' && key !== 'frequency') {
          return (
            <HasPermission
              key={ index }
              permissionObj={ userPermission }
              permissionKey={ name }
              nested
              skipCheck={ readOnly || groupKey.startsWith('deviceType') }
            >
              <NestedRoleGroup
                key={ index }
                groupKey={ name }
                values={ values }
                readOnly={ readOnly }
                setFieldValue={ setFieldValue }
                groupToggleChange={ groupToggleChange }
              />
            </HasPermission>
          );
        }
        return (
          <MessageRoleGroup
            key={ index }
            groupKey={ name }
            values={ values }
            setFieldValue={ setFieldValue }
            labelKey={ key }
            readOnly={ readOnly }
          />
        );
      }) }
      <RenderOnCondition condition={ groupKey === 'users' && !readOnly }>
        <RenderOnCondition condition={ !readOnly }>
          <Row>
            <Col lg={ 4 } className="pt-2 align-self-center">
              <label className="form__label">
                <Translate id="permittedRoles"/>
              </label>
            </Col>
            <Col lg={ 4 } className="pt-2">
              <PermittedRoleSelect
                value={ values.permittedRoles }
                onChange={ handlePermittedRolesChange }
              />
            </Col>
          </Row>
        </RenderOnCondition>
        <Row>
          <RenderOnCondition condition={ readOnly }>
            <Col xs={ 4 } className="pt-2 align-self-center">
              <label className="form__label">
                <Translate id="permittedRoles"/>
              </label>
            </Col>
          </RenderOnCondition>
          <Col xs={ readOnly ? 8 : 12 } className="multiselect_pill_wrapper">
            { values.permittedRoles?.map((role, index) => (
              <span key={ index } className="multiselect_pill">
                {
                  // @ts-ignore
                  `${ role.name }`
                }
                <span className="multiselect_pill_icon" onClick={ () => removePermittedRole(index) }>
                  <FontAwesomeIcon icon={ faTimes }/>
                </span>
              </span>
            )) }
          </Col>
        </Row>
      </RenderOnCondition>
    </>
  );
};

export default RoleGroup;
