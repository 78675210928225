import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as Yup from 'yup';
import { Col, Row } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { DeviceGroup, DeviceGroupType } from '@wiot/shared-domain/models/device-group/device-group';
import { AggregatedPermission } from '@wiot/shared-domain/models/role/role';
import Desktop from '../../components/Responsive/Desktop';
import Mobile from '../../components/Responsive/Mobile';
import LoadingIcon from '../../components/shared/LoadingIcon';
import RenderOnCondition from '../../components/RenderOnCondition';
import { fetchDeviceGroupFromDB, fetchGroupTypesFromDB, } from '../../api/apiHelpers';
import ModalHeader from '../../components/Modal/ModalHeader';
import { CustomFieldInput } from '../../components/Table/CustomFieldInput';
import CustomFieldSelect from '../../components/Table/CustomFieldSelect';
import { ICustomFieldSelectOption } from '../../components/Table/custom-field-select';
import GroupSelect from '../../components/shared/GroupSelect';
import { AppState } from '../../state/reducers/rootReducer';
import { DeviceGroupExtended, SetFieldValue } from '../../state/types';
import { deviceGroupIcons } from '../../constants';
import QUESTION_MARK from '../../assets/question-mark.svg';
import HasPermission from '../../components/HasPermission';
import { getRandomModalOffset } from '../../utils/dialog';
import { ACLDeviceGroup, ACLPropertyView } from '@wiot/shared-domain/models/role/access-level';
import { FeedbackAttachment } from '../../components/Feedback/feedback';
import { filterOutSensitiveDataFromDeviceGroup } from '../../components/Feedback/filter-sensitive-data';
import { createDeviceGroupActionCreator } from '../../state/device-group/create-device-group/createDeviceGroupActionCreators';
import { updateDeviceGroupActionCreator } from '../../state/device-group/update-device-group/updateDeviceGroupActionCreators';
import {
  removeRequestToRefreshDgRelatedEntitiesAction
} from '../../state/device-group/refresh-request/removeRequestToRefreshDgRelatedEntitiesActions';
import { filterDeviceGroupTypes } from '../../utils/device-group-type-filter';
import ConfirmModal, { ConfirmationVariant } from '../../components/Modal/ConfirmModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { openPropertySettingsModal } from '../../state/property-view/property-settings-modal/propertySettingsModalActionCreators';

export interface DeviceGroupActionModalProps extends LocalizeContextProps {
  closeAddAndUpdateModal: (event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent) => void;
  title: string;
  showDeleteButton: boolean;
  addUnit: boolean;
  id?: string | undefined;
  createDeviceGroup: (deviceGroup: DeviceGroup) => void;
  updateDeviceGroup: (deviceGroup: DeviceGroup) => void;
  removeRequestToRefreshDgRelatedEntities: () => void;
  openPropertySettingsModal: (deviceGroup: DeviceGroup) => void;
  currentEntriesPerPage: number;
  currentPage: number;
  removeUnit?: (id: string) => void;
  selectedDeviceGroup?: string;
  refreshTreeData?: () => void;
  refreshGroups?: () => void;
  permission?: AggregatedPermission;
  disableGroupSelect?: boolean;
  isKeyManagerModeEnabled: boolean;
  refreshRequester?: DeviceGroup;
}

export interface DeviceGroupActionModalState {
  groupTypeOptions: ICustomFieldSelectOption[];
  deviceGroupData: DeviceGroup;
  showDeleteModal: boolean;
  randomModalOffset?: { marginTop: number; marginRight: number };
  isLoading: boolean;
}

class DeviceGroupActionModal extends React.Component<DeviceGroupActionModalProps,
  DeviceGroupActionModalState> {
  modalUid = uuidv4();

  constructor(props: DeviceGroupActionModalProps) {
    super(props);
    this.state = {
      groupTypeOptions: [],
      deviceGroupData: {
        name: '',
        type: undefined,
        parent: {
          id: this.props.selectedDeviceGroup || '',
        },
        notes: '',
        abbreviation: '',
        externalId: '',
        hasChildren: false,
      },
      showDeleteModal: false,
      randomModalOffset: { marginTop: 0, marginRight: 0 },
      isLoading: true,
    };
  }

  componentDidUpdate(prevProps: DeviceGroupActionModalProps) {
    if (this.props.refreshRequester !== prevProps.refreshRequester) {
      this.refreshDeviceGroupRelatedEntities();
    }
  }

  componentDidMount = async () => {
    this.setState({ randomModalOffset: getRandomModalOffset() });
    if (!this.props.addUnit && this.props.id) {
      const deviceGroup = await fetchDeviceGroupFromDB(this.props.id, false);
      this.setState({ deviceGroupData: deviceGroup, });
    }
    this.setState({ isLoading: false });
    this.fetchGroupTypes();
  };

  refreshDeviceGroupRelatedEntities = () => {
    const {
      refreshTreeData,
      closeAddAndUpdateModal,
      refreshGroups,
      removeRequestToRefreshDgRelatedEntities
    } = this.props;

    closeAddAndUpdateModal();
    refreshGroups && refreshGroups();
    refreshTreeData && refreshTreeData();
    removeRequestToRefreshDgRelatedEntities();
  };

  fetchGroupTypes = async () => {
    const deviceGroupTypes = await fetchGroupTypesFromDB();
    if (deviceGroupTypes) {
      const filteredDeviceGroupTypes = filterDeviceGroupTypes(deviceGroupTypes, this.props.isKeyManagerModeEnabled);

      const groupTypeOptions = filteredDeviceGroupTypes.map(dgt => ({ name: dgt.name, id: dgt.name }));
      this.setState({ groupTypeOptions });
    }
  };

  handleDeviceGroupChange = (value: DeviceGroupExtended | string, setFieldValue: SetFieldValue) => {
    setFieldValue('parent', value, false);
  };

  handleDeviceGroupTypeChange = (
    event: React.FormEvent<HTMLSelectElement>,
    setFieldValue: SetFieldValue,
  ) => {
    const { value } = event.currentTarget;
    setFieldValue('type', value, false);
  };

  toggleDeleteModal = () => {
    this.setState((prevState) => ({
      showDeleteModal: !prevState.showDeleteModal,
    }));
  };


  handleSubmit = async (deviceGroup: DeviceGroup) => {
    this.setState({ isLoading: true });
    const { addUnit, createDeviceGroup, updateDeviceGroup } = this.props;
    addUnit ? createDeviceGroup(deviceGroup) : updateDeviceGroup(deviceGroup);
  };

  getValidationSchema = () => {
    const { translate } = this.props;
    const commonSchema: { [key: string]: any } = {
      type: Yup.mixed().required(translate('required-field').toString()),
      parent: Yup.object({
        id: Yup.string().required(translate('required-field').toString()),
      }),
      name: Yup.string().trim().required(translate('required-field').toString()),
      abbreviation: Yup.string().trim().nullable(),
    };
    return commonSchema;
  };

  getAttachmentForFeedback = (): FeedbackAttachment | null => {
    if (!this.state.deviceGroupData) {
      return null;
    }
    return {
      deviceGroup: filterOutSensitiveDataFromDeviceGroup(this.state.deviceGroupData),
    };
  };

  onAverageConsumptionConfigurationButtonClick = () => {
    this.props.openPropertySettingsModal(this.state.deviceGroupData);
  };

  render() {
    const {
      title,
      id,
      closeAddAndUpdateModal,
      permission,
      disableGroupSelect,
      showDeleteButton,
      addUnit,
      removeUnit,
      isKeyManagerModeEnabled,
    } = this.props;
    const { deviceGroupData, isLoading } = this.state;
    return (
      <div
        tabIndex={ 0 } // make it focusable
        className="device-modal"
        style={ this.state.randomModalOffset }
        id={ `${ title }-${ this.modalUid }` }
      >
        <ModalHeader
          isDevice={ false }
          titleTranslationId={ title }
          titlePostfix={ deviceGroupData.name }
          targetId={ this.modalUid }
          handleClose={ closeAddAndUpdateModal }
          enableFeedbackSubmission={ true }
          getFeedbackAttachment={ this.getAttachmentForFeedback }
        />
        <div className="device-modal__body">
          <Formik
            initialValues={ deviceGroupData }
            validationSchema={ Yup.object().shape(this.getValidationSchema()) }
            enableReinitialize
            onSubmit={ (values: DeviceGroup) => {
              this.handleSubmit(values);
            } }
            render={ ({ values, errors, setFieldValue }: FormikProps<DeviceGroup>) =>
              isLoading ? (
                <div className="loading-container">
                  <LoadingIcon/>
                </div>
              ) : (
                <Form
                  className="form"
                  onClick={ (event: React.MouseEvent) => event.stopPropagation() }
                >
                  <div className="form__section">
                    <div className="form__row">
                      <GroupSelect
                        disabled={ disableGroupSelect }
                        translateString="choose-parent-group"
                        handleDeviceGroupChange={ this.handleDeviceGroupChange }
                        setFieldValue={ setFieldValue }
                        selectedOption={ (values.parent && values.parent.id) || '' }
                        rootIdOfRestrictedSubTree={ id }
                        targetId={ `group-select-${ title }-${ id }` }
                      />
                    </div>
                    <ErrorMessage component="span" className="input-error" name="parent.id"/>
                  </div>

                  <div className="form__section">
                    <Row>
                      <RenderOnCondition condition={ !isKeyManagerModeEnabled }>
                        <Col lg={ 4 } className="text-center">
                          <img
                            src={
                              values.type
                                ? deviceGroupIcons[values.type as DeviceGroupType]
                                : QUESTION_MARK
                            }
                            className="thumbnail"
                            alt="device group type"
                          />
                        </Col>
                      </RenderOnCondition>

                      <Col lg={ isKeyManagerModeEnabled ? 12 : 8 }>
                        <Row>
                          <Col>
                            <CustomFieldSelect
                              translationId="group-type"
                              fieldName="type"
                              options={ this.state.groupTypeOptions }
                              translateOptions
                              translateNewMapped
                              value={ values.type }
                              onChange={ (event: React.FormEvent<HTMLSelectElement>) =>
                                this.handleDeviceGroupTypeChange(event, setFieldValue)
                              }
                              error={ errors.type }
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={ 9 }>
                            <CustomFieldInput
                              translationId="group-name"
                              fieldName="name"
                              value={ values.name }
                              error={ errors.name }
                            />
                          </Col>
                          <Col lg={ 3 }>
                            <CustomFieldInput
                              translationId="abbreviation"
                              fieldName="abbreviation"
                              value={ values.abbreviation }
                              error={ errors.abbreviation }
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={ 9 }>
                            <CustomFieldInput translationId="external-id" fieldName="externalId" value={ values.externalId }/>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>

                  <div className="form__section mb-5">
                    <Row>
                      <Col xs={ 12 }>
                        <CustomFieldInput translationId="notes" fieldName="notes" value={ values.notes }/>
                      </Col>
                    </Row>
                  </div>

                  <RenderOnCondition condition={ !isKeyManagerModeEnabled }>
                    <HasPermission
                      permissionObj={ permission }
                      permissionKey={ ACLDeviceGroup.FLAG_AS_PROPERTY }
                    >
                      <div className="form__section mb-1">
                        <div className="standard-font-size">
                          <label>
                            <Translate id="flag-group-as-property"/>
                            <label className="custom-checkbox">
                              <Field
                                component="input"
                                name="propertySettings.isProperty"
                                type="checkbox"
                                checked={ !!values.propertySettings?.isProperty }
                              />
                              <span className="checkmark border-color-main"/>
                            </label>
                          </label>
                        </div>
                      </div>
                    </HasPermission>
                  </RenderOnCondition>

                  <RenderOnCondition condition={ !isKeyManagerModeEnabled }>
                    <HasPermission
                      permissionObj={ permission }
                      permissionKey={ ACLPropertyView.VIEW_PROPERTY_SETTINGS }
                    >
                      <div className="form__section">
                        <div className="standard-font-size mb-2">
                          <button
                            type="button"
                            className="form__button--cancel"
                            onClick={ this.onAverageConsumptionConfigurationButtonClick }
                          >
                            <Translate id="configure-average-consumption-for-property-view"/>
                            {' '}
                            <FontAwesomeIcon icon={faCog} />
                          </button>
                        </div>
                      </div>
                    </HasPermission>
                  </RenderOnCondition>

                  <div className="form__section last">
                    <div className="form__row space-between ai">
                      <div className="form__row--left">
                        <HasPermission
                          permissionObj={ permission }
                          permissionKey="deviceGroups.remove"
                        >
                          <>
                            { showDeleteButton && (
                              <button
                                type="button"
                                className="form__button--delete"
                                onClick={ this.toggleDeleteModal }
                              >
                                <Translate id="remove"/>
                              </button>
                            ) }
                          </>
                        </HasPermission>
                      </div>

                      <div className="form__row--right">
                        <button
                          type="button"
                          className="form__button--cancel"
                          onClick={ closeAddAndUpdateModal }
                        >
                          <Translate id="cancel"/>
                        </button>
                        <button
                          className="form__button--blue background-color-main text-color-white border-color-main"
                          type="submit"
                        >
                          <Desktop>
                            <Translate id={ addUnit ? 'add-group' : 'save-group' }/>
                          </Desktop>
                          <Mobile>
                            <Translate id={ addUnit ? 'add-group' : 'save' }/>
                          </Mobile>
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )
            }
          />
          <RenderOnCondition condition={ this.state.showDeleteModal }>
            <>
              { id && (
                <ConfirmModal
                  modalCloseRequested={ this.toggleDeleteModal }
                  actionConfirmed={ async () => {
                    removeUnit && (await removeUnit(id));
                  } }
                  translationIdOfElementType="device-group"
                  translationIdOfAdditionalInfo={ deviceGroupData.hasChildren ? 'confirm-to-delete-group' : '' }
                  confirmationVariant={ ConfirmationVariant.DELETE }
                />
              ) }
            </>
          </RenderOnCondition>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  currentEntriesPerPage: state.currentEntriesPerPage,
  currentPage: state.pagination['device-groups'].currentPage,
  permission: state.currentUser.permission,
  isKeyManagerModeEnabled: !!state.siteSettings.isKeyManagerModeEnabled,
  refreshRequester: state.requestToRefreshDeviceGroupRelatedEntities.requesterDeviceGroup,
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  openPropertySettingsModal: (deviceGroup: DeviceGroup) => dispatch(openPropertySettingsModal(deviceGroup)),
  createDeviceGroup: (deviceGroup: DeviceGroup) => dispatch(createDeviceGroupActionCreator(deviceGroup)),
  updateDeviceGroup: (deviceGroup: DeviceGroup) => dispatch(updateDeviceGroupActionCreator(deviceGroup)),
  removeRequestToRefreshDgRelatedEntities: () => dispatch(removeRequestToRefreshDgRelatedEntitiesAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(DeviceGroupActionModal));
