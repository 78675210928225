import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { PROPERTY_VIEW_PAGE_KEY } from '@wiot/shared-domain/domain/property/property-view-page-key';
import { PropertyViewGeneralInfo } from './PropertyViewGeneralInfo';
import RenderOnCondition from '../../components/RenderOnCondition';
import { fetchProperty } from '../../state/property-view/fetchPropertyActionCreator';
import { AppState } from '../../state/reducers/rootReducer';
import FilterBar from '../../components/Filter/FilterBar';
import { PropertyViewConsumptionTable } from './Consumption/Table/PropertyViewConsumptionTable';
import ErrorBoundary from '../../components/ErrorBoundary';
import ResponsiveContainer from '../../components/shared/ResponsiveContainer';
import { ConsumptionPieChart } from './Consumption/ConsumptionPieChart';
import PropertyConsumptionHistory from './Consumption/HistoryChart/PropertyConsumptionHistory';
import { PropertyViewChildGroups } from './PropertyViewChildGroups';
import NoResultsInfo from '../../components/Table/NoResultsInfo';
import { PROPERTY_VIEW_CONSUMPTION_CONSUMPTION_ID, PROPERTY_VIEW_CONSUMPTION_PIE_CHART_ID } from './Consumption/property-view-constants';
import { scrollToElement } from '../../utils/scroll';
import { selectPageFilters } from '../../state/filter/filterSelectors';
import { selectIsPropertySettingsModalVisible, selectProperty } from '../../state/property-view/propertySelectors';
import { generatePropertyViewPath } from './PropertyViewPathUtils';
import LoadingIcon from '../../components/shared/LoadingIcon';
import { userLeftPropertyView } from '../../state/property-view/userLeftPropertyViewActionCreator';
import PropertyViewSettingsModal from './Consumption/Table/PropertyViewSettingsModal';

export const PropertyView = function () {
  const filter = useSelector((state: AppState) => selectPageFilters(state, PROPERTY_VIEW_PAGE_KEY));
  const {
    consumptionType,
    billingPeriodStartDate,
    billingFrequency,
    propertyMBusDeviceTypeIdForPieChart,
    propertyMBusDeviceTypeIdForConsumption,
  } = filter;

  const dispatch = useDispatch();
  const history = useHistory();

  const filterDeviceGroupId = filter.deviceGroup?.[0]?.[0]?.id;

  const property = useSelector((state: AppState) => selectProperty(state, filterDeviceGroupId));
  const isPropertySettingsModalVisible = useSelector((state: AppState) => selectIsPropertySettingsModalVisible(state));
  const isPropertyLoading = useSelector((state: AppState) => state.properties.isPropertyLoading);
  const isMobileDisplay = useSelector((state: AppState) => state.isMobileDisplay);

  const onUnload = () => {
    dispatch(userLeftPropertyView());
  };

  useEffect(() => {
    const initialViewPath = generatePropertyViewPath({
      consumptionType,
      deviceGroupId: property?.id,
      billingPeriodStartDate,
      billingFrequency,
    });
    history.push(initialViewPath);
    return onUnload;
  }, []);

  useEffect(() => {
    if (filterDeviceGroupId) {
      dispatch(fetchProperty(
        filterDeviceGroupId,
        billingPeriodStartDate,
        billingFrequency,
        consumptionType,
        true,
      ));
    }
  }, [dispatch, filterDeviceGroupId, billingFrequency, billingPeriodStartDate, consumptionType]);

  const onPieChartButtonClick = () => {
    if (isMobileDisplay) {
      scrollToElement(PROPERTY_VIEW_CONSUMPTION_PIE_CHART_ID);
    }
  };

  const onConsumptionButtonClick = () => {
    if (isMobileDisplay) {
      scrollToElement(PROPERTY_VIEW_CONSUMPTION_CONSUMPTION_ID);
    }
  };

  return (
    <main className="property-view">
      <ErrorBoundary>
        <FilterBar page={ PROPERTY_VIEW_PAGE_KEY }/>
        <RenderOnCondition condition={ isPropertyLoading }>
          <LoadingIcon/>
        </RenderOnCondition>
        <RenderOnCondition condition={ !isPropertyLoading }>
          <div className="d-flex flex-wrap">
            <RenderOnCondition condition={ !property }>
              <NoResultsInfo positionAbsolute/>
            </RenderOnCondition>
            <RenderOnCondition condition={ property }>
              <PropertyViewGeneralInfo deviceGroup={ property! }/>
              <PropertyViewConsumptionTable
                pageKey={ PROPERTY_VIEW_PAGE_KEY }
                pieChartButtonClick={ onPieChartButtonClick }
                consumptionButtonClick={ onConsumptionButtonClick }
              />
              <RenderOnCondition condition={ property && property.children?.length }>
                <PropertyViewChildGroups property={ property! }/>
              </RenderOnCondition>
            </RenderOnCondition>
            <RenderOnCondition condition={ propertyMBusDeviceTypeIdForPieChart }>
              <ConsumptionPieChart/>
            </RenderOnCondition>
            <RenderOnCondition condition={ property && propertyMBusDeviceTypeIdForConsumption }>
              <ResponsiveContainer
                title="consumption-history-in-comparison"
                maxWidths={ {
                  sm: 12,
                  md: 12,
                  lg: 12,
                  xl: 12,
                } } // TODO(TL): Upgrade to bootstrap v5 for `xxl` breakpoint
                idOfElement={ PROPERTY_VIEW_CONSUMPTION_CONSUMPTION_ID }
              >
                <PropertyConsumptionHistory responsiveContainerHeight={ isMobileDisplay ? 450 : 230 }/>
              </ResponsiveContainer>
            </RenderOnCondition>
          </div>

          <RenderOnCondition condition={ isPropertySettingsModalVisible }>
            <PropertyViewSettingsModal/>
          </RenderOnCondition>
        </RenderOnCondition>
      </ErrorBoundary>
    </main>
  );
};
