import * as React from 'react';
import { connect } from 'react-redux';
import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';
import { IColumnObject } from '@wiot/shared-domain/models/device/device';
import LoadingIcon from '../../../components/shared/LoadingIcon';
import TableDataHeader from '../../../components/Table/TableDataHeader';
import NoResultsInfo from '../../../components/Table/NoResultsInfo';
import RenderOnCondition from '../../../components/RenderOnCondition';
import { AppState } from '../../../state/reducers/rootReducer';
import TableDataRow from './TableDataRow';
import { DEVICE_GROUPS_TABLE_HEADERS as TABLE_HEADERS } from '../../../constants';

export interface TableDataProps {
  deviceGroups: DeviceGroup[];
  markOneDeviceGroupAsSelected: (id: string) => void;
  markAllDeviceGroupsAsSelected: (
    event: React.FormEvent<HTMLInputElement>
  ) => void;
  isLoading: boolean;
  refreshData: (column?: IColumnObject) => void;
  removeUnit: (id: string) => Promise<void>;
  isSelectAllChecked: boolean;
  isKeyManagerModeEnabled: boolean;
}

export interface TableDataState {
  columns: IColumnObject[];
}

class TableData extends React.Component<TableDataProps, TableDataState> {
  constructor(props: TableDataProps) {
    super(props);
    this.state = {
      columns: [],
    };
  }

  componentDidMount() {
    const { refreshData } = this.props;
    refreshData();
    this.setState({ columns: this.getTableHeaders() });
  }

  getTableHeaders = () => {
    const { isKeyManagerModeEnabled } = this.props;
    let headerNames = TABLE_HEADERS;

    if (isKeyManagerModeEnabled) {
      headerNames = TABLE_HEADERS.filter(
        (headerName) => headerName !== 'abbreviation'
      );
    }

    return headerNames.map((headerName) => ({
      name: headerName,
      visible: true,
      sortable: true,
    }));
  };

  render() {
    if (this.props.isLoading) {
      return <LoadingIcon/>
    }

    const showResults = (this.props.deviceGroups && this.props.deviceGroups.length);

    return (
      <div className="device-manager__table__table__container">
        <table className="device-manager__table__table">
          <TableDataHeader
            withCheckbox
            tableHeaders={this.state.columns}
            markAllRowsAsSelected={this.props.markAllDeviceGroupsAsSelected}
            isSelectAllChecked={this.props.isSelectAllChecked}
          />
          <tbody>
            <RenderOnCondition condition={showResults}>
              {
                this.props.deviceGroups.map((deviceGroup: DeviceGroup) => (
                  <TableDataRow
                    deviceGroup={deviceGroup}
                    key={deviceGroup.id}
                    removeUnit={this.props.removeUnit}
                    selectOne={this.props.markOneDeviceGroupAsSelected}
                    refreshGroups={this.props.refreshData}
                  />
                  ))}
            </RenderOnCondition>
          </tbody>
        </table>
        <RenderOnCondition condition={!showResults}>
          <NoResultsInfo />
        </RenderOnCondition>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  deviceGroups: state.deviceGroups,
  isLoading: state.isLoading,
  isKeyManagerModeEnabled: !!state.siteSettings.isKeyManagerModeEnabled,
});

export default connect(mapStateToProps)(TableData);
