import React from 'react';
import { useSelector } from 'react-redux';
import { Translate } from 'react-localize-redux';

import { Device, IColumnObject } from '@wiot/shared-domain/models/device/device';

import DataRow from './DataRow';
import { FetchOptions } from '../../../state/types';
import { IDeviceManagerFilter, PageKeys } from '../../../state/reducers/filterSortReducer';
import { AppState } from '../../../state/reducers/rootReducer';
import MobileFooter from '../../../components/Mobile/MobileFooter';
import LoadingIcon from '../../../components/shared/LoadingIcon';
import RenderOnCondition from '../../../components/RenderOnCondition';

interface MobileTableProps {
  devices: Device[];
  removeUnit: (id: string) => Promise<void>;
  currentPageTitle: PageKeys;
  totalDocs: number;
  currentEntriesPerPage: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  refreshData: (column?: IColumnObject, pageOptions?: FetchOptions<IDeviceManagerFilter>) => void;
  isLoading: boolean;
}

const MobileTable = (props: MobileTableProps) => {
  const showNoResultInfo = (props.devices.length === 0)
  const showNoResultsInfoState = useSelector((state:AppState) => state.showNoResultsInfo);

  const firstEntry = (props.currentPage - 1) * props.currentEntriesPerPage + 1;
  const lastEntry = Math.min(firstEntry + props.currentEntriesPerPage - 1, props.totalDocs);

  if (props.isLoading) {
    return <LoadingIcon/>
  }
  return (
    <div className="device-manager__table__container">
      <div className="page-header-wrapper">
        <h3 className="page-title" data-testid="header-page-title">
          <Translate id={props.currentPageTitle} />
        </h3>
        <h4 className="page-subtitle" data-testid="table-subheading">
          {props.totalDocs ? (
            <Translate
              id="device-messages-table-header-subheading"
              data={{
                firstEntry,
                lastEntry,
                allDevices: props.totalDocs,
              }}
            />
          ) : null}
        </h4>
      </div>
      <RenderOnCondition condition={showNoResultInfo}>
        <div className="text-center m-2">
          <Translate id={ showNoResultsInfoState ? "too-much-data" : "no-data"} />
        </div>
      </RenderOnCondition>
      <RenderOnCondition condition={!showNoResultInfo}>
        {props.devices.map((device: Device) => (
          <DataRow
            key={device.id}
            device={device}
            removeUnit={props.removeUnit}
            refreshData={props.refreshData}
          />
        ))}
      </RenderOnCondition>
      <MobileFooter
        currentPage={props.currentPage}
        setCurrentPage={props.setCurrentPage}
        totalDocs={props.totalDocs}
      />
    </div>
  );
};

export default MobileTable;
