import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Translate } from 'react-localize-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { GetHexValueValidationSchema } from '../shared/YupValidators';
import { CustomFieldAdvancedInput } from '../Table/CustomFieldAdvancedInput';
import { generateHexNumber } from '../../utils/hexadecimal';
import SecondaryButton from '../shared/SecondaryButton';
import PrimaryButton from '../shared/PrimaryButton';

export interface ChooseXmlFormatModalProps {
  show: boolean;
  cancel: () => void;
  clickDownload: (encryptionKey: string) => void;
}

const DownloadOmsXmlModal = (props: ChooseXmlFormatModalProps) => {
  const { show, cancel, clickDownload } = props;

  return (
    <Modal
      show={show}
      onHide={cancel}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Formik
        onSubmit={({ encryptionKey }) => {
          clickDownload(encryptionKey);
        }}
        validationSchema={Yup.object().shape({
          encryptionKey: GetHexValueValidationSchema(32),
        })}
        initialValues={{
          encryptionKey: '',
        }}
      >
        {({ handleSubmit, values, errors, setFieldValue }) => (
          <>
            <Modal.Header closeButton>
              <Modal.Title>
                <h5>
                  <Translate id="enter-encryption-key" />
                </h5>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="standard-font-size">
                <Form onSubmit={handleSubmit} id="formSelectEncryption">
                  <label>
                    <Translate id="encryption-key-info" />
                  </label>
                  <div className="mt-2">
                    <CustomFieldAdvancedInput
                      type="password"
                      fieldName="encryptionKey"
                      showEyeButton
                      showCopyButton
                      showAesKeyHintButton
                      showGenerateRandomValueButton
                      generateRandomValue={() => setFieldValue('encryptionKey', generateHexNumber(32))}
                      autoComplete="new-password"
                      value={values.encryptionKey}
                      error={errors.encryptionKey}
                      placeholder="EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE"
                      className="wd-100"
                      maxLength={32}
                    />
                    <br />
                  </div>
                </Form>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <SecondaryButton onClick={() => cancel()} textTranslationId="cancel" />
              <PrimaryButton
                formNameToSubmitOnClick="formSelectEncryption"
                textTranslationId="download"
              />
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default DownloadOmsXmlModal;
