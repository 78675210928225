export enum AccessLevel {
  ADD,
  VIEW,
  EDIT,
  REMOVE,
}

export enum ACLDataIntegration {
  VIEW = 'dataIntegration.view',
  ADD = 'dataIntegration.add',
  EDIT = 'dataIntegration.edit',
  REMOVE = 'dataIntegration.remove',
  ALLOWED_FORMAT_CSV = 'dataIntegration.allowedFormat.csv',
  ALLOWED_FORMAT_XML = 'dataIntegration.allowedFormat.xml',
  ALLOWED_FORMAT_JSON = 'dataIntegration.allowedFormat.json',
  ALLOWED_FORMAT_API = 'dataIntegration.allowedFormat.api',
  ALLOWED_FORMAT_FTP = 'dataIntegration.allowedFormat.ftp',
  ALLOWED_FORMAT_SWAN2_LOG = 'dataIntegration.allowedFormat.swan2.log',
}

export enum ACLMessage {
  VIEW = 'view',
  ADD = 'add',
  REMOVE = 'remove',
}

export enum ACLDeviceEdit {
  BASIC = 'edit.basic',
  METADATA = 'edit.metadata',
  RADIO_INFO = 'edit.radioInfo',
  DESCRIPTION = 'edit.description',
  IMAGES = 'edit.images',
}

export enum ACLDeviceView {
  BASIC = 'view.basic',
  METADATA = 'view.metadata',
  RADIO_INFO = 'view.radioInfo',
  DESCRIPTION = 'view.description',
  IMAGES = 'view.images',
  CONSUMPTION = 'view.consumption',
  GATEWAYS = 'view.gateways',
  USERS_WITH_ACCESS = 'view.usersWithAccess',
}

export enum ACLManageDeviceTypes {
  VIEW = 'manageDeviceType.view',
  ADD = 'manageDeviceType.add',
  EDIT = 'manageDeviceType.edit',
  REMOVE = 'manageDeviceType.remove',
}

export enum ACLManageDeviceGroupTypes {
  VIEW = 'manageDeviceGroupTypes.view',
  ADD = 'manageDeviceGroupType.add',
  EDIT = 'manageDeviceGroupType.edit',
  REMOVE = 'manageDeviceGroupType.remove',
}

export enum ACLManageDeviceTypeProfiles {
  VIEW = 'manageDeviceTypeProfiles.view',
  ADD = 'manageDeviceTypeProfiles.add',
  EDIT = 'manageDeviceTypeProfiles.edit',
  REMOVE = 'manageDeviceTypeProfiles.remove',
}

export enum ACLDeviceGroup {
  VIEW = 'deviceGroups.view',
  ADD = 'deviceGroups.add',
  EDIT = 'deviceGroups.edit',
  REMOVE = 'deviceGroups.remove',
  FLAG_AS_PROPERTY = 'deviceGroups.flagAsProperty',
}

export enum ACLDeviceManager {
  VIEW_TOPOLOGY = 'deviceManager.viewTopology',
  VIEW_BLACKLISTED = 'deviceManager.viewBlacklisted',
  VIEW_UNASSIGNED = 'deviceManager.viewUnassigned',
  VIEW_MAP = 'deviceManager.viewMap',
}

export enum ACLKeys {
  VIEW = 'keys.view',
  ADD = 'keys.add',
  EDIT = 'keys.edit',
  REMOVE = 'keys.remove',
}

export enum ACLRoles {
  VIEW = 'roles.view',
  ADD = 'roles.add',
  EDIT = 'roles.edit',
  REMOVE = 'roles.remove',
  ADD_ROOT = 'roles.addRootAdmin',
}

export enum ACLSettings {
  VIEW = 'settings.view',
  EDIT = 'settings.edit',
}

export enum ACLUsers {
  VIEW = 'users.view',
  ADD = 'users.add',
  EDIT = 'users.edit',
  REMOVE = 'users.remove',
}

export enum ACLProfile {
  CHANGE_PASSWORD = 'profile.changePassword',
  CHANGE_ADDRESS = 'profile.changeAddress',
  CHANGE_USER_SETTINGS = 'profile.changeUserSettings',
}

export enum ACLDeviceKeyDownload {
  VIEW = 'deviceKeyDownload.view',
}

export enum ACLPropertyView {
  VIEW = 'propertyView.view',
  VIEW_PROPERTY_SETTINGS = 'propertyView.viewPropertySettings',
  EDIT_PROPERTY_SETTINGS = 'propertyView.editPropertySettings',
}

export type Permissions =
  ACLDataIntegration
  | ACLManageDeviceTypes
  | ACLManageDeviceGroupTypes
  | ACLManageDeviceTypeProfiles
  | ACLDeviceGroup
  | ACLDeviceManager
  | ACLKeys
  | ACLRoles
  | ACLSettings
  | ACLUsers
  | ACLProfile
  | ACLDeviceKeyDownload
  | ACLDeviceView
  | ACLDeviceEdit
  | ACLMessage
  | ACLPropertyView
  | AccessLevel;
