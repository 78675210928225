import * as Yup from 'yup';
import { TranslateFunction } from 'react-localize-redux';
import { DeviceMetadata, ProtocolType } from '@wiot/shared-domain/models/device/device';
import { getProtocolType } from './metaDataUtilityFunctions';
import { GetHexValueValidationSchema } from '../../../components/shared/YupValidators';

export const getValidationSchema = (translate: TranslateFunction) => {
  const defaultValidationSchema = {
    id: Yup.boolean(),
    deviceGroupId: Yup.string().required(translate('required-field').toString()),
    name: Yup.string().trim(),
    deviceTypeId: Yup.string().required(translate('required-field').toString()),
    manufacturerFlagId: Yup.string().nullable().required(translate('required-field').toString()),
  };

  const loraValidationSchema = {
    radioKey: Yup.object()
      .nullable()
      .when('deviceMetadata', {
        is: (deviceMetadata: DeviceMetadata) =>
          getProtocolType(deviceMetadata) === ProtocolType.LORA_ABP_DEVICE ||
          getProtocolType(deviceMetadata) === ProtocolType.LORA_OTAA_DEVICE,
        then: Yup.object().required(translate('required-field').toString()),
      }),
    communicationOptions: Yup.object()
      .when('deviceMetadata', {
        is: (deviceMetadata: DeviceMetadata) =>
          getProtocolType(deviceMetadata) === ProtocolType.LORA_ABP_DEVICE,
        then: Yup.object({
          secondaryRadioKey: Yup.object()
            .nullable()
            .required(translate('required-field').toString()),
        }),
      })
      .when('deviceMetadata', {
        is: (deviceMetadata: DeviceMetadata) =>
          getProtocolType(deviceMetadata) === ProtocolType.LORA_OTAA_DEVICE,
        then: Yup.object({
          lora: Yup.object({
            joinEui: GetHexValueValidationSchema(16),
          }),
        }),
      }),
  };

  const deviceIdSchema = {
    deviceId: Yup.string()
      .trim()
      // defaultValidationSchema
      .required(translate('required-field').toString())
      .min(4, translate('property-must-have-min-digits-or-more', { count: 4 }).toString())
      // loraValidationSchema
      .when('deviceMetadata', {
        is: (deviceMetadata: DeviceMetadata) =>
          getProtocolType(deviceMetadata) === ProtocolType.LORA_OTAA_DEVICE,
        then: GetHexValueValidationSchema(16),
      })
      .when('deviceMetadata', {
        is: (deviceMetadata: DeviceMetadata) =>
          getProtocolType(deviceMetadata) === ProtocolType.LORA_ABP_DEVICE,
        then: GetHexValueValidationSchema(8),
      }),
  }

  return { ...defaultValidationSchema, ...loraValidationSchema, ...deviceIdSchema };
};
