import { DeviceMetadataField } from '@wiot/shared-domain/models/device/device';
import { CHANGE_FILTER, CHANGE_SORT, RESET_FILTER } from '../constants/actionTypes';
import { DeviceGroupExtended } from '../types';
import { PropertyMeasurementFrequency } from '@wiot/shared-domain/domain/property/property';
import { MeasurementFrequency } from '@wiot/shared-domain/models/device-reading/measurement';
import moment from 'moment/moment';
import { IDuration } from '../../components/Filter/durations';
import { SelectableOption } from '../../components/FilterBar/select/selectable-option';
import { PropertyConsumptionType } from '@wiot/shared-domain/domain/property/property-consumption-type';

export interface IDeviceManagerFilter {
  _id?: string;
  name?: string;
  deviceGroup?: DeviceGroupExtended[][] | string[] | null;
  gateways?: SelectableOption[];
  manufacturers?: SelectableOption[];
  status?: SelectableOption[];
  blacklisted?: boolean;
  deviceTypes?: SelectableOption[];
  'deviceMetadata.deviceInfo_all_elemMatch'?: DeviceMetadataField[];
}

export interface IDeviceMessageFilter {
  deviceId?: string;
  idOfDevice?: string;
  deviceGroup?: DeviceGroupExtended[][] | string[];
  gateways?: SelectableOption[];
  deviceTypes?: SelectableOption[];
  receivedAt_lte?: number;
  receivedAt_gte?: number;
  period?: IDuration;
}

export interface IDeviceGroupFilter {
  name: string;
  type: SelectableOption[];
}

export interface IUserManagementFilter {
  name?: string;
  email?: string;
  validated?: SelectableOption;
}

export interface IRolesFilter {
  name: string;
}

export interface IKeysFilter {
  name?: string;
}

export interface IDataIntegrationFilter {
  name: string;
}

export interface ManageDeviceTypesFilter {
  name: string;
}

export interface ManageDeviceGroupTypesFilter {
  name: string;
}

export interface PropertyViewFilter {
  deviceGroup?: DeviceGroupExtended[][] | null;
  consumptionType: PropertyConsumptionType;
  billingPeriodStartDate: string;
  billingFrequency: PropertyMeasurementFrequency;
  propertyMBusDeviceTypeIdForPieChart: number;
  propertyMBusDeviceTypeIdForConsumption: number;
}

export interface IFilterState {
  'property-view': PropertyViewFilter;
  'device-manager': IDeviceManagerFilter;
  'device-messages': IDeviceMessageFilter;
  'device-groups': IDeviceGroupFilter;
  'user-management': IUserManagementFilter;
  roles: IRolesFilter;
  'key-management': IKeysFilter;
  'data-integration': IDataIntegrationFilter;
  backups: Record<string, unknown>;
  'manage-device-types': ManageDeviceTypesFilter;
  'manage-device-group-types': ManageDeviceGroupTypesFilter;
}

export type PageKeys =
  | 'undefined'
  | 'device-manager'
  | 'device-messages'
  | 'device-groups'
  | 'user-management'
  | 'roles'
  | 'key-management'
  | 'data-integration'
  | 'backups'
  | 'manage-device-types'
  | 'manage-device-group-types'
  | 'property-view';

export type FilterType =
  | IDeviceManagerFilter
  | IDeviceMessageFilter
  | IDeviceGroupFilter
  | IUserManagementFilter
  | IRolesFilter
  | IKeysFilter
  | IDataIntegrationFilter
  | ManageDeviceTypesFilter
  | ManageDeviceGroupTypesFilter
  | PropertyViewFilter;

export interface IFilterReducerState {
  filter: IFilterState;
  sort: string;
}

export interface IFilterPayload {
  page: PageKeys;
  values?: Record<string, unknown>;
}

function getInitialBillingPeriodStartDate(queryParam: string | null): string {
  if (!queryParam) {
    return moment().startOf('year').format('YYYY-MM-DD');
  }

  return moment(queryParam).format('YYYY-MM-DD');
}

function getInitialState(): IFilterReducerState {
  const queryParams = new URLSearchParams(window.location.search);
  const deviceGroupIdQueryParam = queryParams.get('deviceGroupId');
  const consumptionTypeQueryParam = queryParams.get('consumptionType') as PropertyConsumptionType;
  const billingFrequencyQueryParam = queryParams.get('billingFrequency');
  const billingPeriodStartDateQueryParam = queryParams.get('billingPeriodStartDate');

  const initialConsumptionType = consumptionTypeQueryParam ? PropertyConsumptionType[consumptionTypeQueryParam] : PropertyConsumptionType.ENERGY;
  const initialBillingFrequency = billingFrequencyQueryParam ? MeasurementFrequency[billingFrequencyQueryParam] :
    MeasurementFrequency.MONTH;
  const initialBillingPeriodStartDate = getInitialBillingPeriodStartDate(billingPeriodStartDateQueryParam);

  return {
    filter: {
      'device-manager': {
        deviceGroup: [],
        deviceTypes: [],
      },
      'device-messages': {
        period: 'last-7-days',
        idOfDevice: '',
      },
      'device-groups': {
        name: '',
        type: [],
      },
      'user-management': {
        validated: undefined,
      },
      roles: {
        name: '',
      },
      'key-management': {
        name: '',
      },
      'data-integration': {
        name: '',
      },
      backups: {},
      'manage-device-types': {
        name: '',
      },
      'manage-device-group-types': {
        name: '',
      },
      'property-view': {
        deviceGroup: deviceGroupIdQueryParam ? [[{ id: deviceGroupIdQueryParam }]] : [],
        consumptionType: initialConsumptionType,
        billingFrequency: initialBillingFrequency,
        billingPeriodStartDate: initialBillingPeriodStartDate,
        propertyMBusDeviceTypeIdForConsumption: 0,
        propertyMBusDeviceTypeIdForPieChart: 0,
      },
    },
    sort: '',
  };
}

export const filterSortReducer = (
  state = getInitialState(),
  action: { type: string; payload: IFilterPayload },
): IFilterReducerState => {
  const { payload } = action;
  switch (action.type) {
    case CHANGE_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          [payload.page]: {
            ...state.filter[payload.page],
            ...payload.values,
          },
        },
      };
    case RESET_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          [payload.page]: getInitialState().filter[payload.page],
        },
      };
    case CHANGE_SORT:
      return {
        ...state,
        // @ts-ignore
        sort: action.payload,
      };
    default:
      return state;
  }
};
