import React, { useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { Col, Image, Nav, Row, Tab } from 'react-bootstrap';
import { faCaretLeft, faCaretRight, faPen } from '@fortawesome/free-solid-svg-icons';
import { connect, useSelector } from 'react-redux';
import { DeviceReading, } from '@wiot/shared-domain/models/device-reading/device-reading';
import { Device, DeviceMetadataFields } from '@wiot/shared-domain/models/device/device';
import { IDeviceRole, IMessageRole } from '@wiot/shared-domain/models/role/role';
import { MBusDeviceType } from '@wiot/shared-domain/models/device-types/m-bus-device-types';
import { AppState } from '../../../state/reducers/rootReducer';
import Portal from '../../../components/shared/Portal';
import CopyButton from '../../../components/shared/CopyButton';
import { sourceTypeIconSmall } from '../../../constants';
import QUESTION_MARK from '../../../assets/question-mark.svg';
import {
  getDeviceTypeIconFullPath,
  getSourceTypeName,
  getTranslationValueInCurrentLanguage,
  localizeDate,
  localizeTime,
} from '../../../utils/common';
import CustomImageFileInput from '../../../components/Table/CustomImageFileInput';
import Thumbnails from '../../../components/Thumbnails/Thumbnails';
import { DeviceExtended } from '../../../state/types';
import DeviceMetaData from './DeviceMetaData';
import DetailCol from '../DetailCol';
import { fetchDeviceFromDB } from '../../../api/apiHelpers';
import DeviceActionModal from '../DeviceActionModal/DeviceActionModal';
import HasPermission from '../../../components/HasPermission';
import RenderOnCondition from '../../../components/RenderOnCondition';
import DeviceStatusPil from '../DeviceStatusPil';
import ConsumptionTab from './Consumption/ConsumptionTab';
import GatewaysTab from './GatewaysTab';
import { getTranslationIdFromProtocolType } from '../../../config/device-modal-translation-ids';
import { LinkToDeviceMessagesPage } from '../../../navigation/LinkToDeviceMessagesPage';
import { ACLDeviceView, ACLMessage } from '@wiot/shared-domain/models/role/access-level';
import { DeviceFields } from '@wiot/shared-domain/models/device/device-fields';
import { UsersWithAccess } from './UsersWithAccess';
import { getSelectedMetaDataValue, isConsumptionTabVisible } from '../DeviceActionModal/metaDataUtilityFunctions';
import { ModalTabEventKeys } from '../ModalTabEventKeys';
import { FormattedDeviceReadingValue } from '../../../components/shared/FormattedDeviceReadingValue';
import { Tooltip } from '../../../components/shared/Tooltip';

export interface DeviceDetailsBodyProps {
  ancestorPath?: string;
  isChangeModal?: boolean;
  device: DeviceExtended;
  getDeviceAncestorPath: () => string;
  getDeviceInfo: (deviceId: string, setActiveTabKey?: React.Dispatch<React.SetStateAction<ModalTabEventKeys>>) => void;
  onDropFiles: (files: (string | File)[]) => void;
  onImagesRemoved: (files: (string | File)[]) => void;
  images: [];
  toggleGallery: () => void;
  toggleOcrLoading: () => void;
  toggleOcrWizard: () => void;
  isOcrLoading: boolean;
  setCurrentFile: (file: string) => void;
  setOcrFoundData: (foundData: string[]) => void;
  toggleChangeOptions: () => void;
  uploadImages: () => void;
  removeUnit?: (id: string) => void;
  refreshTreeData?: () => void;
  refreshDevices?: () => void;
  deviceRole?: IDeviceRole;
  messageRole?: IMessageRole;
  showFullTimestamp: boolean | undefined;
}

interface ChangedDevice {
  name: string;
  deviceId: string;
}

const DeviceDetailsBody = (props: DeviceDetailsBodyProps) => {
  const {
    device,
    isChangeModal,
    images,
    deviceRole,
    messageRole,
    showFullTimestamp,
    removeUnit,
    refreshDevices,
    refreshTreeData,
    getDeviceInfo,
    getDeviceAncestorPath,
    ancestorPath,
    onDropFiles,
    onImagesRemoved,
    setCurrentFile,
    setOcrFoundData,
    isOcrLoading,
    toggleChangeOptions,
    toggleOcrLoading,
    toggleGallery,
    toggleOcrWizard,
    uploadImages,
  } = props;

  const {
    id,
    name,
    deviceId,
    deviceType,
    gateways,
    manufacturer,
    status,
    deviceMetadata,
    radioKey,
    usersWithAccess,
    isDeprecated,
    // @ts-ignore
    deviceMessages,
    lastMessage,
    oldDevice,
    newDevice,
    notes,
    version,
    communicationOptions,
  } = device;

  const [newDeviceName, setNewDeviceName] = useState({
    name: '',
    deviceId: '',
  });
  const [oldDeviceName, setOldDeviceName] = useState({
    name: '',
    deviceId: '',
  });
  const [activeTabKey, setActiveTabKey] = useState(ModalTabEventKeys.BASIC);
  const [showDeviceActionModal, setShowDeviceActionModal] = useState(false);
  const [showLeftOverflowIcon, setShowLeftOverflowIcon] = useState(false);
  const [showRightOverflowIcon, setShowRightOverflowIcon] = useState(false);
  const [gatewayDevices, setGatewayDevices] = useState<Device[]>([]);
  const isKeyManagerModeEnabled = useSelector((state: AppState) => !!state.siteSettings.isKeyManagerModeEnabled);

  useEffect(() => {
    let ignore = false;
    if (gateways) {
      const getGateways = async () => {
        await Promise.all(
          gateways.map(async (gateway: Device | string) => {
            const gatewayId = typeof gateway === 'string' ? gateway : gateway?.id;
            const gatewayDetails = await fetchDeviceFromDB(gatewayId, false);
            if (!ignore) setGatewayDevices((result) => [...result, gatewayDetails]);
          }),
        );
      };
      getGateways();
    }
    return () => {
      setGatewayDevices([]);
      ignore = true;
    };
  }, [gateways, id]);

  const toggleDeviceActionModal = (
    event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent,
  ) => {
    if (event) {
      event.stopPropagation();
    }
    setShowDeviceActionModal(!showDeviceActionModal);
  };

  const getReadingDate = (reading: DeviceReading) => {
    if (reading && reading.receivedAt) {
      if (showFullTimestamp) {
        return localizeDate(reading.receivedAt);
      }

      return (
        <>
          { moment(new Date(reading.receivedAt)).fromNow() }, { <Translate id="at-time"/> }{ ' ' }
          { localizeTime(reading.receivedAt, {
            hour: 'numeric',
            minute: 'numeric',
          }) }{ ' ' }
          <Translate id="o-clock"/>
        </>
      );
    }

    return '';
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const targetEl = e.currentTarget;
    setShowRightOverflowIcon(
      targetEl.scrollWidth > targetEl.clientWidth &&
      targetEl.scrollLeft < targetEl.scrollWidth - targetEl.clientWidth,
    );
    setShowLeftOverflowIcon(targetEl.scrollWidth > targetEl.clientWidth && targetEl.scrollLeft > 0);
  };

  useEffect(() => {
    const getDeviceName = async (
      itemId: string,
      setMethod: React.Dispatch<React.SetStateAction<ChangedDevice>>,
    ) => {
      try {
        const fetchedDevice = await fetchDeviceFromDB(itemId, false);
        setMethod({ name: fetchedDevice.name, deviceId: fetchedDevice.deviceId });
      } catch (e) {
        setMethod({ name: '', deviceId: '' });
      }
    };
    setNewDeviceName({ name: '', deviceId: '' });
    setOldDeviceName({ name: '', deviceId: '' });
    newDevice && getDeviceName(newDevice, setNewDeviceName);
    oldDevice && getDeviceName(oldDevice, setOldDeviceName);
  }, [newDevice, oldDevice]);

  const deviceReadings: DeviceReading[] = deviceMessages;

  const manufacturerName = manufacturer ? manufacturer.name : '-';

  const shouldRenderConsumptionTab = isConsumptionTabVisible(isKeyManagerModeEnabled, false, deviceType, !!device.deviceGroup);
  const isSecondaryRadioKeyVisible = !isKeyManagerModeEnabled && communicationOptions?.secondaryRadioKey;

  return (
    <div className="details__section" id="device-details-body">
      { isDeprecated && (
        <Row>
          <Col className="details__warning">
            { newDevice ? (
              <Translate id="device-was-replaced"/>
            ) : (
              <Translate id="device-marked-deprecated"/>
            ) }
          </Col>
        </Row>
      ) }
      <HasPermission permissionObj={ deviceRole } permissionKey="edit" nested>
        <>
          { !isChangeModal && removeUnit && (refreshDevices || refreshTreeData) && (
            <button
              type="button"
              className="device-manager__table__header__add-button details__button--edit"
              onClick={ toggleDeviceActionModal }
              data-testid="update-device"
            >
              <FontAwesomeIcon icon={ faPen }/>
            </button>
          ) }
          { showDeviceActionModal && (
            <Portal>
              <DeviceActionModal
                closeAddAndUpdateModal={ toggleDeviceActionModal }
                title="update-device"
                showDeleteButton
                addUnit={ false }
                removeUnit={ removeUnit }
                id={ id }
                refreshTreeData={ refreshTreeData }
                refreshDevices={ refreshDevices }
                getDeviceInfo={ getDeviceInfo }
              />
            </Portal>
          ) }
        </>
      </HasPermission>
      <Tab.Container
        id="device-details-modal-tabs"
        activeKey={ activeTabKey }
        onSelect={ (eventKey) => setActiveTabKey(eventKey as ModalTabEventKeys) }
      >
        <RenderOnCondition condition={ ancestorPath || getDeviceAncestorPath() }>
          <Row>
            <DetailCol translateKey="device-group" colSize={ 12 }>
              <p className="details__text">{ ancestorPath || getDeviceAncestorPath() }</p>
            </DetailCol>
          </Row>
        </RenderOnCondition>
        <Nav variant="tabs" onScroll={ handleScroll }>
          <RenderOnCondition condition={ showLeftOverflowIcon }>
            <div className="overflow-left-icon-container">
              <FontAwesomeIcon className="left-icon" icon={ faCaretLeft }/>
            </div>
          </RenderOnCondition>
          <RenderOnCondition condition={ showRightOverflowIcon }>
            <div className="overflow-right-icon-container">
              <FontAwesomeIcon className="right-icon" icon={ faCaretRight }/>
            </div>
          </RenderOnCondition>
          <HasPermission permissionObj={ deviceRole } permissionKey={ACLDeviceView.BASIC}>
            <Nav.Item>
              <Nav.Link eventKey={ ModalTabEventKeys.BASIC }>
                <Translate id="basic"/>
              </Nav.Link>
            </Nav.Item>
          </HasPermission>

          <HasPermission permissionObj={ deviceRole } permissionKey={ACLDeviceView.GATEWAYS}>
            <RenderOnCondition
              condition={ device.deviceType?.mBusDeviceTypeId !== MBusDeviceType.GATEWAY && !isKeyManagerModeEnabled }
            >
              <Nav.Item>
                <Nav.Link eventKey={ ModalTabEventKeys.GATEWAYS }>
                  <Translate id="gateways"/>
                </Nav.Link>
              </Nav.Item>
            </RenderOnCondition>
          </HasPermission>

          <HasPermission permissionObj={ deviceRole } permissionKey={ACLDeviceView.RADIO_INFO}>
            <RenderOnCondition
              condition={ deviceMetadata?.radioInfo?.length }
            >
              <Nav.Item>
                <Nav.Link eventKey={ DeviceMetadataFields.radioInfo }>
                  <Translate id="radioInfo"/>
                </Nav.Link>
              </Nav.Item>
            </RenderOnCondition>
          </HasPermission>

          <HasPermission permissionObj={ deviceRole } permissionKey={ACLDeviceView.DESCRIPTION}>
            <RenderOnCondition
              condition={ deviceMetadata?.deviceDescription?.length }
            >
              <Nav.Item>
                <Nav.Link eventKey={ DeviceMetadataFields.deviceDescription }>
                  <Translate id="device-description"/>
                </Nav.Link>
              </Nav.Item>
            </RenderOnCondition>
          </HasPermission>

          <HasPermission permissionObj={ deviceRole } permissionKey={ACLDeviceView.METADATA}>
            <RenderOnCondition
              condition={ deviceMetadata?.deviceInfo?.length || deviceMetadata?.metadata?.length }
            >
              <Nav.Item>
                <Nav.Link eventKey={ DeviceMetadataFields.metadata }>
                  <Translate id="metadata"/>
                </Nav.Link>
              </Nav.Item>
            </RenderOnCondition>
          </HasPermission>

          <HasPermission permissionObj={ messageRole } permissionKey={ACLMessage.VIEW}>
            <RenderOnCondition
              condition={ (deviceReadings && deviceReadings.length > 0) }
            >
              <Nav.Item>
                <Nav.Link eventKey={ ModalTabEventKeys.MESSAGES }>
                  <Translate id="messages"/>
                </Nav.Link>
              </Nav.Item>
            </RenderOnCondition>
          </HasPermission>
          <HasPermission permissionObj={ deviceRole } permissionKey={ACLDeviceView.CONSUMPTION}>
            <RenderOnCondition condition={ shouldRenderConsumptionTab }>
              <Nav.Item>
                <Nav.Link eventKey={ ModalTabEventKeys.CONSUMPTION }>
                  <Translate id="consumption"/>
                </Nav.Link>
              </Nav.Item>
            </RenderOnCondition>
          </HasPermission>
          <HasPermission permissionObj={ deviceRole } permissionKey={ACLDeviceView.USERS_WITH_ACCESS}>
            <RenderOnCondition condition={ usersWithAccess?.length && !isKeyManagerModeEnabled }>
              <Nav.Item>
                <Nav.Link eventKey={ ModalTabEventKeys.USERS_WITH_ACCESS }>
                  <Translate id="users-with-access"/>
                </Nav.Link>
              </Nav.Item>
            </RenderOnCondition>
          </HasPermission>
          <HasPermission permissionObj={ deviceRole } permissionKey={ACLDeviceView.IMAGES}>
            <RenderOnCondition condition={ isChangeModal || (images && images.length > 0) }>
              <Nav.Item>
                <Nav.Link eventKey={ ModalTabEventKeys.IMAGES }>
                  <Translate id="images"/>
                </Nav.Link>
              </Nav.Item>
            </RenderOnCondition>
          </HasPermission>
        </Nav>
        <Tab.Content>
          <HasPermission permissionObj={ deviceRole } permissionKey={ACLDeviceView.BASIC}>
            <Tab.Pane eventKey={ ModalTabEventKeys.BASIC }>
              <Row>
                <Col xs={ 12 }>
                  <Row>
                    <Col lg={ 3 } className="text-center">
                      <Image
                        className="thumbnail"
                        src={ getDeviceTypeIconFullPath(deviceType) }
                        alt="device type"
                      />
                    </Col>
                    <Col lg={ 9 }>
                      <Row>
                        <Col lg={ 6 }>
                          <Row noGutters>
                            <DetailCol
                              translateKey="device-type"
                              colSize={ 12 }
                            >
                              <p className="details__text">
                                { deviceType?.name ? getTranslationValueInCurrentLanguage(deviceType.name) : '-' }
                              </p>
                            </DetailCol>
                            <DetailCol
                              translateKey={ getTranslationIdFromProtocolType(device, DeviceFields.DEVICE_NAME) }
                              colSize={ 12 }
                            >
                              <p className="details__text">{ name || '-' }</p>
                            </DetailCol>
                            <DetailCol
                              translateKey={ getTranslationIdFromProtocolType(device, DeviceFields.DEVICE_VERSION) }
                              colSize={ 12 }
                            >
                              <p className="details__text">
                                { version || '-' }
                              </p>
                            </DetailCol>
                            <RenderOnCondition condition={ !isKeyManagerModeEnabled }>
                              <DetailCol
                                translateKey="last-message"
                                colSize={ 12 }
                              >
                                <p className="details__text">
                                  { lastMessage ? localizeDate(lastMessage.receivedAt) : '-' }
                                </p>
                              </DetailCol>
                            </RenderOnCondition>
                          </Row>
                        </Col>
                        <Col lg={ 6 }>
                          <Row noGutters>
                            <DetailCol
                              translateKey={ getTranslationIdFromProtocolType(device, DeviceFields.RADIO_TYPE) }
                              colSize={ 12 }
                            >
                              <p className="details__text">
                                  < Translate id={ getSelectedMetaDataValue(
                                      DeviceMetadataFields.deviceInfo,
                                      DeviceFields.RADIO_TYPE,
                                      deviceMetadata,
                                    ) || '-'
                                  }
                                  />
                              </p>
                            </DetailCol>
                            <DetailCol
                              translateKey={ getTranslationIdFromProtocolType(device, DeviceFields.DEVICE_ID) }
                              colSize={ 12 }
                            >
                              <p className="details__text">
                                <CopyButton copyValue={ deviceId || '-' } hideIcon>
                                  { deviceId || '-' }
                                </CopyButton>
                              </p>
                            </DetailCol>
                            <RenderOnCondition condition={ !isKeyManagerModeEnabled }>
                              <DetailCol
                                translateKey={ getTranslationIdFromProtocolType(device, DeviceFields.RADIO_KEY) }
                                colSize={ 12 }
                              >
                                <p className="details__text">{ (radioKey && radioKey.name) || '-' }</p>
                              </DetailCol>
                            </RenderOnCondition>
                            <RenderOnCondition condition={ isSecondaryRadioKeyVisible }>
                              <DetailCol
                                translateKey={ getTranslationIdFromProtocolType(device, DeviceFields.SECONDARY_RADIO_KEY) }
                                colSize={ 12 }
                              >
                                <p className="details__text">{ communicationOptions?.secondaryRadioKey?.name || '-' }</p>
                              </DetailCol>
                            </RenderOnCondition>
                            <RenderOnCondition condition={ communicationOptions?.lora?.joinEui }>
                              <DetailCol
                                translateKey={ getTranslationIdFromProtocolType(device, DeviceFields.JOIN_EUI) }
                                colSize={ 12 }
                              >
                                <p className="details__text">{ communicationOptions?.lora?.joinEui || '-' }</p>
                              </DetailCol>
                            </RenderOnCondition>
                            <RenderOnCondition condition={ !isKeyManagerModeEnabled }>
                              <DetailCol translateKey="status">
                                { status && status.length > 0 ? (
                                  <DeviceStatusPil id={ `status-pill-device-modal-${ id }` } status={ status } tooltipPlace="right"/>
                                ) : (
                                  <div>-</div>
                                ) }
                              </DetailCol>
                            </RenderOnCondition>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <DetailCol
                          translateKey={ getTranslationIdFromProtocolType(device, DeviceFields.MANUFACTURER) }
                          colSize={ 12 }
                        >
                          <p className="details__text">{ manufacturerName }</p>
                        </DetailCol>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>



              <Row>
                { oldDevice && (
                  <DetailCol translateKey="replace-for">
                    <a
                      style={
                        oldDevice && oldDeviceName?.deviceId
                          ? {
                            cursor: 'pointer',
                            textDecoration: 'underline',
                          }
                          : {}
                      }
                      onClick={ () => {
                        if (oldDevice) {
                          getDeviceInfo(oldDevice);
                        }
                      } }
                    >
                      <p className="details__text">
                        { oldDeviceName?.deviceId || <Translate id="deleted-device"/> }
                      </p>
                    </a>
                  </DetailCol>
                ) }
                { newDevice && (
                  <DetailCol translateKey="replaced-by">
                    <a
                      style={
                        newDevice && newDeviceName?.deviceId
                          ? {
                            cursor: 'pointer',
                            textDecoration: 'underline',
                          }
                          : {}
                      }
                      onClick={ () => {
                        if (newDevice) {
                          getDeviceInfo(newDevice);
                        }
                      } }
                    >
                      <p className="details__text">
                        { newDeviceName?.deviceId || <Translate id="deleted-device"/> }
                      </p>
                    </a>
                  </DetailCol>
                ) }
              </Row>



            </Tab.Pane>
          </HasPermission>

          <HasPermission permissionObj={deviceRole} permissionKey={ACLDeviceView.GATEWAYS}>
            <GatewaysTab
              getDeviceInfo={ getDeviceInfo }
              setActiveTabKey={ setActiveTabKey }
              permission={ deviceRole }
              gatewayDevices={ gatewayDevices }
            />
          </HasPermission>

          <HasPermission permissionObj={ deviceRole } permissionKey={ACLDeviceView.DESCRIPTION}>
            <Tab.Pane eventKey={ DeviceMetadataFields.deviceDescription }>
              <DeviceMetaData
                field={ DeviceMetadataFields.deviceDescription }
                data={ deviceMetadata }
                permission={ deviceRole }
              />
            </Tab.Pane>
          </HasPermission>

          <HasPermission permissionObj={ deviceRole } permissionKey={ACLDeviceView.RADIO_INFO}>
            <Tab.Pane eventKey={ DeviceMetadataFields.radioInfo }>
              <DeviceMetaData
                field={ DeviceMetadataFields.radioInfo }
                data={ deviceMetadata }
                permission={ deviceRole }
              />
            </Tab.Pane>
          </HasPermission>

          <HasPermission permissionObj={ deviceRole } permissionKey={ACLDeviceView.METADATA}>
            <Tab.Pane eventKey={ DeviceMetadataFields.metadata }>
              <RenderOnCondition condition={ notes }>
                <Row>
                  <DetailCol translateKey="notes" colSize={ 12 }>
                    <p className="details__text">{ notes }</p>
                  </DetailCol>
                </Row>
              </RenderOnCondition>

              <DeviceMetaData
                field={ DeviceMetadataFields.deviceInfo }
                data={ deviceMetadata }
                permission={ deviceRole }
                deviceType={ deviceType }
              />
              <DeviceMetaData
                field={ DeviceMetadataFields.metadata }
                data={ deviceMetadata }
                permission={ deviceRole }
                deviceType={ deviceType }
              />
            </Tab.Pane>
          </HasPermission>

          <HasPermission permissionObj={ messageRole } permissionKey={ACLMessage.VIEW}>
            <Tab.Pane eventKey={ ModalTabEventKeys.MESSAGES }>
              <div className="details__column details__column--all-messages">
                <div className="details__row">
                  <div className="details__column grow">
                    <RenderOnCondition condition={ deviceReadings && deviceReadings.length }>
                      <>
                        <table className="device-manager__table__table">
                          <thead>
                            <tr className="details__headings--table">
                              { ['', 'value', 'source'].map((h, i) => (
                                <th key={ i }>
                                  <Translate id={ h }/>
                                </th>
                              )) }
                            </tr>
                          </thead>
                          <tbody>
                            { deviceReadings.map((reading: DeviceReading) => {
                              const sourceTypeName = getSourceTypeName(reading.source);
                              return (
                                <tr className="details__text" key={ reading.id }>
                                  <td className="details__td">{ getReadingDate(reading) }</td>
                                  <td className="details__td w-25">
                                    <FormattedDeviceReadingValue deviceReading={ reading }/>
                                  </td>
                                  <td className="details__td w-25">
                                    <img
                                      className="details__source-icon"
                                      data-tip="source-type-icon"
                                      data-for={ `source-${ id }-${ sourceTypeName }` }
                                      src={
                                        (sourceTypeName && sourceTypeIconSmall[sourceTypeName]) ||
                                        QUESTION_MARK
                                      }
                                      alt={ sourceTypeName }
                                    />
                                    <Tooltip id={ `source-${ id }-${ sourceTypeName }` }>
                                      { sourceTypeName ? <Translate id={ sourceTypeName }/> : '-' }
                                    </Tooltip>
                                  </td>
                                </tr>
                              );
                            }) }
                          </tbody>
                        </table>
                        { !isChangeModal && (
                          <LinkToDeviceMessagesPage
                            idOfDevice={ id }
                            style={{
                              textDecoration: 'none',
                              color: 'inherit',
                              width: '100%',
                              display: 'block',
                            }}
                          >
                            <button className="device-manager__table__header__add-button device-manager__table__header__add-button--all">
                              <Translate id="all-messages"/>
                            </button>
                          </LinkToDeviceMessagesPage>
                        ) }
                      </>
                    </RenderOnCondition>
                  </div>
                </div>
              </div>
            </Tab.Pane>
          </HasPermission>
          <HasPermission permissionObj={ deviceRole } permissionKey={ACLDeviceView.CONSUMPTION}>
            <RenderOnCondition condition={ shouldRenderConsumptionTab }>
              <Tab.Pane eventKey={ ModalTabEventKeys.CONSUMPTION }>
                <div className="details__section">
                  <ConsumptionTab
                    device={ device }
                  />
                </div>
              </Tab.Pane>
            </RenderOnCondition>
          </HasPermission>
          <HasPermission permissionObj={ deviceRole } permissionKey={ACLDeviceView.USERS_WITH_ACCESS}>
            <Tab.Pane eventKey={ ModalTabEventKeys.USERS_WITH_ACCESS }>
              <UsersWithAccess users={usersWithAccess} />
            </Tab.Pane>
          </HasPermission>
          <HasPermission permissionObj={ deviceRole } permissionKey={ACLDeviceView.IMAGES}>
            <Tab.Pane eventKey={ ModalTabEventKeys.IMAGES }>
              <div className="details__section">
                <RenderOnCondition condition={ isChangeModal }>
                  <CustomImageFileInput
                    onFilesUpdated={ onDropFiles }
                    onFilesRemoved={ onImagesRemoved }
                    files={ images }
                    toggleGallery={ toggleGallery }
                    toggleOcrLoading={ toggleOcrLoading }
                    toggleOcrWizard={ toggleOcrWizard }
                    isOcrLoading={ isOcrLoading }
                    setCurrentFile={ setCurrentFile }
                    setOcrFoundData={ setOcrFoundData }
                  />
                </RenderOnCondition>
                <RenderOnCondition condition={ !isChangeModal }>
                  <Thumbnails
                    toggleGallery={ toggleGallery }
                    setCurrentFile={ setCurrentFile }
                    files={ images }
                  />
                  <RenderOnCondition condition={ !(images && images.length > 0) }>
                    <Translate id="no-data"/>
                  </RenderOnCondition>
                </RenderOnCondition>
              </div>
            </Tab.Pane>
          </HasPermission>
          <RenderOnCondition condition={ isChangeModal }>
            <div className="details__row--right justify-content-end">
              <button
                type="button"
                className="form__button--blue background-color-main text-color-white border-color-main mr-2"
                onClick={ uploadImages }
              >
                <Translate id="upload-files"/>
              </button>
              <button
                type="button"
                className="form__button--blue background-color-main text-color-white border-color-main"
                onClick={ toggleChangeOptions }
              >
                <Translate id="next"/>
              </button>
            </div>
          </RenderOnCondition>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  showFullTimestamp: state.currentUser.user?.settings?.display?.showFullTimestamp,
});

export default connect(mapStateToProps)(DeviceDetailsBody);
