import { IDeviceManagerFilter } from '../../state/reducers/filterSortReducer';
import { getDeviceGroupIds } from '../../utils/device-group-filter-helper';
import { MINIMUM_NUMBER_OF_CHARACTERS_TO_START_SEARCH } from '../FilterBar/select/constants/minimum-number-of-characters-to-start-search';

export const getDeviceManagersFilterString = (filter?: IDeviceManagerFilter): string => {
  const {
    name,
    deviceGroup,
    manufacturers,
    status,
    deviceTypes,
    gateways,
    ...remaining
  } = filter || {};

  const updatedFilter = { ...remaining }
  if (name && name.length >= MINIMUM_NUMBER_OF_CHARACTERS_TO_START_SEARCH) {
    updatedFilter['name'] = name;
  }

  // TODO 1: Types must be simplified and the types cleaned up. A bit too weird.
  const deviceGroupIds = getDeviceGroupIds(deviceGroup);
  if (deviceGroupIds && deviceGroupIds.length > 0) {
    updatedFilter['deviceGroup'] = deviceGroupIds;
  } else if (deviceGroup === null) {
    updatedFilter['deviceGroup'] = null;
  }

  const deviceTypeIds = deviceTypes?.map((option) => option.value);
  if (deviceTypeIds && deviceTypeIds.length > 0) {
    updatedFilter['deviceType'] = deviceTypeIds;
  }

  const statusIds = status?.map((option) => option.value);
  if (statusIds && statusIds.length > 0) {
    updatedFilter['status'] = statusIds;
  }

  const manufacturerIds = manufacturers?.map((option) => option.value);
  if (manufacturerIds && manufacturerIds.length > 0) {
    updatedFilter['manufacturer.flagId'] = manufacturerIds;
  }

  return JSON.stringify(updatedFilter);
};
