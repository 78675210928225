import { isEqual } from 'lodash';
import React, { useEffect, CSSProperties } from 'react';
import { useSelector } from 'react-redux';
import ReactSelect, { components, ControlProps, MenuProps, ValueType, OptionTypeBase, Styles, Theme} from 'react-select';
import { Option as ReactSelectOption } from 'react-select/src/filters';
import { ValueContainerProps } from 'react-select/src/components/containers';
import { IndicatorProps } from 'react-select/src/components/indicators';
import { OptionProps } from 'react-select/src/components/Option';
import classNames from 'classnames';
import { getSelectStyles } from '../../Input/select/get-styles';
import { AppState } from '../../../state/reducers/rootReducer';
import useTranslation from '../../../hooks/useTranslation';
import { Option } from './components/Option';
import { Control } from './components/Control';
import { ValueContainer } from './components/ValueContainer';
import { DropdownIndicator } from './components/DropdownIndicator';
import { Menu } from './components/Menu';
import { getReasoningMessageForNoOptions } from './functions/get-reasoning-message-for-no-options';
import { ClearIndicator } from './components/ClearIndicator';
import MenuPortal from '../../MenuPortal';
import { getPlaceholderForSelect } from './functions/get-placeholder-for-select';
import { sortOptions } from './functions/sort-options';
import { InputContextType } from '../../Input/input-context-type';
import { showTooManySelectedOptionsError } from './functions/show-too-many-selected-options-error';
import { MAXIMUM_NUMBER_OF_SELECTED_OPTIONS } from './functions/selection-limits';
import { includesCaseInsensitive } from '../../../utils/string';

export interface BaseSelectProps<OptionType extends OptionTypeBase> {
  translationId: string;
  getOptionLabel?: (option: OptionType) => string;
  getOptionValue?: (option: OptionType) => any;

  defaultOptions: OptionType[];
  selectedOptions: undefined | OptionType | OptionType[];
  onChange: (values: ValueType<OptionType>) => void;

  defaultValue?: OptionType | OptionType[];

  label?: string;

  placeholderTranslationId?: string;
  placeholderPrefix?: string;

  option?: (props: OptionProps<OptionType>) => React.ReactElement;
  noOptionsMessage?: (args: any) => string;

  portalTargetId?: string;

  isMulti?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  sortLabels?: boolean;
  sortValues?: boolean;
  translateOptions?: boolean;

  isDisabled?: boolean;
  readOnly?: boolean;
  onlyStoreValue?: boolean;
  required?: boolean;
  showDefaultOption?: boolean;
  showSelectAndDeselectAllButtons?: boolean;

  onBlur?: (name: string) => void;
  error?: string;

  className?: string;
  inputContextType?: InputContextType;
  classNameForLabel?: string;

  listHeight?: number;
}

export const BaseSelect = <OptionType extends OptionTypeBase> (props: BaseSelectProps<OptionType>) => {
  const {
    translationId,
    getOptionLabel,
    getOptionValue,

    defaultOptions,
    selectedOptions,
    defaultValue,
    onChange,

    label,

    placeholderTranslationId = translationId,
    placeholderPrefix,

    option,
    noOptionsMessage,

    portalTargetId,

    isMulti = true,
    isClearable = false,
    isSearchable = true,
    sortLabels = true,
    sortValues = false,
    translateOptions = false,

    isDisabled = false,
    readOnly = false,
    onlyStoreValue = false,
    required = false,
    showDefaultOption = true,
    showSelectAndDeselectAllButtons = false,

    onBlur,
    error,

    inputContextType = InputContextType.MODAL,
    className,
    classNameForLabel = 'form__label',
    listHeight = 200,
  } = props;
  const isMobileDisplay = useSelector((state: AppState) => state.isMobileDisplay);
  const reactSelectStyles = getSelectStyles(isMobileDisplay, '24px', '#fff');

  const translate = useTranslation();

  // (DM) TODO: WIOT-1239 react-select `sortLabel` prop is not working as expected. This is a workaround.
  useEffect(() => {
    if (sortLabels && Array.isArray(defaultOptions)) {
      sortOptions<OptionType>(defaultOptions, getOptionLabel);
    }
  }, [defaultOptions]);

  const handleSelectionChange = (values: ValueType<OptionType>) => {
    if (values?.length > MAXIMUM_NUMBER_OF_SELECTED_OPTIONS) {
      showTooManySelectedOptionsError(translate);
      return;
    }
    onChange(values);
  }

  const handleBlur = () => {
    if (onBlur) {
      onBlur(translationId);
    }
  };

  const isOptionChecked = (menuOption: OptionProps<OptionType>): boolean => {
    const { data: selectableOption, isSelected } = menuOption;
    if (!selectedOptions) {
      return false;
    }
    if (selectableOption) {
      if (Array.isArray(selectedOptions)) {
        return !!selectedOptions.find((selected) => areOptionsEqual(selected, selectableOption));
      } else {
        return areOptionsEqual(selectedOptions, selectableOption);
      }
    }
    return isSelected || false;
  }
  const areOptionsEqual = (a: OptionType, b: OptionType) => {
    if (getOptionValue) {
      if (onlyStoreValue) {
        return a === getOptionValue(b);
      } else {
        return getOptionValue(a) === getOptionValue(b);
      }
    } else {
      if (a.value && b.value) {
        return a.value === b.value;
      }
    }
    return isEqual(a, b);
  }

  const isOptionVisibleWithGivenFilterString = (option: ReactSelectOption, searchText: string) => {
    if (!isSearchable || !searchText) {
      return showDefaultOption;
    } else {
      if (option.label) {
        return includesCaseInsensitive(option.label, searchText)
      }
      return getOptionLabel
        ? includesCaseInsensitive(getOptionLabel(option.data), searchText)
        : includesCaseInsensitive(option.data.label, searchText);
    }
  }

  const getControl = (controlProps: ControlProps<OptionType>) =>
    <Control
      { ...controlProps }
    />

  const emptySelection = isMulti ? [] : undefined;
  const getClearIndicator = (clearIndicatorProps: IndicatorProps<OptionType>) =>
    (!isClearable || readOnly)
    ? null
    : (
      <ClearIndicator
        { ...clearIndicatorProps }
        clear={ () => handleSelectionChange(emptySelection) }
      />
    );

  const getDropdownIndicator = (dropdownIndicatorProps: IndicatorProps<OptionType>) => readOnly ? null : (
    <DropdownIndicator
      { ...dropdownIndicatorProps }
    />
  );

  const getMenu = (menuProps: MenuProps<OptionType>) => {
    const handleSelectAllClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, currentOptions?: OptionType[]) => {
      e.stopPropagation();
      handleSelectionChange(currentOptions && currentOptions.length > 0 ? currentOptions : defaultOptions );
    };
    const handleSelectNoneClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      e.stopPropagation();
      handleSelectionChange(emptySelection);
    };

    const tooManyOptions = defaultOptions?.length && defaultOptions?.length > MAXIMUM_NUMBER_OF_SELECTED_OPTIONS;
    const areButtonsVisible = showSelectAndDeselectAllButtons && isMulti && !isMobileDisplay && !tooManyOptions;
    return (
      <Menu
        { ...menuProps }
        showSelectAndDeselectAllButtons={ areButtonsVisible }
        handleSelectAllClick={ areButtonsVisible ? handleSelectAllClick : () => {} }
        handleSelectNoneClick={ areButtonsVisible ? handleSelectNoneClick : () => {} }
        defaultOptionsCount={ areButtonsVisible ? defaultOptions?.length : undefined }
      />
    )
  }

  const getOptions = (optionProps: OptionProps<OptionType>) => {
    if (option) {
      return option(optionProps);
    } else {
      let labelValue = getOptionLabel ? getOptionLabel(optionProps.data) : optionProps.data.label;
      labelValue = translateOptions ? translate(labelValue) : labelValue;

      return (
        <Option
          {...optionProps}
          label={ labelValue }
          isOptionChecked={ isOptionChecked(optionProps) }
        />
      );
    }
  }

  const getPlaceholder = getPlaceholderForSelect(
      translate,
      selectedOptions,
      placeholderTranslationId || translationId,
      getOptionLabel,
      translateOptions,
      placeholderPrefix,
    );

  const getStyles: Styles =({
    ...reactSelectStyles,
    menuPortal: (provided: CSSProperties) => ({
      ...provided,
      zIndex: 9999,
    }),
    menu: (provided: CSSProperties) => ({
      ...provided,
      zIndex: "9999",
    }),
    menuList: (provided: CSSProperties) => ({
      ...provided,
      maxHeight: listHeight,
      fontSize: isMobileDisplay ? '16px' : '12px',
    }),
  });

  const getTheme = (theme: Theme): Theme => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: '#E4F0FDFF',
    },
  });

  return (
    <div className="w-100">
      {label ? (
        <div className={ classNameForLabel }>
          <label className={ required ? 'required' : '' } htmlFor={ label }>
            { `${ translate(label) }` }
          </label>
        </div>
      ) : null }
      <div className={ `${ inputContextType }-select-wrapper` }>

    <ReactSelect
      id={`${ inputContextType }-${ translationId }-select-component`}
      readOnly={ readOnly }
      className={ classNames(
        className,
        `${ inputContextType }-select`,
        {
          [`${ inputContextType }-input--readonly`] : readOnly,
        }
      ) }
      classNamePrefix={ `${ inputContextType }-select` }
      openMenuOnClick={ !readOnly }
      onSelectResetsInput={ false }
      closeMenuOnSelect
      components={{
        MenuPortal,
        Control: getControl,
        Input: readOnly ? () => null : components.Input,
        ValueContainer,
        ClearIndicator: readOnly ? () => null : getClearIndicator,
        DropdownIndicator: readOnly ? () => null : getDropdownIndicator,
        Menu: getMenu,
        Option: getOptions,
        Placeholder: () => null,
        MultiValueContainer: () => null,
        MultiValueLabel: () => null,
        SingleValue: () => null,
        IndicatorSeparator: () => null,
      }}
      isMulti={ isMulti }
      isSearchable={ isSearchable }
      isClearable={ isClearable }
      sortLabels={ sortLabels }

      options={ defaultOptions }
      placeholder={ getPlaceholder }
      filterOption={ isOptionVisibleWithGivenFilterString }
      defaultOptions={ showDefaultOption }
      value={ selectedOptions }
      defaultValue={ defaultValue }
      onChange={ handleSelectionChange }
      onBlur={ handleBlur }

      getOptionValue={ getOptionValue }
      getOptionLabel={ getOptionLabel }

      menuPortalTarget={ portalTargetId ? document.getElementById(portalTargetId) : null}
      menuPosition="absolute"
      menuPlacement="bottom"
      menuShouldScrollIntoView={ false }

      noOptionsMessage={
        noOptionsMessage
          ? noOptionsMessage
          : (args) => getReasoningMessageForNoOptions(args.inputValue, translate)
      }
      hideSelectedOptions={ false }
      styles={ getStyles }
      theme={ getTheme }
    />

    </div>
    {!!error && <div className="input-error">{error}</div>}
  </div>
  );
};
