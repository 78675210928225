import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import ListGroup from 'react-bootstrap/ListGroup';

import 'react-datepicker/dist/react-datepicker.css';
import useTranslation from '../../hooks/useTranslation';
import { OutsideClickHandler } from '../OutsideClickHandler';
import { periodToDateRange } from '../../utils/common';
import { setFilter } from '../../state/filter/set-filter-action-creator';
import { useDispatch, useSelector } from 'react-redux';
import { IDeviceMessageFilter, PageKeys } from '../../state/reducers/filterSortReducer';
import { AppState } from '../../state/reducers/rootReducer';
import { DURATIONS, IDuration } from './durations';

interface FilterDateRangeProps {
  pageKey: PageKeys;
}

const FilterDateRange = (props: FilterDateRangeProps) => {
  const { pageKey } = props;
  const filter = useSelector((state: AppState) => state.filters.filter);
  const pageFilter = filter[pageKey] as IDeviceMessageFilter;

  const period = pageFilter.period;
  let startDate: any = pageFilter.receivedAt_gte;
  let endDate: any = pageFilter.receivedAt_lte;

  if (period) {
    // If the range is stored as a period then get the corresponding dates for that
    const dateRange = periodToDateRange(period);
    startDate = dateRange.startDate;
    endDate = dateRange.endDate;
  }

  const [ open, setOpen ] = useState(false);

  const translate = useTranslation();
  const dispatch = useDispatch();

  const handleDatePickerChange = (newValues: Record<string, unknown>) => {
    dispatch(
      setFilter({
        page: pageKey,
        values: newValues,
      }),
    );
  };

  const handleDatePeriodChange = (period?: IDuration): void => {
    dispatch(
      setFilter({
        page: pageKey,
        values: {
          period,
        },
      }),
    );
  };

  const handleClickOutside = (): void => {
    setOpen(false);
  };

  const handleSelectOption = (option: IDuration): void => {
    handleDatePickerChange({ receivedAt_gte: undefined, receivedAt_lte: undefined });
    handleDatePeriodChange(option);
  };

  const formatDate = (date: string | Date | number): string => {
    const formattedDate = moment(new Date(date)).format('DD/MM/YYYY');
    if (formattedDate === 'Invalid date') {
      return '';
    }
    return formattedDate;
  };

  const handleStartDateChange = (date: Date): void => {
    // When the custom date range is selected, clear the relative period(ex, 30-days) and store the start date
    handleDatePeriodChange(undefined);
    handleDatePickerChange({ receivedAt_gte: date.setHours(0, 0, 0, 0) });
  };

  const handleEndDateChange = (date: Date): void => {
    // When the custom date range is selected, clear the relative period(ex, 30-days) and store the end date
    handleDatePeriodChange(undefined);
    handleDatePickerChange({ receivedAt_lte: date.setHours(23, 59, 59, 999) });
  };

  const formattedStart = formatDate(startDate);
  const formattedEnd = formatDate(endDate);
  const inputValue = `${ formattedStart } - ${ formattedEnd }`;

  return (
    <OutsideClickHandler onClickOutside={ handleClickOutside }>
      <div className="filterbar__item filter-bar-select">
        <input
          type="text"
          className={ `filter-bar-control filter-bar-select__control ${ open ? 'filter-bar-select__control--active' : '' }` }
          onClick={ () => setOpen(!open) }
          readOnly
          value={ period ? translate(period).toString() : inputValue }
        />
        { open && (
          <div className="filterbar__item__list__wrapper">
            <ListGroup>
              { DURATIONS.map((option) => (
                <ListGroup.Item
                  key={ option }
                  active={ period === option }
                  onClick={ () => {
                    handleSelectOption(option);
                  } }
                >
                  { translate(option) }
                </ListGroup.Item>
              )) }
            </ListGroup>
            <div className="filterbar__item__list__picker">
              <DatePicker
                className="form-control"
                placeholderText="von"
                // @ts-ignore
                selected={ startDate }
                selectsStart
                dateFormat="dd/MM/yyyy"
                onChange={ handleStartDateChange }
              />
              <DatePicker
                className="form-control"
                placeholderText="bis"
                selectsEnd
                minDate={ startDate }
                selected={ endDate }
                dateFormat="dd/MM/yyyy"
                onChange={ handleEndDateChange }
              />
            </div>
          </div>
        ) }
      </div>
    </OutsideClickHandler>
  );
};

export default FilterDateRange;
