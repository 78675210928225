import { getMeasurementAttributeIds } from '@wiot/shared-domain/models/device-type-profile/device-type-profile';
import { IColumnObject } from '@wiot/shared-domain/models/device/device';

export const DEVICE_MANAGER_TABLE_HEADERS = [
  'device-type-icon',
  'device-id',
  'device-name',
  'manufacturer',
  'device-group',
  'last-message',
  'status',
];

const ALL_COLUMNS = [...DEVICE_MANAGER_TABLE_HEADERS, ...getMeasurementAttributeIds(), 'actions'];
const SORTABLE_COLUMNS = ['device-id', 'device-name', 'device-group', 'manufacturer', 'status'];
const DEFAULT_VISIBLE_COLUMNS = [...DEVICE_MANAGER_TABLE_HEADERS, 'primaryValue', 'actions'];
export const MANDATORY_COLUMNS = ['device-type-icon', 'device-id', 'actions'];

export const DEFAULT_COLUMNS: IColumnObject[] = ALL_COLUMNS.map((column) => (
  {
    name: column,
    visible: DEFAULT_VISIBLE_COLUMNS.includes(column),
    sortable: SORTABLE_COLUMNS.includes(column)
  }
));

export const KEY_MANAGER_TABLE_COLUMNS = [
  'device-type-icon',
  'device-id',
  'device-name',
  'manufacturer',
  'device-group',
  'actions',
].map((column) => (
  {
    name: column,
    visible: true,
    sortable: SORTABLE_COLUMNS.includes(column)
  }
));
