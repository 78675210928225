import { TreeNode } from '@wiot/shared-domain/domain/device-tree/tree-node';
import { SUB_TREE_FETCHED, SubTreeFetchedAction } from './subTreeActions';

export type DeviceHierarchySubTree = {
  children: TreeNode[];
  root: TreeNode | null,

};

const initialState: DeviceHierarchySubTree = {
  children: [],
  root: null,
};

export const subTreeReducer = (
  state: DeviceHierarchySubTree = initialState,
  action: SubTreeFetchedAction,
): DeviceHierarchySubTree => {
  switch (action.type) {
    case SUB_TREE_FETCHED:
      return {
        ...state,
        children: action.subTreeRoot.children,
        root: action.subTreeRoot,
      };
    default:
      return state;
  }
};
