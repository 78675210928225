import {
  SAVE_DEVICE_GROUPS,
  TOGGLE_SELECT_ALL_DEVICE_GROUPS,
  TOGGLE_SELECT_DEVICE_GROUP,
} from '../constants/actionTypes';
import { DeviceGroupExtended } from '../types';

const initialState: DeviceGroupExtended[] = [];

export const deviceGroupsReducer = (
  state: any = initialState,
  action: Record<any, any>,
): DeviceGroupExtended[] => {
  switch (action.type) {
    case SAVE_DEVICE_GROUPS:
      return action.payload.map((deviceGroups: DeviceGroupExtended) => ({
        ...deviceGroups,
        checked: false,
      }));

    case TOGGLE_SELECT_DEVICE_GROUP:
      return state.map((deviceGroup: DeviceGroupExtended) => {
        if (String(deviceGroup.id) !== action.payload) {
          return deviceGroup;
        }
        return {
          ...deviceGroup,
          checked: !deviceGroup.checked,
        };
      });

    case TOGGLE_SELECT_ALL_DEVICE_GROUPS:
      return state.map((deviceGroup: DeviceGroupExtended) => ({
        ...deviceGroup,
        checked: action.payload,
      }));

    default:
      return state;
  }
};
