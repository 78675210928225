import { DeviceGroupCreationTypes } from '../device-group/create-device-group/deviceGroupCreationTypes';
import { DeviceGroupUpdateTypes } from '../device-group/update-device-group/deviceGroupUpdateTypes';

export const IS_TABLE_LOADING = 'IS_TABLE_LOADING';

interface IsTableLoadingAction {
  type: typeof IS_TABLE_LOADING;
  payload: boolean;
}

export type IsTableLoadingActionTypes =
  IsTableLoadingAction
  | DeviceGroupCreationTypes
  | DeviceGroupUpdateTypes;
export const isTableLoading = (loading: boolean): IsTableLoadingActionTypes => ({
  type: IS_TABLE_LOADING,
  payload: loading,
});
