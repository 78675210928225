import {
  faBackspace,
  faBatteryQuarter,
  faBolt,
  faBug,
  faCheck,
  faClock,
  faCommentSlash,
  faExclamationTriangle,
  faFileAlt,
  faGlasses,
  faHourglassEnd,
  faHourglassHalf,
  faInfo,
  faKey,
  faLock,
  faMicrochip,
  faPowerOff,
  faQuestionCircle,
  faTemperatureLow,
  faTimes,
  faTools,
  faUser,
  faUserSecret,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { isEqual, isPlainObject, some } from 'lodash';
import { getIn } from 'formik';
import { ISite } from '@wiot/shared-domain/models/settings/settings';
import moment from 'moment';
import { DeviceStatus, IColumnObject, ProtocolType } from '@wiot/shared-domain/models/device/device';
import { DeviceReadingSourceType } from '@wiot/shared-domain/models/device-reading/device-reading';
import { IDeviceType } from '@wiot/shared-domain/models/device-types/device-types';
import { FileLocation, IFileLocation } from '@wiot/shared-domain/models/file/file-location';
import { DeviceGroupType } from '@wiot/shared-domain/models/device-group-types/device-group-types';
import {
  getTranslationValueByLanguageCode,
  ITranslation,
  localCompareTranslations,
} from '@wiot/shared-domain/models/localization/translation';
import { FetchOptions } from '../state/types';
import store from '../state/store';
import {
  DEVICE_ROLE_DEFAULT_VALUES_WIOT,
  MESSAGE_ROLE_DEFAULT_VALUES_WIOT,
  ROLE_DEFAULT_VALUES_WIOT
} from '@wiot/shared-domain/models/role/role-default-values.wiot';
import {
  DEVICE_ROLE_DEFAULT_VALUES_KEY_MANAGER,
  ROLE_DEFAULT_VALUES_KEY_MANAGER
} from '@wiot/shared-domain/models/role/role-default-values.key-manager';
import QUESTION_MARK from '../assets/question-mark.svg';
import QUESTION_MARK_SMALL from '../assets/devices/question-mark-small.svg';
import { deviceGroupIconsBorder, IconVariant, keyManagerDeviceGroupIcons } from '../constants';
import { ReactText } from 'react';
import { IDuration } from '../components/Filter/durations';
import { getTranslate } from 'react-localize-redux';
import { DataValidator } from '@wiot/shared-domain/models/validators/data-validators';
import { IDeviceMessageFilter } from '../state/reducers/filterSortReducer';
import { LANGUAGE_KEY } from '../components/UserSettings/language-key';

export const isUndefined = (thing: unknown): boolean => typeof thing === 'undefined';

export type QueryMap = Record<string, any>;
export const buildQueryString = (queryMap: QueryMap): string => {
  const esc = encodeURIComponent;
  return Object.keys(queryMap)
    .filter((k) => !isUndefined(queryMap[k]))
    .map((k) => `${ esc(k) }=${ esc(queryMap[k]) }`)
    .join('&');
};

export const getDateRangeString = (partialFilters: Partial<IDeviceMessageFilter>): string => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { receivedAt_gte, receivedAt_lte, period } = partialFilters;
  let range;
  if (period) {
    const dateRange = periodToDateRange(period);
    range = {
      start: new Date(dateRange.startDate).toISOString(),
      end: new Date(dateRange.endDate).toISOString(),
    };
  } else {
    if (receivedAt_gte) {
      range = { start: new Date(receivedAt_gte).toISOString(), };
    }
    if (receivedAt_lte) {
      range = { ...range, stop: new Date(receivedAt_lte).toISOString(), };
    }
  }
  return JSON.stringify(range);
};

export const kebabToCamelCase = (kebabCaseString: string): string => {
  if (kebabCaseString === 'last-message') {
    return 'time';
  }
  if (kebabCaseString === 'device-group-type') {
    return 'type';
  }
  if (kebabCaseString.includes('-name')) {
    return 'name';
  }
  return kebabCaseString
    .split('-')
    .map((w: string, i: number) => {
      if (i === 0) {
        return w;
      }
      return w.charAt(0).toUpperCase() + w.slice(1);
    })
    .join('');
};

export const getFetchOptions = <T>(
  pageSize: number | undefined = undefined,
  column: IColumnObject | undefined | null = null,
  filter: T | undefined = undefined,
  page = 1,
  gateways: string | undefined = undefined,
): FetchOptions<T> => {
  const fetchOptions: FetchOptions<T> = {};
  fetchOptions.page = page;
  if (pageSize) {
    fetchOptions.pageSize = pageSize;
  }
  if (column) {
    fetchOptions.sort = JSON.stringify({
      // @ts-ignore check for undefined state of column exists
      [kebabToCamelCase(column.name)]: column.sort,
    });
  }
  if (filter) {
    fetchOptions.filters = filter;
  }
  if (gateways) {
    fetchOptions.gateways = gateways;
  }
  return fetchOptions;
};

export const getStatusIcon = (status: string): IconDefinition => {
  switch (status) {
    case DeviceStatus.NO_KEY:
      return faKey;
    case DeviceStatus.OFFLINE:
      return faPowerOff;
    case DeviceStatus.HANDLING_ERROR:
      return faUser;
    case DeviceStatus.MANIPULATION:
      return faUserSecret;
    case DeviceStatus.RTC_INVALID:
    case DeviceStatus.TIME_SYNC_FAILED:
      return faClock;
    case DeviceStatus.TEMPERATURE_ERROR:
    case DeviceStatus.NEGATIVE_TEMPERATURE_DIFF:
      return faTemperatureLow;
    case DeviceStatus.NEGATIVE_POWER:
    case DeviceStatus.VOLTAGE_DROP:
    case DeviceStatus.POWER_FAIL:
      return faBolt;
    case DeviceStatus.WRONG_FLOW_DIRECTION:
    case DeviceStatus.BACKFLUSH:
      return faBackspace;
    case DeviceStatus.OK:
      return faCheck;
    case DeviceStatus.RESERVED:
    case DeviceStatus.MANUFACTURER_SPECIFIC_ERROR:
      return faQuestionCircle;
    case DeviceStatus.APPLICATION_BUSY:
      return faHourglassHalf;
    case DeviceStatus.WARNING:
      return faExclamationTriangle;
    case DeviceStatus.POWER_LOW:
    case DeviceStatus.LOW_BATTERY:
    case DeviceStatus.CRITICAL_BATTERY:
    case DeviceStatus.BATTERY_DURATION_LESS_3_MONTH:
      return faBatteryQuarter;
    case DeviceStatus.PARSING_FAILED:
      return faGlasses;
    case DeviceStatus.NO_TELEGRAM_PARSED:
      return faCommentSlash;
    case DeviceStatus.END_OF_LIFE:
      return faHourglassEnd;
    case DeviceStatus.HARDWARE_ERROR:
      return faMicrochip;
    case DeviceStatus.SOFTWARE_ERROR:
    case DeviceStatus.RESET_ERROR:
    case DeviceStatus.TEMPORARY_ERROR:
    case DeviceStatus.APPLICATION_ERROR:
    case DeviceStatus.DOUBLET_FILTER_OVERFLOW:
      return faBug;
    case DeviceStatus.INVALID_MESSAGE_CONTENT:
      return faFileAlt;
    case DeviceStatus.INVALID_ENCRYPTION:
      return faLock;
    case DeviceStatus.INVALID_CONTROL_INFORMATION:
    case DeviceStatus.INVALID_COMMUNICATION_INDICATOR:
      return faInfo;
    case DeviceStatus.INVALID_COMMUNICATION_INDICATOR_IR:
      return faTools;
    default:
      return faTimes;
  }
};
export const localizeNumber = (numberFormat: number): string | 0 =>
  numberFormat && numberFormat.toLocaleString('de-DE');

/**
 * Gets a localized, textual representation of the specified date (date only).
 *
 * @param date The date to localize.
 * @param options Optional options for the localization.
 * @returns A localized, textual representation of the specified date.
 */
export const localizeDateOnly = (
  date?: string | number | Date,
  options?: Intl.DateTimeFormatOptions,
): string | 0 | undefined => date && new Date(date).toLocaleDateString('de-DE', options);

/**
 * Gets a localized, textual representation of the specified date (date and time).
 *
 * @param date The date (and time) to localize.
 * @param options Optional options for the localization.
 * @returns A localized, textual representation of the specified date and time.
 */
export const localizeDate = (
  date?: string | number | Date,
  options?: Intl.DateTimeFormatOptions,
): string | 0 | undefined => date && new Date(date).toLocaleString('de-DE', options);

export const localizeTime = (
  date: string | number | Date,
  options?: Intl.DateTimeFormatOptions,
): string | 0 | undefined => date && new Date(date).toLocaleTimeString('de-DE', options);

export const MIN_LENGTH_DIFF_TO_TRUNCATE = 2;
export const stringTruncateFromCenter = (str: string, maxLength: number) => {
  const shouldTruncate = (str.length - maxLength > MIN_LENGTH_DIFF_TO_TRUNCATE);
  if (!shouldTruncate) {
    return str;
  }
  const left = Math.ceil(maxLength / 2); // length of beginning part
  const right = str.length - Math.floor(maxLength / 2) + 1; // start index of ending part
  return `${ str.slice(0, left) }...${ str.slice(right) }`;
};

export const getSourceTypeName = (source: DeviceReadingSourceType | undefined): string => {
  let sourceTypeName: string;
  if (source && (source.includes('OMS') || source.includes('LORA'))) {
    sourceTypeName = `${ source }_GATEWAY`;
  } else {
    sourceTypeName = source || '';
  }
  return sourceTypeName;
};

export const getSelectedLanguage = (settings?: ISite): string => {
  const language = localStorage.getItem(LANGUAGE_KEY);
  if (language) return language;
  if (settings?.language) {
    return settings.language;
  }
  return 'de';
};

/**
 * Gets the translation value in the user selected language.
 *
 * @param translations The translations to get the translation value from.
 *
 * @returns The translation value in the user selected language.
 */
export function getTranslationValueInCurrentLanguage(translations: ITranslation[]): string {
  const languageCode = getSelectedLanguage();
  return getTranslationValueByLanguageCode(translations, languageCode);
}

/**
 * The method returns a number indicating whether a reference translations comes before, or after, or is the
 * same as the given compare translation in sort order.
 *
 * @param referenceTranslations The reference string against which the compareTranslations are compared.
 * @param compareTranslations The translations against which the referenceTranslations are compared.
 *
 * @returns A number indicating whether a reference string comes before, or after, or is the
 *          same as the given translations in sort order.
 */
export function localCompareTranslationsCurrentLanguage(
  referenceTranslations: ITranslation[],
  compareTranslations: ITranslation[],
): number {
  const languageCode = getSelectedLanguage();
  return localCompareTranslations(referenceTranslations, compareTranslations, languageCode);
}

export const getRoleSectionStructure = (groupPathArr: string[], groupKey: string, isKeyManagerModeEnabled: boolean): any => {
  if (isKeyManagerModeEnabled) {
    if (groupKey.startsWith('deviceType')) {
      const lastKey = groupPathArr[groupPathArr.length - 1];

      if (lastKey === 'devices') {
        return DEVICE_ROLE_DEFAULT_VALUES_KEY_MANAGER;
      }
      return getIn(DEVICE_ROLE_DEFAULT_VALUES_KEY_MANAGER, lastKey);
    }
    return getIn(ROLE_DEFAULT_VALUES_KEY_MANAGER, groupPathArr);
  }

  if (groupKey.startsWith('deviceType')) {
    const lastKey = groupPathArr[groupPathArr.length - 1];
    if (lastKey === 'messages') {
      return MESSAGE_ROLE_DEFAULT_VALUES_WIOT;
    }
    if (lastKey === 'devices') {
      return DEVICE_ROLE_DEFAULT_VALUES_WIOT;
    }
    return getIn(DEVICE_ROLE_DEFAULT_VALUES_WIOT, lastKey);
  }
  return getIn(ROLE_DEFAULT_VALUES_WIOT, groupPathArr);
};

const hasAnyPermissionInNested = (permissionObj: any, permissionKey: string) => {
  const nestedObj = getIn(permissionObj, permissionKey);
  if (permissionObj && isPlainObject(nestedObj)) {
    return some(Object.values(nestedObj), (o) => {
      if (isPlainObject(o)) {
        // @ts-ignore Check for object type is made
        return Object.values(o).includes(true);
      }
      return o;
    });
  }
  return false;
};

/**
 * @deprecated Don't use this method in the future. Especially not in any react component.
 *             You should always be able to inject the global state directly in a class and function component.
 *             For class components use the function `mapStateToProps` and for function components use `useSelector`.
 */
const isSuperAdmin = (): boolean | undefined =>
  store.getState().currentUser.permission?.superAdmin;

export const hasPermission = (
  permissionObj: any,
  permissionKey: string,
  nested?: boolean,
): boolean => {
  if (isSuperAdmin()) {
    return true;
  }

  if (!permissionObj) {
    return false;
  }

  if (nested) {
    return (
      permissionObj &&
      (permissionObj?.superAdmin || hasAnyPermissionInNested(permissionObj, permissionKey))
    );
  }
  return permissionObj && (permissionObj?.superAdmin || getIn(permissionObj, permissionKey));
};

export const isOffline = (): boolean => store.getState().isOffline;

export const setDocumentTitle = (title?: string): void => {
  if (title) {
    document.title = title;
  }
};

export const convertFile2image = (file: File): HTMLImageElement => {
  const imageEl = document.createElement('img');
  if (imageEl) {
    imageEl.src = URL.createObjectURL(file);
  }
  return imageEl;
};

export const convertImage2PNGdataURL = async (image: HTMLImageElement): Promise<string> => {
  await image.decode();
  const c = document.createElement('canvas');
  const ctx = c.getContext('2d');
  c.width = image.width;
  c.height = image.height;
  ctx?.drawImage(image, 0, 0);
  return c.toDataURL('image/png');
};

export const convertImage2JPGdataURL = async (image: HTMLImageElement): Promise<string> => {
  await image.decode();
  const c = document.createElement('canvas');
  const ctx = c.getContext('2d');
  c.width = image.width;
  c.height = image.height;
  ctx?.drawImage(image, 0, 0);
  return c.toDataURL('image/jpeg', 0.9);
};

export const convertDataURL2blob = (dataURL: string): Blob | null => {
  const arr = dataURL.split(',');

  if (
    arr?.length &&
    arr[0].match(/:(.*?);/) &&
    // @ts-ignore      arr[0].match(/:(.*?);/) is NOT null
    arr[0].match(/:(.*?);/).length > 1
  ) {
    // @ts-ignore    arr[0].match(/:(.*?);/)[1] is NOT null
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n) {
      n -= 1;
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }
  return null;
};

export const getObjectDiff = (obj1: Record<any, any>, obj2: Record<any, any>): string[] =>
  Object.keys(obj1).reduce((result, key) => {
    if (!obj2.hasOwnProperty(key)) {
      result.push(key);
    } else if (isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);
      result.splice(resultKeyIndex, 1);
    }
    return result;
  }, Object.keys(obj2));

export const randomString = (): string => Math.random().toString(36).substring(7);

/**
 * Gets the full path of the given icon.
 *
 * @param iconLocation The icon to get the full path for.
 * @param iconVariant The variant of the icon e.g. small, red, green etc..
 *
 * @returns The full path of the given icon.
 */
export const getIconFullPath = (
  iconLocation: IFileLocation | undefined,
  iconVariant: IconVariant | string = IconVariant.default,
): string => {
  let questionMarkVariant: string;
  switch (iconVariant) {
    case IconVariant.small:
      questionMarkVariant = QUESTION_MARK_SMALL;
      break;
    default:
      questionMarkVariant = QUESTION_MARK;
  }

  return iconLocation ? FileLocation.getFullPath(iconLocation, iconVariant) : questionMarkVariant;
};

/**
 * Gets the full path of the given device type icon.
 *
 * @param deviceType The device type to get the full path for.
 * @param iconVariant The variant of the icon e.g. small, red, green etc..
 *
 * @returns The full path of the given device type icon.
 */
export const getDeviceTypeIconFullPath = (
  deviceType?: IDeviceType | null,
  iconVariant: IconVariant | string = IconVariant.default,
): string => getIconFullPath(deviceType?.iconLocation, iconVariant);

export const getDetailedGatewayIconSource = (
  protocolType: ProtocolType | undefined,
  deviceType: IDeviceType | undefined,
  iconVariant: IconVariant | string = IconVariant.default,
): string | null => {
  if (protocolType === ProtocolType.OMS_GATEWAY) {
    return getDeviceTypeIconFullPath(deviceType, `-oms${ iconVariant }`);
  }
  if (protocolType === ProtocolType.LORA_GATEWAY) {
    return getDeviceTypeIconFullPath(deviceType, `-lora${ iconVariant }`);
  }

  return null;
};

/**
 * Gets the full path of the given device type icon by the given device statuses.
 *
 * @param deviceType The device type to get the full path for.
 * @param deviceStatuses The device statuses to evaluate the icon variant from.
 *
 * @returns The full path of the given device type icon.
 */
export const getDeviceTypeIconFullPathByStatuses = (
  deviceType: IDeviceType,
  deviceStatuses?: DeviceStatus[],
): string => {
  if (!deviceStatuses) {
    return getDeviceTypeIconFullPath(deviceType);
  }

  if (deviceStatuses.includes(DeviceStatus.OK)) {
    return getDeviceTypeIconFullPath(deviceType, IconVariant.largeWithBorderBackgroundGreen);
  }

  return getDeviceTypeIconFullPath(deviceType, IconVariant.largeWithBorderBackgroundRed);
};

/**
 * Gets the full path of the given device group type icon.
 *
 * @param deviceGroupType The device group type to get the full path for.
 * @param iconVariant The variant of the icon e.g. small, red, green etc..
 *
 * @returns The full path of the given device group type icon.
 */
export const getDeviceGroupTypeIconFullPath = (
  deviceGroupType?: DeviceGroupType,
  iconVariant: IconVariant = IconVariant.default,
): string => getIconFullPath(deviceGroupType?.iconLocation, iconVariant);

/**
 * Gets the icon path of the given note type in a variant evaluated from the given device statuses.
 *
 * @param nodeType The node type to get the icon path for.
 * @param deviceStatuses The device statuses to evaluate the icon variant from.
 *
 * @returns The icon path of the given note type in a variant evaluated from the given device statuses.
 */
export const getIconPathFromNodeType = (
  nodeType: string,
  deviceStatuses?: DeviceStatus[],
  isKeyManagerModeEnabled = false,
): string => {
  let iconSrc = nodeType;

  if (iconSrc && deviceStatuses) {
    if (deviceStatuses.includes(DeviceStatus.OK)) {
      iconSrc += '_GREEN';
    } else {
      iconSrc += '_RED';
    }
  }

  if (!iconSrc) {
    return deviceGroupIconsBorder['2'];
  }

  if (isKeyManagerModeEnabled) {
    return keyManagerDeviceGroupIcons[iconSrc];
  }

  return deviceGroupIconsBorder[iconSrc];
};

/*
 * Convert the range in the period format like last-24-h, last-7-days to start date and end date
 * Returns an object containing startDate and endDate
 * @param period - IDuration
 */
export const periodToDateRange = (period: IDuration): { startDate: number; endDate: number } => {
  const optionArr = period.split('-');
  const amount = Number(optionArr[1]);
  const unit = optionArr[2];
  // @ts-ignore the IDuration type asserts that the unit is in the accepted format
  const startDate = moment().subtract(amount, unit).toDate().setHours(0, 0, 0, 0);
  const endDate = new Date().setHours(23, 59, 59, 999);
  return {
    startDate,
    endDate,
  };
};

/**
 * Validates geo latitude value and returns an error message if it is invalid
 * The validation only happens if there is a value(to allow the field to be optional)
 * valid latitude value should range between -90 to 90
 * Ref: https://stackoverflow.com/a/7780993/3867205
 * @param value - string
 * @returns - string
 */
export const validateLatitude = (value: string | number) => {
  const translate = getTranslate(store.getState().localize);
  const parsedValue = typeof value === 'string' ? parseFloat(value) : value;
  if (value && !(Number.isFinite(parsedValue) && parsedValue >= -90 && parsedValue <= 90)) {
    return translate('invalid-latitude').toString();
  }
  return;
};

/**
 * Validates geo longitude value and returns an error message if it is invalid
 * The validation only happens if there is a value(to allow the field to be optional)
 * Valid longitude value should range between -180 to 180
 * Ref: https://stackoverflow.com/a/7780993/3867205
 * @param value - string
 * @returns - string
 */
export const validateLongitude = (value: string | number) => {
  const translate = getTranslate(store.getState().localize);
  const parsedValue = typeof value === 'string' ? parseFloat(value) : value;
  if (value && !(Number.isFinite(parsedValue) && parsedValue >= -180 && parsedValue <= 180)) {
    return translate('invalid-longitude').toString();
  }
  return;
};

/**
 * Validates if the value exists and returns an error message otherwise
 * @param value - any value
 * @returns - string
 */
export const validateRequiredFieldValue = (
  value: string | number | boolean | undefined,
): string | undefined => {
  if (typeof value === 'number' || value) {
    return;
  }

  const translate = getTranslate(store.getState().localize);
  return translate('required-field').toString();
};

/**
 * Validates if the value exists and it is a valid email. Returns an error message otherwise
 * @param value - string
 * @returns - string
 */
export const validateEmail = (value: string) => {
  const translate = getTranslate(store.getState().localize);
  const validEmail = DataValidator.validateEmail(value);
  if (!value || !validEmail) {
    return translate('invalid-email').toString();
  }
  return;
};

export const getValueWithUnitIfAvailable = (value: string | number | ReactText[], unit = '') => {
  if (typeof value === 'number' && isNaN(value)) {
    const translate = getTranslate(store.getState().localize);

    return translate('not-available');
  } else {
    return `${ Number(value)?.toFixed(2) } ${ unit }`;
  }
};
