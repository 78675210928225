import React, { useState } from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import ProcessStep from './ProcessStep';
import { AppState } from '../../state/reducers/rootReducer';
import { showOrHideAllKeys, showOrHideDeviceKey } from '../../state/device-key/deviceKeysActionCreators';
import DownloadOmsXmlModal from '../Modal/DownloadOmsXmlModal';
import { CustomFieldAdvancedInput } from '../Table/CustomFieldAdvancedInput';
import { fetchDeviceKeysAsFile, resetDeviceKeysFile, } from '../../state/device-key-download/fetchDeviceKeysAsFileActionCreator';
import { DeviceKeyExportFileFormat } from '@wiot/shared-domain/models/device-key/device-keys-export-file-format';
import PrimaryButton from '../shared/PrimaryButton';


function DownloadStep(props: { downloadHash: string }) {
  const dispatch = useDispatch();
  const { downloadHash } = props;
  const downloadDetails = useSelector(
    (state: AppState) => state.deviceKeyProtection.downloadDetails.downloadDetails,
  );
  const protectionDetails = useSelector(
    (state: AppState) => state.deviceKeyProtection.protectionDetails.protectionDetails,
  );
  const deviceKeys = downloadDetails ? downloadDetails.deviceKeys : [];
  const areAllKeyVisible = deviceKeys.every((key) => key.isVisible);
  const [ showDownloadModal, setShowDownloadModal ] = useState(false);

  const createDownloadLink = (fileContent: Blob, fileFormat: DeviceKeyExportFileFormat) => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(new Blob([ fileContent ]));
    link.download = `${ downloadHash }_deviceKeys.${ fileFormat.toString() }`;
    link.click();
    link.parentNode?.removeChild(link);
  };

  const deviceKeysAsFile = useSelector((state: AppState) => state.deviceKeyProtection.deviceKeysAsFile);
  if (deviceKeysAsFile) {
    createDownloadLink(deviceKeysAsFile.fileContent, deviceKeysAsFile.fileFormat);
    dispatch(resetDeviceKeysFile());
  }

  return (
    <ProcessStep isActive={ protectionDetails.hasCurrentUserAccessToKey } titleTranslationId="download" isLastStep>
      <>
        <div className="mb-4 option-list">
          <PrimaryButton
            icon={ faArrowDown }
            iconSize="lg"
            classNames="device-key-download-btn py-1"
            textTranslationId="device-key-download_download-all-as-oms-file"
            onClick={ () => setShowDownloadModal(true) }
          />

          <PrimaryButton
            icon={ faArrowDown }
            iconSize="lg"
            classNames="device-key-download-btn py-1"
            textTranslationId="device-key-download_download-all-as-csv-file"
            onClick={ () => dispatch(fetchDeviceKeysAsFile(downloadHash, DeviceKeyExportFileFormat.CSV)) }
          />

          <button
            className="secondary-button py-1"
            onClick={ () => {
              dispatch(showOrHideAllKeys(areAllKeyVisible));
            } }
          >
            <FontAwesomeIcon
              icon={ areAllKeyVisible ? faEyeSlash : faEye }
              size="lg"
              className="mr-2"
            />
            <Translate id="show-hide-all"/>
          </button>
        </div>

        { deviceKeys.map((deviceKey, index) => (
          <div key={ deviceKey.deviceSerialNumber } className="mb-3 ml-3">
            <div className="mb-1">
              <b>{ deviceKey.deviceName }</b>
              &nbsp; (<Translate id="device"/>: { deviceKey.keyName })
            </div>
            <Formik initialValues={ {} } onSubmit={ () => true }>
              { ({ handleSubmit }) => (
                <Form onSubmit={ handleSubmit }>
                  <CustomFieldAdvancedInput
                    type="password"
                    fieldName={ deviceKey.deviceName! }
                    showCopyButton
                    showEyeButton
                    className="device-key"
                    value={ deviceKey.keyValue }
                    initialIsValueVisible={ deviceKey.isVisible }
                    readOnly
                    onEyeButtonClick={ () => dispatch(showOrHideDeviceKey(index)) }
                  />
                </Form>
              ) }
            </Formik>
          </div>
        )) }
      </>

      <DownloadOmsXmlModal
        show={ showDownloadModal }
        cancel={ () => setShowDownloadModal(false) }
        clickDownload={ (encryptionKey) => {
          dispatch(fetchDeviceKeysAsFile(downloadHash, DeviceKeyExportFileFormat.XML, encryptionKey));
        } }
      />
    </ProcessStep>
  );
}

export default DownloadStep;
