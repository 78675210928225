import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Translate } from 'react-localize-redux';
import { createWorker } from 'tesseract.js';
import RenderOnCondition from '../RenderOnCondition';
import Thumbnails from '../Thumbnails/Thumbnails';

interface CustomImageFileInputProps {
  onFilesUpdated: (files: (string | File)[]) => void;
  onFilesRemoved?: (files: (string | File)[]) => void;
  files: (string | File)[];
  toggleGallery?: (index: number) => void;
  toggleOcrLoading?: () => void;
  toggleOcrWizard?: () => void;
  isOcrLoading?: boolean;
  setCurrentFile?: (file: string) => void;
  setOcrFoundData?: (foundData: string[]) => void;
}

const CustomImageFileInput = ({
  onFilesUpdated,
  onFilesRemoved,
  files,
  toggleGallery,
  toggleOcrWizard,
  toggleOcrLoading,
  isOcrLoading,
  setCurrentFile,
  setOcrFoundData,
}: CustomImageFileInputProps) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (droppedFiles) => {
      onFilesUpdated([...files, ...droppedFiles]);
    },
  });

  const removeFile = (index: number) => {
    const newFiles = [...files];
    const removedFiles = newFiles.splice(index, 1);
    onFilesRemoved && onFilesRemoved(removedFiles);
    onFilesUpdated(newFiles);
  };

  const doOCR = async (index: number, path: string) => {
    const worker = createWorker({
      // logger: m => console.log(m),                                                 // will be implemented to make tesseract work offline
      // workerPath: '../../../node_modules/tesseract.js/dist/worker.min.js',
      // langPath: '../lang-data',
      // corePath: '../../../node_modules/tesseract.js-core/tesseract-core.wasm.js',
    });
    await worker.load();
    await worker.loadLanguage('eng');
    await worker.initialize('eng');
    const {
      data: { text },
    } = await worker.recognize(path);
    const matches = text.match(/[0-9]{7,}/gm) || [];
    await worker.terminate();
    setOcrFoundData && setOcrFoundData(matches);
    toggleOcrLoading && toggleOcrLoading();
  };

  return (
    <div style={{ flex: 1 }}>
      <div className="form__label">
        <Translate id="files" />
      </div>
      <div {...getRootProps({ className: 'dropzone-root' })}>
        <Translate id="choose-files" />
        ...
        <input {...getInputProps()} />
      </div>
      <RenderOnCondition condition={files?.length}>
        <Thumbnails
          files={files}
          toggleGallery={toggleGallery}
          removeFile={onFilesRemoved && removeFile}
          ocrFile={toggleOcrWizard && doOCR}
          toggleOcrLoading={toggleOcrLoading}
          toggleOcrWizard={toggleOcrWizard}
          isOcrLoading={isOcrLoading}
          setCurrentFile={setCurrentFile}
        />
      </RenderOnCondition>
    </div>
  );
};

export default CustomImageFileInput;
