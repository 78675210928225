import { faEye, faEyeSlash, faPencilAlt, faSignOutAlt, IconDefinition, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage, Form, Formik } from 'formik';
import * as React from 'react';
import {
  LocalizeContextProps,
  LocalizedElement,
  LocalizedElementMap,
  Translate,
  TranslateOptions,
  TranslatePlaceholderData,
  TranslateValue,
  withLocalize,
} from 'react-localize-redux';
import onClickOutside from 'react-onclickoutside';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Form as BootstrapForm } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IUser } from '@wiot/shared-domain/models/user/user';
import { AggregatedPermission } from '@wiot/shared-domain/models/role/role';
import RenderOnCondition from '../RenderOnCondition';
import ModalHeader from '../Modal/ModalHeader';
import CustomCheckbox from '../shared/CustomCheckbox';
import { fetchMyUserFromDB, updateUserInDB } from '../../api/apiHelpers';
import { setCurrentUser } from '../../state/actions/setCurrentUserAction';
import { AppState } from '../../state/reducers/rootReducer';
import { CustomFieldAdvancedInput } from '../Table/CustomFieldAdvancedInput';
import LanguageToggle from './LanguageToggle';
import SettingsToggle from './SettingsToggle';
import HasPermission from '../HasPermission';
import OfflineModeToggle from './OfflineModeToggle';
import { logout } from '../../state/actions/logoutActionCreator';
import { getInitialValuesFromUser, IUserActionModalFormValues, } from '../../pages/UserManagement/UserActionModal/UserForm';
import { redirectToPath } from '../../utils/windowLocation';
import { LOGIN_PATH } from '../../navigation/paths';
import { DataValidator } from '@wiot/shared-domain/models/validators/data-validators';
import { UserRole } from '@wiot/shared-domain/models/user/user-role';
import { Tooltip } from '../shared/Tooltip';

interface UserSettingsProps extends LocalizeContextProps, RouteComponentProps {
  closeUserSettings: () => void;
  toggleOfflineState: () => void;
  setCurrentUser: (object: IUser) => void;
  logout: () => Promise<void>;
  currentUser: IUser | undefined;
  permission?: AggregatedPermission;
  isOffline: boolean;
  isKeyManagerModeEnabled: boolean;
}

interface IUserSettingsState {
  userData: IUser;
  userRoles: UserRole[];
  showAdvancedSettings: boolean;
}

class UserSettings extends React.Component<UserSettingsProps, IUserSettingsState> {
  constructor(props: UserSettingsProps) {
    super(props);
    this.state = {
      userData: {
        firstName: '',
        lastName: '',
        email: '',
        street: '',
        city: '',
        // @ts-ignore
        newPassword: '',
        currentPassword: '',
        settings: {
          display: {
            showFullTimestamp: false,
            persistPageState: false,
          },
        },
      },
      userRoles: [],
      showAdvancedSettings: false,
    };
  }

  componentDidMount = async () => {
    try {
      const userDetails = await fetchMyUserFromDB();

      this.setState((prevState) => ({
        userRoles: {
          ...prevState.userRoles,
          ...userDetails.user.roles,
        },
        userData: {
          ...prevState.userData,
          ...userDetails.user,
        },
      }));
    } catch (e) {
      console.error(e);
    }
  };

  handleClickOutside = () => {
    this.props.closeUserSettings();
  };

  handleLogoutClick = async () => {
    await this.props.logout();
    redirectToPath(LOGIN_PATH);
  };

  handleSubmit = async (values: IUserActionModalFormValues) => {
    try {
      const { currentPassword: password, userRoles, isAdmin, ...validUserProperties } = values;
      const userData: IUser = { password, ...validUserProperties };

      const response: any = await updateUserInDB(userData);
      const { user: updatedUser } = response;
      this.props.setCurrentUser(updatedUser);
      this.props.closeUserSettings();
      toastr.success(
        this.props.translate('settings').toString(),
        this.props.translate('settings-changed').toString(),
      );
    } catch (e) {
      console.error(e);
    }
  };

  handleToggleClick = () => {
    this.setState((prevState) => ({ showAdvancedSettings: !prevState.showAdvancedSettings }));
  };

  private static getValidationSchema(
    translate: (
      value: TranslateValue,
      data?: TranslatePlaceholderData,
      options?: TranslateOptions,
    ) => LocalizedElement | LocalizedElementMap,
  ) {
    return Yup.object().shape({
      email: Yup.string()
        .email(translate('invalid-email').toString())
        .required(translate('required-field').toString()),
      newPassword: Yup.string().matches(
        DataValidator.PASSWORD_REGEX,
        {
          message: translate('login-password-indicator-requirements').toString(),
        },
      ),
      currentPassword: Yup.string().when('newPassword', {
        is: (val) => !!val,
        then: Yup.string().required(translate('required-field').toString()),
      }),
    });
  }

  render() {
    const { showAdvancedSettings } = this.state;
    const { translate, isKeyManagerModeEnabled } = this.props;
    return (
      <div id="user-settings-modal" className="user-settings" data-testid="user-settings">
        <div className="user-settings__mobile-container">
          <ModalHeader
            handleClose={ this.props.closeUserSettings }
            titleTranslationId="user-settings"
            targetId="modal"
            changeOnlyZindex
          />
          <div className="d-flex justify-content-between mb-3">
            <button
              className="header__button header__button-logout"
              onClick={ this.handleLogoutClick }
              data-testid="user-settings-logout"
            >
              <FontAwesomeIcon
                icon={ faSignOutAlt }
                size="lg"
                className="settings-form__logout--icon"
              />
              <Translate id="logout"/>
            </button>
            <LanguageToggle/>
          </div>
          <RenderOnCondition condition={ !isKeyManagerModeEnabled }>
            <div className="d-flex justify-content-between mb-2">
              <OfflineModeToggle
                handleClick={ this.props.toggleOfflineState }
                isOffline={ this.props.isOffline }
              />
            </div>
          </RenderOnCondition>
          <div className="d-flex justify-content-between mb-3">
            <SettingsToggle handleClick={ this.handleToggleClick } isActive={ showAdvancedSettings }/>
          </div>
          <Formik
            initialValues={ getInitialValuesFromUser(this.state.userData, []) }
            validationSchema={ UserSettings.getValidationSchema(translate) }
            enableReinitialize
            onSubmit={ this.handleSubmit }
            render={ ({ isValid, values, handleChange }) => (
              <Form
                className="settings-form"
                onClick={ (event: React.MouseEvent) => event.stopPropagation() }
              >
                <RenderOnCondition condition={ !showAdvancedSettings }>
                  <HasPermission
                    permissionObj={ this.props.permission }
                    permissionKey="profile.changeAddress"
                  >
                    <div className="settings-form__box">
                      <CustomFieldAdvancedInput
                        fieldName="firstName"
                        type="text"
                        icon={ faPencilAlt }
                        translationId="first-name"
                        value={ values.firstName }
                      />
                      <CustomFieldAdvancedInput
                        fieldName="lastName"
                        type="text"
                        icon={ faPencilAlt }
                        translationId="last-name"
                        value={ values.lastName }
                      />
                      <CustomFieldAdvancedInput
                        fieldName="street"
                        type="text"
                        icon={ faPencilAlt }
                        translationId="street"
                        value={ values.street }
                      />
                      <CustomFieldAdvancedInput
                        fieldName="city"
                        type="text"
                        icon={ faPencilAlt }
                        translationId="city"
                        value={ values.city }
                      />
                      <CustomFieldAdvancedInput
                        fieldName="email"
                        type="text"
                        icon={ faPencilAlt }
                        translationId="email"
                        value={ values.email }
                        readOnly
                      />
                      <ErrorMessage name="email" component="div" className="input-error"/>
                    </div>
                  </HasPermission>

                  <HasPermission
                    permissionObj={ this.props.permission }
                    permissionKey="profile.changePassword"
                  >
                    <div className="settings-form__box">
                      <h4 data-testid="settings-heading" className="settings-form__heading">
                        <Translate id="change-password"/>
                      </h4>
                      <CustomFieldAdvancedInput
                        fieldName="newPassword"
                        type="password"
                        translationId="new-password"
                        autoComplete="new-password"

                        showEyeButton

                        // @ts-ignore
                        value={ values.newPassword }
                      />
                      <ErrorMessage name="newPassword" component="div" className="input-error"/>
                    </div>

                    <div className="settings-form__box">
                      <CustomFieldAdvancedInput
                        fieldName="currentPassword"
                        type="password"
                        translationId="current-password"

                        showEyeButton

                        // @ts-ignore
                        value={ values.currentPassword }
                      />
                      <ErrorMessage name="currentPassword" component="div" className="input-error"/>
                    </div>
                  </HasPermission>
                </RenderOnCondition>
                <RenderOnCondition condition={ showAdvancedSettings }>
                  <h4 data-testid="settings-heading" className="settings-form__heading">
                    <Translate id="change-display-settings"/>
                  </h4>
                  <RenderOnCondition condition={ !isKeyManagerModeEnabled }>
                    <div className="settings-form__box settings-form__input-row">
                      <BootstrapForm.Label
                        className="justify-content-between d-flex standard-font-size"
                        data-tip="settings.display.showFullTimestamp"
                        data-for="settings.display.showFullTimestamp"
                      >
                        <Tooltip
                          id="settings.display.showFullTimestamp"
                          place="bottom"
                        >
                          <Translate id="show-full-timestamp-setting-desc"/>
                        </Tooltip>
                        <CustomCheckbox
                          name="settings.display.showFullTimestamp"
                          onChange={ (event) => handleChange(event) }
                          checked={ values.settings?.display?.showFullTimestamp }
                        />
                        { translate('show-full-timestamp-setting') }
                      </BootstrapForm.Label>
                    </div>
                  </RenderOnCondition>
                  <div className="settings-form__box settings-form__input-row">
                    <BootstrapForm.Label
                      className="justify-content-between d-flex standard-font-size"
                      data-tip="settings.display.persistPageState"
                      data-for="settings.display.persistPageState"
                    >
                      <Tooltip
                        id="settings.display.persistPageState"
                        place="bottom"
                      >
                        <Translate id="persist-page-state-setting-desc"/>
                      </Tooltip>
                      <CustomCheckbox
                        name="settings.display.persistPageState"
                        onChange={ (event) => handleChange(event) }
                        checked={ values.settings?.display?.persistPageState }
                      />
                      { translate('persist-page-state-setting') }
                    </BootstrapForm.Label>
                  </div>
                </RenderOnCondition>

                <div className="settings-form__row">
                  <button
                    type="button"
                    data-testid="settings-cancel"
                    onClick={ this.props.closeUserSettings }
                    className="form__button--cancel"
                  >
                    <Translate id="cancel"/>
                  </button>
                  <HasPermission
                    permissionObj={ this.props.permission }
                    permissionKey="profile"
                    nested
                  >
                    <button
                      className={ `form__button ${
                        isValid
                          ? 'form__button--blue background-color-main text-color-white border-color-main'
                          : ''
                      }` }
                      type="submit"
                      data-testid="settings-submit"
                    >
                      <Translate id="save"/>
                    </button>
                  </HasPermission>
                </div>
              </Form>
            ) }
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  currentUser: state.currentUser.user,
  permission: state.currentUser.permission,
  isOffline: state.isOffline,
  isKeyManagerModeEnabled: !!state.siteSettings.isKeyManagerModeEnabled,
});

const mapDispatchToProps = (dispatch: any) => ({
  setCurrentUser: (userDetails: IUser) => dispatch(setCurrentUser(userDetails)),
  logout: () => dispatch(logout()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(withRouter(onClickOutside(UserSettings))));
