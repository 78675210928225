import React, { useEffect, useState } from 'react';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Ancestor, DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';
import { AppState } from '../../state/reducers/rootReducer';
import { fetchDeviceGroupFromDB, fetchDeviceGroupsFromDB } from '../../api/apiHelpers';
import { DeviceGroupExtended, IModalZindex, SetFieldValue } from '../../state/types';
import { OutsideClickHandler } from '../OutsideClickHandler';
import RenderOnCondition from '../RenderOnCondition';
import TableLoadingIcon from './TableLoadingIcon';
import Portal from './Portal';
import CustomCheckbox from './CustomCheckbox';
import classNames from 'classnames';
import { GROUP_PATH_DELIMITER } from '../../utils/device-group-path-helper';
import { ExpandIcon } from '../FilterBar/select/components/ExpandIcon';
import { VIRTUAL_ROOT_DEVICE_GROUP_ID } from '@wiot/shared-domain/domain/device-tree/virtual-root-device-group-id';
import { includesCaseInsensitive } from '../../utils/string';

export interface GroupSelectProps extends LocalizeContextProps {
  handleDeviceGroupChange?: (
    selectedGroup: DeviceGroupExtended | string,
    setFieldValue: SetFieldValue,
    userRolesIndex?: number,
  ) => void;
  setFieldValue: SetFieldValue;
  selectedOption: string;
  rootIdOfRestrictedSubTree?: string;
  userRolesIndex?: number;
  translateString?: string;
  setGroupId?: boolean;
  maxHeight?: number;
  error?: string;
  touched?: boolean;
  disabled?: boolean;
  hideLabel?: boolean;
  targetId?: string;
  reduxZindex: IModalZindex;
  required?: boolean;
  dropDownWrapperClassNames?: string;
}

const GroupSelect = ({
  handleDeviceGroupChange,
  setFieldValue,
  selectedOption,
  rootIdOfRestrictedSubTree,
  translateString,
  translate,
  setGroupId,
  maxHeight,
  userRolesIndex,
  error,
  touched,
  disabled,
  hideLabel,
  targetId = '',
  reduxZindex,
  required = false,
  dropDownWrapperClassNames,
}: GroupSelectProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isReadonlyVirtualRoot, setIsReadonlyVirtualRoot] = useState(false);
  const [deviceGroups, setDeviceGroups] = useState<DeviceGroup[]>([]);
  const [viewingOption, setViewingOption] = useState<DeviceGroupExtended[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTree, setSelectedTree] = useState<DeviceGroupExtended[]>([]);
  const [isPathLoading, setIsPathLoading] = useState<boolean>(false);
  const [isGroupsLoading, setIsGroupsLoading] = useState<boolean>(false);

  const toggleDropdownOpen = () => {
    if (!disabled && !isReadonlyVirtualRoot) {
      setIsExpanded(!isExpanded);
    }
  };


  const fetchAvailableDeviceGroups = async () => {
    try {
      setIsGroupsLoading(true);
      const { deviceGroups: deviceGroupsRes } = await fetchDeviceGroupsFromDB(false, undefined, false);
      setIsGroupsLoading(false);
      setDeviceGroups(deviceGroupsRes);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchSelectedDeviceGroup = async () => {
    try {
      setIsPathLoading(true);

      const deviceGroup = await fetchDeviceGroupFromDB(selectedOption, false, true);
      setIsPathLoading(false);

      const { ancestors } = deviceGroup;
      const hasAncestors = ancestors && ancestors.length > 0
      if (hasAncestors) {
        setViewingOption(ancestors);
        const lastAncestor: Ancestor = ancestors[ancestors.length - 1];
        const lastAncestorDeviceGroup = await fetchDeviceGroupFromDB(lastAncestor.id!, false, true);
        setDeviceGroups(lastAncestorDeviceGroup.children!);
        setSelectedTree([...ancestors, deviceGroup]);
      } else {
        setDeviceGroups([deviceGroup]);
        setSelectedTree([deviceGroup]);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect( () => {
    if (!selectedOption) {
      fetchAvailableDeviceGroups().then(r => true);
      return;
    }

    if (selectedOption === VIRTUAL_ROOT_DEVICE_GROUP_ID) {
      setIsReadonlyVirtualRoot(true);
      setSelectedTree([{name:'-'}]);
      return;
    }

    fetchSelectedDeviceGroup().then(r => true)
  }, [selectedOption]);

  const handleOptionSelect = (
    event: React.MouseEvent<HTMLInputElement>,
    group: DeviceGroupExtended,
  ) => {
    event.stopPropagation();
    setIsExpanded(false);
    setSelectedTree([...viewingOption, group]);
    handleDeviceGroupChange &&
    handleDeviceGroupChange(setGroupId ? group.id! : group, setFieldValue, userRolesIndex);
  };

  const handleStepInto = async (group: DeviceGroupExtended) => {
    if (!group.hasChildren || !isAllowedToSelect(group.id!)) {
      return;
    }

    try {
      const deviceGroup = await fetchDeviceGroupFromDB(group.id!, false);
      if (deviceGroup.hasChildren) {
        setViewingOption([...viewingOption, group]);
        setDeviceGroups(deviceGroup.children!);
        setSearchTerm('');
      }
    } catch (e) {
      console.error(e);
    }
  };


  const handleStepOut = async (index: number, event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    try {
      let deviceGroups: DeviceGroup[];
      if (index === 0) {
        const firstLevelDeviceGroups = await fetchDeviceGroupsFromDB(false, undefined, false);
        deviceGroups = firstLevelDeviceGroups.deviceGroups;
      } else {
        const idOfPreviousDeviceGroup = viewingOption[index - 1].id!;
        const previousDeviceGroup = await fetchDeviceGroupFromDB(idOfPreviousDeviceGroup, false);
        deviceGroups = [previousDeviceGroup];
      }

      if (deviceGroups.length > 0 && deviceGroups[0].hasChildren) {
        setViewingOption(viewingOption.slice(0, index));
        setDeviceGroups(index === 0 ? deviceGroups : deviceGroups[0].children!);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const filterOptions = (event: React.FormEvent<any>) => {
    setSearchTerm(event.currentTarget.value);
  };

  const getIsOptionChecked = (id: string) => selectedOption === id;

  const getSelectedTree = () => selectedTree?.map((ancestor) => ancestor.name).join(GROUP_PATH_DELIMITER);

  const getBreadCrumb = () => {
    const options = [{ name: translate('root-group') }, ...viewingOption];
    return options.map((group, index) => {
      if (index < options.length - 1) {
        return (
          <a key={ index } href="#" onClick={ (e) => handleStepOut(index, e) }>
            { ' ' }
            { index !== 0 && '›' } { group.name }
          </a>
        );
      }
      return (
        <span key={ index }>
          { ' ' }
          { index !== 0 && '›' } { group.name }
        </span>
      );
    });
  };

  const getSearchPlaceHolder = () => {
    const arrayLength = viewingOption?.length;
    return `🔍 ${ translate('search-in', {
      groupName: viewingOption[arrayLength - 1]?.name,
    })?.toString() }`;
  };

  const getFilteredDeviceGroups = () => {
    if (searchTerm) {
      return deviceGroups.filter((group: DeviceGroupExtended) => group && includesCaseInsensitive(group.name, searchTerm));
    }
    return deviceGroups;
  };

  const isAllowedToSelect = (deviceGroupId: string) => {
    const isRestrictedDeviceGroup = !!rootIdOfRestrictedSubTree && rootIdOfRestrictedSubTree === deviceGroupId;
    return !isRestrictedDeviceGroup;
  };

  const getRenderedOptions = () => {
    if (!deviceGroups) {
      return null;
    }

    return getFilteredDeviceGroups().map((group: DeviceGroupExtended, index) => {
      const isAllowedToSelectGivenGroup: boolean = isAllowedToSelect(group.id!);
      const isCheckBoxVisible = isAllowedToSelectGivenGroup;
      const isChildrenLinkVisible = isAllowedToSelectGivenGroup && group.hasChildren;
      return (
        <div key={ group.id! + index } className="multiselect_option_wrapper">
          <div className="multiselect_option_content">
            <div className="multiselect_option_checkbox">
              <RenderOnCondition condition={ isCheckBoxVisible }>
                <CustomCheckbox
                  readOnly
                  onClick={ (event: React.MouseEvent<HTMLInputElement>) => {
                    handleOptionSelect(event, group);
                  } }
                  checked={ getIsOptionChecked(group.id!) }
                />
              </RenderOnCondition>
            </div>
            <div className="multiselect_option_label" onClick={ () => handleStepInto(group) }>
              { group.name }
              <div className="group-select__dropdown-indicator">
                { isChildrenLinkVisible &&
                    <FontAwesomeIcon className="group-select__dropdown-icon" icon={ faAngleRight }/>
                }
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  const handleClickOutside = () => {
    setIsExpanded(false);
  };

  const getGroupSelectPosition = () => {
    const selectEl = document.getElementById(targetId);
    const selectBoundary = selectEl?.getBoundingClientRect();
    return {
      top: (selectBoundary?.bottom || 0) + window.pageYOffset,
      right: document.body.clientWidth - (selectBoundary?.right || 0) + window.pageXOffset,
      zIndex: reduxZindex?.zIndex + 1,
    };
  };

  return (
    <div className="form__label">
      <RenderOnCondition condition={ !hideLabel }>
        <label className={ required ? 'required' : '' }>
          <Translate id={ translateString || 'choose-group' } />
        </label>
      </RenderOnCondition>
      <div className="group-select">

      <div
        id={ targetId }
        className={ `
          group-select__control
          ${ isExpanded ? 'group-select__control--menu-is-open' : '' } 
          ${ disabled || isReadonlyVirtualRoot ? 'group-select-disabled' : '' }
        ` }
        onClick={ () => !isPathLoading && !isGroupsLoading && toggleDropdownOpen() }
        tabIndex={ 0 }
      >
        <div className="group-select__label">

        <RenderOnCondition condition={ isPathLoading || isGroupsLoading }>
          <TableLoadingIcon groupLoading size={30} />
        </RenderOnCondition>
        <RenderOnCondition condition={ !isPathLoading && !isGroupsLoading }>
          <span>{ selectedTree && selectedOption ? getSelectedTree() : <Translate id="choose-one" /> }</span>
        </RenderOnCondition>

        </div>

        { !disabled && !isReadonlyVirtualRoot && (
          <div className="group-select__dropdown-indicator">
            <ExpandIcon isExpanded={ isExpanded } className="group-select__dropdown-icon"/>
          </div>
        )}

      </div>
      { isExpanded && !disabled && (
        <>

        <OutsideClickHandler onClickOutside={ handleClickOutside }>
          <Portal>
            <div
              style={ getGroupSelectPosition() }
              className={ classNames('multiselect_dropdown_wrapper', dropDownWrapperClassNames || 'multiselect_group-select') }
            >
              <div className="multiselect_breadcrumb">
                <span>{ getBreadCrumb() }</span>
              </div>
              <RenderOnCondition condition={ viewingOption.length }>
                <Form.Control
                  type="text"
                  value={ searchTerm }
                  placeholder={ getSearchPlaceHolder() }
                  onChange={ filterOptions }
                />
              </RenderOnCondition>
              <div className="multiselect_option_group-select" style={{ maxHeight: maxHeight || '' }}>
                <>{ getRenderedOptions() }</>
              </div>
            </div>
          </Portal>
        </OutsideClickHandler>

        </>
      )}

      </div>
      { !!error && touched && <div className="input-error">{ error }</div> }
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  reduxZindex: state.modalZindex,
});

export default connect(mapStateToProps)(withLocalize(GroupSelect));
