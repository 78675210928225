import React, { useEffect } from 'react';
import { Translate } from 'react-localize-redux';
import { useRootClose } from "react-overlays";
import {
  faEdit,
  faEnvelope,
  faExchangeAlt,
  faInfoCircle,
  faPlusCircle,
  faPlusSquare,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';
import { AggregatedPermission, IDeviceRole } from '@wiot/shared-domain/models/role/role';
import HasPermission from '../../../components/HasPermission';
import { connect, useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../state/reducers/rootReducer';
import RenderOnCondition from '../../../components/RenderOnCondition';
import GoToDeviceKeyDownloadMenuItem from './GoToDeviceKeyDownloadMenuItem';
import { IDeviceType, isMBusDeviceTypeWithConsumptionData } from '@wiot/shared-domain/models/device-types/device-types';
import { hasPermission } from '../../../utils/common';
import { ACLMessage } from '@wiot/shared-domain/models/role/access-level';
import { LinkToDeviceMessagesPage } from '../../../navigation/LinkToDeviceMessagesPage';
import { closeContextMenu, openContextMenu } from '../../../state/context-menu/toggleContextMenuActionCreators';

export interface TableMenuProps {
  removeUnit: (id: string) => void;
  deviceId: string;
  menuId: string;
  deviceGroup?: DeviceGroup;
  refreshTreeData?: () => void;
  refreshDevices?: () => void;
  ancestorPath?: string;
  permission?: AggregatedPermission;
  deviceRole?: IDeviceRole;
  deviceType?: IDeviceType;
  deviceMessageRole?: any;
  showDetails: (id: string) => Promise<void>;
  toggleDeviceActionModal: (event: React.MouseEvent) => void;
  toggleDeleteModal: (event: React.MouseEvent) => void;
  toggleDuplicateDeviceActionModal: (event: React.MouseEvent) => void;
  toggleChangeDeviceActionModal: (event: React.MouseEvent) => void;
  toggleDeviceActionModalAddSameGroup: (event: React.MouseEvent) => void;
  toggleAddManualDeviceReadingModal: (event: React.MouseEvent) => void;
  isMobileDisplay?: boolean;
  downloadHash?: string;
}

interface ExtendedTableMenuProps extends TableMenuProps, RouteComponentProps {}

const TableMenu = (props: ExtendedTableMenuProps) => {
  const {
    permission,
    deviceId,
    menuId,
    deviceType,
    deviceRole,
    deviceMessageRole,
    isMobileDisplay,
    showDetails,
    toggleDeleteModal,
    toggleDuplicateDeviceActionModal,
    toggleDeviceActionModalAddSameGroup,
    toggleChangeDeviceActionModal,
    toggleDeviceActionModal,
    toggleAddManualDeviceReadingModal,
    downloadHash,
  } = props;

  const isKeyManagerModeEnabled = useSelector((state: AppState) => !!state.siteSettings.isKeyManagerModeEnabled);
  const showAddManualDeviceReadingMenuItem = hasPermission(deviceMessageRole, ACLMessage.ADD) && !isKeyManagerModeEnabled && isMBusDeviceTypeWithConsumptionData(deviceType?.mBusDeviceTypeId);

  const dispatch = useDispatch();

  const closeMenu = (event?: React.MouseEvent) => {
    event && event.preventDefault();
    dispatch(closeContextMenu());
  }

  const tdRef = React.createRef<HTMLDivElement>();
  useRootClose(
    tdRef,
    () => closeMenu(),
  );

  useEffect(() => {
    dispatch(openContextMenu(menuId));
  }, [])

  useEffect(() => {
    const targetElement = tdRef.current;
    isMobileDisplay && disableBodyScroll(targetElement);

    return () => {
      isMobileDisplay && enableBodyScroll(targetElement);
    };
  }, [isMobileDisplay, tdRef]);

  const handleMenuButtonClick = (
    event: React.MouseEvent,
    action: (value: any) => any,
    value?: string,
  ) => {
    if (value) {
      action(value);
    } else {
      action(event);
    }

    closeMenu(event);
  };

  return (
    <div
      className="td-menu"
      ref={ tdRef }
    >
      <HasPermission permissionObj={ deviceRole } permissionKey="view" nested>
        <button
          className="td-menu__item"
          onClick={ (e) => handleMenuButtonClick(e, showDetails, deviceId) }
          data-testid="devices-row-name"
        >
          <div className="td-menu__item__icon">
            <FontAwesomeIcon icon={ faInfoCircle }/>
          </div>
          <Translate id="device-details"/>
        </button>
      </HasPermission>

      <HasPermission permissionObj={ deviceRole } permissionKey="edit" nested>
        <button
          className="td-menu__item"
          onClick={ (e) => handleMenuButtonClick(e, toggleDeviceActionModal) }
          data-testid="update-device"
        >
          <div className="td-menu__item__icon">
            <FontAwesomeIcon icon={ faEdit }/>
          </div>
          <Translate id="update"/>
        </button>
      </HasPermission>

      <HasPermission permissionObj={ deviceRole } permissionKey="remove">
        <button
          className="td-menu__item"
          onClick={ (e) => handleMenuButtonClick(e, toggleDeleteModal) }
        >
          <div className="td-menu__item__icon">
            <FontAwesomeIcon icon={ faTrashAlt }/>
          </div>
          <Translate id="remove"/>
        </button>
      </HasPermission>

      <RenderOnCondition condition={ !isKeyManagerModeEnabled }>
        <HasPermission permissionObj={ deviceRole } permissionKey="add">
          <button
            className="td-menu__item"
            onClick={ (e) => handleMenuButtonClick(e, toggleDuplicateDeviceActionModal) }
            data-testid="clone-device"
          >
            <div className="td-menu__item__icon">
              <FontAwesomeIcon icon={ faCopy }/>
            </div>
            <Translate id="clone"/>
          </button>
        </HasPermission>
      </RenderOnCondition>

      <RenderOnCondition condition={ !isKeyManagerModeEnabled }>
        <HasPermission permissionObj={ deviceRole } permissionKey="edit" nested>
            <button
              className="td-menu__item"
              onClick={ (e) => handleMenuButtonClick(e, toggleChangeDeviceActionModal) }
              data-testid="change-device"
            >
              <div className="td-menu__item__icon">
                <FontAwesomeIcon icon={ faExchangeAlt }/>
              </div>
              <Translate id="change-device"/>
            </button>
        </HasPermission>
      </RenderOnCondition>

      <RenderOnCondition condition={ !isKeyManagerModeEnabled }>
        <HasPermission permissionObj={ deviceRole } permissionKey="add">
          <>
            { props.deviceGroup && (
              <button
                className="td-menu__item"
                onClick={ (e) => handleMenuButtonClick(e, toggleDeviceActionModalAddSameGroup) }
                data-testid="add-dev-same-group"
              >
                <div className="td-menu__item__icon">
                  <FontAwesomeIcon icon={ faPlusCircle }/>
                </div>
                <Translate id="add-dev-same-group"/>
              </button>
            ) }
          </>
        </HasPermission>
      </RenderOnCondition>

      <RenderOnCondition condition={ showAddManualDeviceReadingMenuItem }>
        <>
          <button
            className="td-menu__item"
            onClick={ (e) => handleMenuButtonClick(e, toggleAddManualDeviceReadingModal) }
            data-testid="add-manual-device-reading"
          >
            <div className="td-menu__item__icon">
              <FontAwesomeIcon icon={ faPlusSquare }/>
            </div>
            <Translate id="add-manual-device-reading"/>
          </button>
        </>
      </RenderOnCondition>

      <RenderOnCondition condition={isKeyManagerModeEnabled && downloadHash}>
        <GoToDeviceKeyDownloadMenuItem downloadHash={downloadHash} closeMenu={() => closeMenu()}/>
      </RenderOnCondition>

      <RenderOnCondition condition={ !isKeyManagerModeEnabled }>
        <LinkToDeviceMessagesPage
          idOfDevice={ deviceId }
          className="td-menu__item"
        >
          <div className="td-menu__item__icon">
            <FontAwesomeIcon icon={ faEnvelope }/>
          </div>
          <Translate id="to-messages"/>
        </LinkToDeviceMessagesPage>
      </RenderOnCondition>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  permission: state.currentUser.permission,
});

export default connect(mapStateToProps)(withRouter(TableMenu));
